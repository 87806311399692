import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    IntrospectionFragmentMatcher,
    NormalizedCacheObject,
} from 'apollo-boost';
import { withClientState } from 'apollo-link-state';
import { IResolvers } from 'graphql-tools';
import fetch from 'node-fetch';

import introspectionQueryResultData from '../fragmentTypes.json';

export default (data?: NormalizedCacheObject) => {
    let initialCache = new InMemoryCache({
        fragmentMatcher: new IntrospectionFragmentMatcher({
            introspectionQueryResultData,
        }),
    });

    if (data) {
        initialCache = initialCache.restore(data);
    }

    const resolvers: IResolvers<{}, { cache: InMemoryCache }> = {
        Mutation: {
            setScheme: (_: any, { scheme }: { scheme: any }, { cache }: { cache: any }) => {
                cache.writeData({
                    data: {
                        menuConfig: {
                            __typename: 'MenuConfig',
                            scheme,
                        },
                    },
                });
                return null;
            },
        },
    };

    const defaults = {
        menuConfig: {
            __typename: 'MenuConfig',
            scheme: null,
        },
    };

    return new ApolloClient({
        ssrMode: true,
        link: ApolloLink.from([
            withClientState({ cache: initialCache, resolvers, defaults }),
            new HttpLink({
                uri: process.env.RAZZLE_CLIENT_URI,
                fetch,
                headers: {
                    Authorization: `Bearer ${process.env.RAZZLE_CLIENT_TOKEN}`,
                },
            }),
        ]),
        cache: initialCache,
    });
};
