import styled from '../../styled-components';

interface Props {
    tiny?: boolean;
}

export const Spinner = styled.span`
    width: ${(props: Props) => props.tiny ? '20px' : '40px'};
    height: ${(props: Props) => props.tiny ? '20px' : '40px'};
    background-color: #333;
    
    border-radius: 100%;  
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
    
    @-webkit-keyframes sk-scaleout {
        0% { -webkit-transform: scale(0) }
        100% {
            -webkit-transform: scale(1.0);
            opacity: 0;
        }
    }
    
    @keyframes sk-scaleout {
        0% { 
            -webkit-transform: scale(0);
            transform: scale(0);
        } 100% {
            -webkit-transform: scale(1.0);
            transform: scale(1.0);
            opacity: 0;
        }
    }
`;