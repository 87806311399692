import React, { ComponentType, FC, ReactNodeArray } from 'react';
import styled from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import Seperator from '../atoms/Separator';
import { compose, withState } from 'recompose';
import generatePath from 'react-router-dom/generatePath';
import { ExchangeParams } from '../Exchange';
import { getStartedRoute } from '../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router';
import { ModalInProps } from '../../hocs/withModal';
import ReactGA from 'react-ga';

interface InProps {
    ctaText?: string | null;
    ctaButtons?: ReactNodeArray;
    slideover?: ComponentType<ModalInProps>;
    buttonText?: string | null;
    compact?: boolean;
    noSeperator?: boolean;
    hasPricingPlan?: boolean;

    // we passthru all other props.
    // todo, this could be cleaner, type info is lost this way (generic type?)
    [key: string]: any;
}

interface OutProps extends RouteComponentProps<ExchangeParams> {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
}

export const trackOpenContactFormEvent = () => {
    ReactGA.event({
        category: 'Contactform',
        action: 'Form opened',
        label: location.pathname
    });
};

const ContactUs: FC<InProps & OutProps> = ({ setIsModalOpen, isModalOpen, ctaText, buttonText, compact, slideover: Slideover, match, noSeperator, ctaButtons, ...rest }) =>
    <>
        {!noSeperator &&
            <Seperator />
        }
        <ContactWrapper>
            {!compact && (
                <ContactText
                    dangerouslySetInnerHTML={{
                        __html: ctaText || `<p>Let's talk about how AMS-IX<br />can help you with your networking needs</p>`
                    }}
                />
            )}
            {ctaButtons ? ctaButtons :
                <DefaultButton
                    key="contact-home-btn"
                    colorScheme={'colored'}
                    onClick={(e) => {
                        e.preventDefault();
                        setIsModalOpen(true);
                        trackOpenContactFormEvent();
                    }}
                    to={'#'}
                >
                    {buttonText || 'Contact Us'}
                </DefaultButton>
            }
        </ContactWrapper>
        {!noSeperator &&
            <Seperator />
        }
        {Slideover &&
            <Slideover
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange })}
                {...rest}
            />
        }
    </>

const ContactWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6.4rem 0 8rem 0;
    align-items: center;
    @media screen and (max-width: ${(props) => props.theme.mediaQueries.l}) {
        flex-direction: column;
    }
`;

const ContactText = styled.div`
    font: ${(props) => props.theme.fonts.small.contactText};
    max-width: 54rem;
    margin-bottom: 2.4rem;
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        font: ${(props) => props.theme.fonts.medium.contactText};

    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        font: ${(props) => props.theme.fonts.large.contactText};
        margin-bottom: 0;
    }
    * {
        display: inline-block;
    }

    p > b {
        color: ${props => props.theme.colors.mirage};
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 31.2px */
        letter-spacing: 0.24px;
    }
    p {
        color: ${props => props.theme.colors.shuttleGray};
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        margin: 0;
    }
`;

const enhance = compose<OutProps, InProps>(
    withRouter,
    withState('isModalOpen', 'setIsModalOpen', false)
);
export default enhance(ContactUs);
