import React from 'react';

const SearchIcon = () => (
    <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <circle stroke="currentColor" strokeWidth="1.89" cx="6.7" cy="6.7" r="6.25" />
            <rect fill="currentColor" x="9" y="9" width="4" height="4" rx="2" />
            <path d="M11.15 11.15l5.4 5.4" stroke="currentColor" strokeWidth="1.89" />
        </g>
    </svg>
);

export default SearchIcon;
