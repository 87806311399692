import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    }

const LinkIconRight = ({ color = 'currentColor', ...rest }: Props) =>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="14.25" y="38" width="9.5" height="9.5" rx="4.75" transform="rotate(-90 14.25 38)" fill="#F58025"/>
        <rect x="28.5" y="23.75" width="9.5" height="9.5" rx="4.75" transform="rotate(-90 28.5 23.75)" fill="#F58025"/>
        <rect x="14.25" y="9.5" width="9.5" height="9.5" rx="4.75" transform="rotate(-90 14.25 9.5)" fill="#F58025"/>
        <rect y="23.75" width="9.5" height="9.5" rx="4.75" transform="rotate(-90 0 23.75)" fill="#F58025"/>
        <path d="M20.583 31.6668L33.2497 19.0001L20.583 6.33348" stroke="#F58025" stroke-width="2.4"/>
        <path d="M6.33301 18.9999H31.6663" stroke="#F58025" stroke-width="2.4"/>
    </svg>


export default LinkIconRight;