import React from 'react';
import Container from '../../../atoms/Container';

interface Props {
    setVisible: (visible: boolean) => void;
}

const DropdownSearch = ({ setVisible }: Props) => {

    return (
        <Container maxWidth={true} paddingTop={'5.6rem'} paddingBottom={'6.4rem'}>
           <p>Hier komt de searchbar</p>
        </Container>
    );
};

export default DropdownSearch;
