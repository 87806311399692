import React, { SFC } from 'react';
import { ConnectedNetworksStatsFragment } from '../../entities/operationResults';
import styled from '../../styled-components';

interface Props {
    stats: ConnectedNetworksStatsFragment;
}

const ConnectedNetworksStats: SFC<Props> = ({ stats }) => {
    return (
        <div>
            <Title>Network (AS number) statistics</Title>
            <Stats>
                <Stat>
                    <StatTitle>Route server peers</StatTitle>
                    <StatBody>{stats.routeserverPeers}</StatBody>
                </Stat>
                <Stat>
                    <StatTitle>Total connected ASNs</StatTitle>
                    <StatBody>{stats.connectedNetworks}</StatBody>
                </Stat>
                <Stat>
                    <StatTitle>Customer ports</StatTitle>
                    <StatBody>{stats.customerPorts}</StatBody>
                </Stat>
                <Stat>
                    <StatTitle>IPv6 peers</StatTitle>
                    <StatBody>{stats.ipv6Peers}</StatBody>
                </Stat>
            </Stats>
        </div>
    );
};

const Title = styled.h2`
    color: ${props => props.theme.colors.tango};
    font-family: chalet-new-york-sixty;
    font-size: 2rem;
    margin: 0  0 2.4rem 0;
`;

const Stats = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const Stat = styled.div`
    width: 50%;
    margin-bottom: 2.4rem;
`;

const StatTitle = styled.div`
    font: ${props => props.theme.fonts.small.smallCaps};
    color: ${props => props.theme.colors.baliHai};
    text-transform: uppercase;
    
    margin-bottom: 0.5rem;
`;

const StatBody = styled.div`
    font-family: chalet-new-york-sixty;
    font-size: 2.4rem;
`;

export default ConnectedNetworksStats;