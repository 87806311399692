import React, { SFC } from 'react';
import styled from '../styled-components';
import { RouteComponentProps } from 'react-router';
import ContentTitle from './atoms/ContentTitle';
import DefaultButton from './atoms/buttons/DefaultButton';

type Props = RouteComponentProps<{}>;

const NotFound: SFC<Props> = ({ staticContext }) => {
    if (staticContext) {
        staticContext.status = 404;
    }
    return (
        <Container>
            <ContentTitle>Oops, page not found</ContentTitle>
            <DefaultButton to={'/'} colorScheme={'colored'}>Go to the homepage</DefaultButton>
        </Container>
    );
};

const Container = styled.div`
    height: calc(100vh - 32rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        height: calc(100vh - 38rem);
    }
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        height: calc(100vh - 45rem);
    }
`;

export default NotFound;
