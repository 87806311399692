import * as H from 'history';
import queryString from 'query-string';
import React, { SFC } from 'react';
import { match } from 'react-router';
import { NavLink, NavLinkProps, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styled, { ThemeProps, withTheme } from '../../../styled-components';
import PaginationLink from '../../atoms/buttons/PaginationLink';

const enhanced = compose<OutProps, InProps>(
    withTheme,
    withRouter
);

type OutProps = ThemeProps & RouteComponentProps<{}>;

interface InProps {
    totalPages: number;
}

function getPaginationItems(total: number, current: number) {
    const items: number[] = [];
    let defaultLoopAmount = 2;
    let defaultLoopStart = -1;
    if (current === total) {
        for (let i = 3; 0 < i; i -= 1) {
            if (total - i > 1) {
                items.push(total - i);
            }
        }
    } else if (current === 1) {
        for (let i = 1; i < 4; i += 1) {
            if (current + i === total) {
                return items;
            }
            items.push(current + i);
        }
    } else {
        if (current + 1 === total && current - 1 > 1) {
            defaultLoopStart -= 1;
        }
        for (let i = defaultLoopStart; i < defaultLoopAmount; i += 1) {
            if (current + i === 1 && current + 1 !== total) {
                defaultLoopAmount += 1;
            }
            if (current + i !== 1 && current + i !== total) {
                items.push(current + i);
            }
        }
    }
    return items;
}

function initPagination(total: number, current: number) {
    const firstPage: boolean = current === 1;
    const lastPage: boolean = current === total;
    const layoutStart: boolean = current <= 3 || total <= 4;
    const layoutEnd: boolean = current >= total - 2 || total <= 4;

    const paginationItems = getPaginationItems(total, current);

    const details = {
        pageDetails: { total, current, firstPage, lastPage },
        layout: { layoutStart, layoutEnd },
        items: { paginationItems },
    };

    return details;
}

const CollectionPagination: SFC<OutProps & InProps> = ({ totalPages, location: { search } }) => {
    const query = queryString.parse(search);
    const currentPage = parseInt(query.page, 10) || 1;
    const paginationDetails = initPagination(totalPages, currentPage);
    return (
        <PaginationWrap>
            <PaginationList>
                <PaginationItem
                    to={{ search: queryString.stringify({ ...query, page: 1 }) }}
                    className={!paginationDetails.layout.layoutStart ? 'dots-right' : ''}
                    page={1}
                >
                    1
                </PaginationItem>
                {paginationDetails.items.paginationItems.map(paginationItem => (
                    <PaginationItem
                        key={paginationItem}
                        page={paginationItem}
                        to={{ search: queryString.stringify({ ...query, page: paginationItem }) }}
                    >
                        {paginationItem}
                    </PaginationItem>
                ))}
                <PaginationItem
                    to={{ search: queryString.stringify({ ...query, page: totalPages }) }}
                    page={totalPages}
                    className={!paginationDetails.layout.layoutEnd ? 'dots-left' : ''}
                >
                    {totalPages}
                </PaginationItem>
            </PaginationList>
            <div>
                <PaginationLink
                    inActive={paginationDetails.pageDetails.firstPage}
                    to={{ search: queryString.stringify({ ...query, page: currentPage - 1 }) }}
                    first={true}
                />
                <PaginationLink
                    inActive={paginationDetails.pageDetails.lastPage}
                    to={{ search: queryString.stringify({ ...query, page: currentPage + 1 }) }}
                />
            </div>
        </PaginationWrap>
    );
};

const PaginationWrap = styled.div`
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const PaginationList = styled.div`
    list-style: none;
    padding: 0;
    margin: 0;
`;

// this is needed because react router refuses to parse query strings themselves... https://github.com/ReactTraining/react-router/issues/5379
const isActive = (page: number) => (_: match<{}>, location: H.Location) =>
    (queryString.parse(location.search).page || '1') === page.toString();

type PaginationItemProps = Partial<NavLinkProps> & { page: number };

const PaginationItem = styled(NavLink).attrs<PaginationItemProps>(({ page }: PaginationItemProps) => ({
    isActive: isActive(page),
}))<PaginationItemProps>`
    font: ${props => props.theme.fonts.large.storySubtitle};
    color: ${props => props.theme.colors.baliHai};
    display: inline-block;
    padding-right: 2rem;
    &.active {
        color: ${props => props.theme.colors.tango};
    }
    &.dots-right {
        &:after {
            margin-left: 10px;
            content: '...';
        }
    }
    &.dots-left {
        &:before {
            margin-right: 10px;
            content: '...';
        }
    }
`;

export default enhanced(CollectionPagination);
