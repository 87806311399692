import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const LinkIconDown = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g transform="translate(-.667 -.667)" fill="none" fillRule="evenodd">
            <rect fill={color} x=".667" y="6.667" width="4" height="4" rx="2"/>
            <rect fill={color} x="6.667" y="12.667" width="4" height="4" rx="2"/>
            <rect fill={color} x="12.667" y="6.667" width="4" height="4" rx="2"/>
            <rect fill={color} x="6.667" y=".667" width="4" height="4" rx="2"/>
            <path stroke={color} strokeWidth="1.4" d="M3.333 8.667L8.667 14 14 8.667M8.667 3.333V14"/>
        </g>
    </svg>
);

export default LinkIconDown;