import React, { Fragment, SFC } from 'react';
import * as Form from '../atoms/Form';

const RegisterYourContactDetails: SFC = () => {
    return (
        <Fragment>
            <Form.Field
                name="title"
                label="Title *"
                placeholder="Choose..."
                type="select"
                options={['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.']}
            />
            <Form.Field name="firstName" label="First Name *" placeholder="Your first name" />
            <Form.Field name="lastName" label="Last Name *" placeholder="Your last name" />
            <Form.Field name="jobTitle" label="Job Title" placeholder="Your job title" />
            <Form.Field name="email" label="Email *" placeholder="Your email" smallText={'We won’t send you any unwanted e-mails'}/>
            <Form.Field name="phoneNumber" label="Phone/Mobile *" placeholder="Your phone number"/>
        </Fragment>
    );
};

export default RegisterYourContactDetails;
