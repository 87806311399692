import { compose, lifecycle } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router';
import ReactGA from 'react-ga';

const withTracker = <Props>() => compose<Props & RouteComponentProps<any>, Props>(
    withRouter,
    lifecycle({
        componentDidUpdate(prevProps: Props & RouteComponentProps<any>) {
            if (this.props.location.pathname !== prevProps.location.pathname) {
                ReactGA.set({ page: this.props.location.pathname });
                ReactGA.pageview(this.props.location.pathname);
            }
        }
    })
);

export default withTracker;