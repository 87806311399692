import React, { SFC } from 'react';
import styled from '../../../styled-components';
import { IconButtonLink } from '../../atoms/buttons/IconButton';
import Facebook from '../../atoms/icons/Facebook';
import Youtube from '../../atoms/icons/Youtube';
import LinkedIn from '../../atoms/icons/LinkedIn';
import Twitter from '../../atoms/icons/Twitter';
import { FooterSocialsFragment } from '../../../entities/operationResults';

interface Props {
    socialLinks: FooterSocialsFragment | undefined;
}

const FooterSocial: SFC<Props> = ({ socialLinks }) => (
    <FooterSocialWrap>
        <SocialList>
            {socialLinks && socialLinks.facebookUrl && <li><IconButtonLink target="_blank" href={`${socialLinks.facebookUrl}`} colorScheme={'shuttle'} icon={<Facebook/>}/></li> }
            {socialLinks && socialLinks.youtubeUrl && <li><IconButtonLink target="_blank" href={`${socialLinks.youtubeUrl}`} colorScheme={'shuttle'} icon={<Youtube/>}/></li> }
            {socialLinks && socialLinks.linkedinUrl && <li><IconButtonLink target="_blank" href={`${socialLinks.linkedinUrl}`} colorScheme={'shuttle'} icon={<LinkedIn/>}/></li> }
            {socialLinks && socialLinks.twitterUrl && <li><IconButtonLink target="_blank" href={`${socialLinks.twitterUrl}`} colorScheme={'shuttle'} icon={<Twitter/>}/></li> }
        </SocialList>
    </FooterSocialWrap>
);

const FooterSocialWrap = styled.div`
  flex: 1;

  @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
`;

const SocialList = styled.ul`
  padding: 0;
  margin:0;
  list-style: none;
  li{
    display: inline-block;
  }
`;

export default FooterSocial;
