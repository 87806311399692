import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const LinkIconRight = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="16" height="16" {...rest}>
        <g transform="rotate(-90 8 8.667)" fill="none" fillRule="evenodd">
            <rect fill={color} x=".667" y="6.667" width="4" height="4" rx="2"/>
            <rect fill={color} x="6.667" y="12.667" width="4" height="4" rx="2"/>
            <rect fill={color} x="12.667" y="6.667" width="4" height="4" rx="2"/>
            <rect fill={color} x="6.667" y=".667" width="4" height="4" rx="2"/>
            <path stroke={color} strokeWidth="1.4" d="M3.333 9.333l5.334 5.334L14 9.333M8.667 3.333V14"/>
        </g>
    </svg>
);

export default LinkIconRight;