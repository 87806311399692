import React, { Fragment, SFC } from 'react';
import * as Form from '../atoms/Form';
import styled from 'styled-components';
import RegisterContact from './RegisterContact';
import { Values } from './RegisterForm';

interface Props {
    values: Values;
}

const RegisterContacts: SFC<Props> = ({ values }: Props) => {
    return (
        <Fragment>
            <StyledH3Title>Administrative Contact Person (ACP)</StyledH3Title>

            <Form.Field name="firstName" label="First Name *" disabled value={values.firstName} placeholder={'Please fill in your contact info'}/>
            <Form.Field name="lastName" label="Last Name *" disabled value={values.lastName} placeholder={'Please fill in your contact info'}/>
            <Form.Field name="jobTitle" label="Job Title *" disabled value={values.jobTitle} placeholder={'Please fill in your contact info'}/>
            <Form.Field name="email" label="Email *" disabled value={values.email} placeholder={'Please fill in your contact info'}/>
            <Form.Field name="phoneNumber" label="Phone/Mobile *" disabled value={values.phoneNumber} placeholder={'Please fill in your contact info'}/>

            <StyledH3Title>Billing Contact Person (BCP)</StyledH3Title>
            <RegisterContact type={'BCP'} sameAs={'ACP'} value={values.BCP.sameAs}/>

            <StyledH3Title>Contractual Contact Person (CCP)</StyledH3Title>
            <RegisterContact type={'CCP'} sameAs={'BCP'} value={values.CCP.sameAs}/>

            <StyledH3Title>Technical Contact Person (TCP)</StyledH3Title>
            <RegisterContact type={'TCP'} sameAs={'CCP'} value={values.TCP.sameAs}/>

            <StyledH3Title>NOC</StyledH3Title>

            <Form.Field
                name="NOC"
                label="Same as TCP"
                type="checkbox"
                defaultChecked={values.NOC}
            />

            <OptionalForm hide={values.NOC}>
                <Form.Field name="NOCemail" label="Email *" placeholder="NOC email"/>
                <Form.Field name="NOCphoneNumber" label="Phone/Mobile *" placeholder="NOC phone number"/>
            </OptionalForm>
        </Fragment>
    );
};

export default RegisterContacts;

const StyledH3Title = styled.h3 `
    font: ${(props) => props.theme.fonts.small.introText};
    max-width: 80rem;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        font: ${(props) => props.theme.fonts.large.introText};
    }
`;

const OptionalForm = styled.div<{hide: boolean}>`
    display: ${(props) => props.hide ? 'none' : 'block'};
`;