import styled from "styled-components";

interface Props {
    direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
    justifyContent?: string;
    alignItems?: string;
    gap?: number;
}

const Flex = styled.div<Props>`
    display: flex;
    flex-direction: ${props => props.direction || 'row'};
    gap: ${props => props.gap ? `${props.gap}px` : 0};
    align-items: ${props => props.alignItems};
    justify-content: ${props => props.justifyContent};
`

export default Flex;