import { css } from '../../../styled-components';

export const BodyLink = css`
    a {
        color: ${props => props.theme.colors.tango};
        font-family: chalet-new-york-sixty;
        text-decoration: none;
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: ${props => props.theme.colors.transparentGrey};
            transition: opacity 0.1s;
            opacity: 1;
        }
        
        &:hover:after {
            opacity: 0;
        }
    }
`;