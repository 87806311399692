import React, { SFC } from 'react';
import styled from '../../styled-components';
import { Spinner } from '../atoms/Spinner';
import { compose, lifecycle, withState } from 'recompose';

interface Props {
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

const SpinnerPage: SFC<Props> = ({ visible }) => {
    return (
        <SpinnerWrap>
            {visible ? (
                    <Spinner />
            ) : null}
        </SpinnerWrap>
    );
};

const SpinnerWrap = styled.div`
  background-color:white;
  opacity: 0.5;
  position: relative;
  top:0;
  bottom:0;
  left:0;
  right:0;
 width: 100vw;
  height: calc(100vh - 6rem);
  z-index:200;
  
  span{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
`;

const enhance = compose(
    withState('visible', 'setVisible', false),
    lifecycle<Props, {}, { timeOut: number | null }>({
        timeOut: null,
        componentDidMount() {
            if (typeof window !== 'undefined') {
                this.timeOut = window.setTimeout(this.props.setVisible, 1000, true);
            }
        },
        componentWillUnmount() {
            if (this.timeOut && typeof window !== 'undefined') {
                window.clearTimeout(this.timeOut);
            }
        }
    })
);

export default enhance(SpinnerPage);