import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const Logo = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="52" height="46" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g fillRule="nonzero" fill="none">
            <path
                d="M38.81 36.506L37.26 38.18l-.344-.28c.763-1.588 1.916-2.636 2.943-2.636.573 0 .803.264.803.802a2.757 2.757 0 0 1-.04.384l-1.01 7.177v.08a.452.452 0 0 0 .12.32l.098.104 1.572-1.718.344.303c-.746 1.547-1.933 2.635-2.92 2.635-.545 0-.826-.263-.826-.785.004-.155.017-.31.04-.464l1.01-7.12v-.102a.292.292 0 0 0-.126-.253l-.115-.12zm1.692-4.748a.77.77 0 0 1 .826.859c0 .842-.424 1.346-1.147 1.346a.758.758 0 0 1-.826-.848c.017-.813.459-1.357 1.147-1.357zM48.396 39.888l1.75 3.74c.08.183.103.183.24.24l1.05-1.449.362.241c-.482 1.506-1.371 2.612-2.117 2.612-.425 0-.522-.097-.906-.905l-1.67-3.557-.183.223-.907.968c-.35.359-.674.741-.97 1.146.25.334.402.73.442 1.145 0 .602-.441.986-1.147.986s-1.05-.304-1.05-.865c.064-.53.297-1.024.666-1.41a29.407 29.407 0 0 1 2.868-2.863l-1.532-3.168c-.08-.2-.103-.223-.264-.263l-1.067 1.426-.384-.24.08-.184c.706-1.718 1.291-2.451 2.014-2.451.465 0 .602.143 1.01.985l1.422 2.995a23.33 23.33 0 0 0 1.67-1.873l-.104-.183a1.98 1.98 0 0 1-.263-.945c0-.641.401-.985 1.147-.985.625 0 .97.303.97.802-.06.599-.348 1.152-.804 1.546a48.232 48.232 0 0 1-2.157 2.114l-.166.172zM23.263 23.786c-.623.68-1.474 1.585-2.552 2.715-.987 1.054-1.469 1.587-1.9 2.108a14.675 14.675 0 0 0 6.954 1.719c.459 0 .906 0 1.354-.063l-3.345-7.103-.51.624z"
                fill="#EF8029"
            />
            <path
                d="M18.674 12.353c-.23-.573-.281-.625-.734-.733l-3.006 4.01-1.079-.682.224-.51c1.985-4.869 3.626-6.902 5.668-6.902 1.302 0 1.72.395 2.834 2.772l3.964 8.431a65.33 65.33 0 0 0 4.704-5.264l-.281-.51a5.567 5.567 0 0 1-.734-2.657c0-1.81 1.147-2.772 3.23-2.772 1.755 0 2.718.847 2.718 2.29 0 1.301-.676 2.659-2.294 4.36a136.79 136.79 0 0 1-6.03 5.922l-.453.453 4.062 8.655a14.694 14.694 0 0 0 8.91-15.501A14.71 14.71 0 0 0 27.71 1.085a14.724 14.724 0 0 0-15.513 8.917 14.684 14.684 0 0 0 4.577 17.278 81.323 81.323 0 0 1 6.15-5.986l-4.25-8.941z"
                fill="#EF8029"
            />
            <path
                d="M1.137 37.876v-1.821a12.264 12.264 0 0 1 3.505-.682c1.974 0 2.92.785 2.92 2.372v5.332c0 .504.063.573.574.745l1.147.32v.425l-1.451.48a4.383 4.383 0 0 1-.844.144h-.08a1 1 0 0 1-1.067-.968l-.24.12-.683.32c-.482.224-.786.345-.907.385a2.872 2.872 0 0 1-.987.143c-1.629 0-2.759-1.008-2.759-2.434 0-1.209.746-1.994 2.134-2.292a27.691 27.691 0 0 1 3.385-.464v-2.188c0-1.248-.482-1.65-2.014-1.65a2.603 2.603 0 0 0-.545.04l-.843 1.673H1.137zm4.59 2.675c-.715.078-1.422.206-2.117.384-.97.24-1.389.705-1.389 1.53 0 .985.746 1.609 1.934 1.609a3.2 3.2 0 0 0 .763-.08c.217-.031.432-.08.642-.143a.879.879 0 0 0 .16-.04l.006-3.26zM10.201 45.075v-.504l1.692-.705v-6.942l-1.692-.481v-.481l3.482-.785v1.146l.264-.12 1.05-.402c1.228-.464 1.428-.52 2.036-.52a2.158 2.158 0 0 1 2.037 1.145l.281-.103a32.82 32.82 0 0 0 1.371-.522 6.358 6.358 0 0 1 2.214-.572 2.721 2.721 0 0 1 1.974.824c.602.602.803 1.209.803 2.395v5.39l1.738.733v.504h-5.123v-.504l1.59-.705V38.86c0-1.627-.746-2.291-2.616-2.291a6.411 6.411 0 0 0-1.974.28c.24.594.336 1.236.281 1.874v5.155l1.612.704v.504h-4.916v-.504l1.509-.704V38.86c0-1.667-.683-2.291-2.496-2.291a5.98 5.98 0 0 0-1.612.2v7.103l1.59.704v.505l-5.095-.006zM28.736 44.713V42.48h.844l.929 1.81c.291.053.587.08.883.08 1.25 0 1.957-.52 1.957-1.426 0-.624-.224-.927-1.01-1.386l-1.83-1.025c-1.147-.664-1.67-1.49-1.67-2.555 0-1.65 1.148-2.715 3.041-2.715a9.766 9.766 0 0 1 2.599.441l.16.063v2.005h-.849l-.803-1.627a2.717 2.717 0 0 0-.849-.091c-1.147 0-1.813.481-1.813 1.289 0 .572.345.968 1.25 1.466l1.573.865c1.29.704 1.79 1.466 1.79 2.675 0 1.69-1.331 2.938-3.161 2.938a13.046 13.046 0 0 1-3.04-.573z"
                fill={color}
            />
        </g>
    </svg>
);

export default Logo;