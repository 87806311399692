import React, { ReactNode } from 'react';
import { ColorScheme } from '../../../entities/ColorScheme';
import { buttonBackgroundColor, buttonColor } from '../../../services/colorHelpers';
import styled from '../../../styled-components';
import { buttonHover, ButtonWithIcon } from './ButtonStyles';

interface Props {
    colorScheme: ColorScheme;
    children?: ReactNode;
    icon: ReactNode;
    rotate?: number;
}


const IconContainer = styled.div<{ colorScheme: ColorScheme; rotate?: number }>`
    position: relative;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    margin: 0 1.2rem 0 0;
    transition: background-color 0.15s;
    transform: rotate(${props => (props.rotate ? `${props.rotate}deg` : `${props.rotate}`)});
    background-color: ${props => buttonBackgroundColor(props.colorScheme, 'neutral', props.theme)};
    color: ${props => buttonColor(props.colorScheme, 'neutral', props.theme)};
    &:hover {
        background-color: ${props => buttonBackgroundColor(props.colorScheme, 'hover', props.theme)};
        color: ${props => buttonColor(props.colorScheme, 'hover', props.theme)};

        &:after {
            transform: scale(1.025);
        }
    }
    &:focus {
        background-color: ${props => buttonBackgroundColor(props.colorScheme, 'focus', props.theme)};
        color: ${props => buttonColor(props.colorScheme, 'focus', props.theme)};
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        width: 40px;
        height: 40px;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:after {
        ${buttonHover};
    }
`;

export default ({
    colorScheme,
    rotate,
    children,
    icon,
    ...rest
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <ButtonWithIcon {...rest}>
        <IconContainer rotate={rotate} colorScheme={colorScheme}>
            {icon}
        </IconContainer>
        {children}
    </ButtonWithIcon>
);

// hack... type safety is retained outside IconButtonLink however.
const IconLinkWrap = ButtonWithIcon.withComponent('a') as any;

export const IconButtonLink = ({
    colorScheme,
    children,
    icon,
    ...rest
}: Props & React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <IconLinkWrap {...rest}>
        <IconContainer colorScheme={colorScheme}>{icon}</IconContainer>
        {children}
    </IconLinkWrap>
);
