import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const Youtube = ({ type, color = 'currentColor', ...rest }: Props) => (
    <svg width="16" height="12">
        <path
            d="M15.376 1.236C14.8.549 13.732.269 11.696.269H4.304C2.22.269 1.136.567.56 1.298 0 2.011 0 3.061 0 4.514v2.77C0 10.1.666 11.53 4.304 11.53h7.392c1.766 0 2.745-.247 3.378-.853.649-.621.926-1.636.926-3.392v-2.77c0-1.533-.043-2.589-.624-3.278zM10.272 6.28L6.915 8.036a.516.516 0 0 1-.755-.458V4.081a.516.516 0 0 1 .754-.458l3.357 1.743a.516.516 0 0 1 .001.915z"
            fill={color}
            fillRule="nonzero"
        />
    </svg>
);

export default Youtube;
