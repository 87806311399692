import { MatchedRoute, matchRoutes } from 'react-router-config';
import routes from '../constants/routes';

export default <TParams>(pathname: string) => {
    const matchedRoutes = [...matchRoutes<TParams>(routes, pathname)];
    matchedRoutes.reverse();

    let matchedRoute: MatchedRoute<TParams> | null = null;
    if (matchedRoutes.length) {
        matchedRoute = matchedRoutes[0];
    }
    return matchedRoute;
};