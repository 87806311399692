import React from 'react';
import styled from '../../../styled-components';
import { buttonHover } from './ButtonStyles';
import {MenuScheme} from "../../../entities/operationResults";

const TransparentButton = styled.div<{ scheme?: MenuScheme }>`
    position: relative;
    font-family: urw-din;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.86px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 2.4rem;
    padding: 1.3rem 1.3rem;
    align-items: center;
    transition: background-color 0.15s, opacity 0.25s, transform 0.2s;
    opacity: 1;
    display: inline;
    white-space: nowrap;
    margin-left: 2rem;
    margin-right: 1rem;
    svg {
        g {
             stroke: ${props => (props.scheme !== 'light' ? props.theme.colors.white : props.theme.colors.black)};
        }
        path {
             stroke: ${props => (props.scheme !== 'light' ? props.theme.colors.white : props.theme.colors.black)};
        }
    }
    &:hover {
        &:after {
            transform: scale(1.025);
        }
    }
    
    svg {
        color: #000;
    }
    
    svg:first-child {
        vertical-align: middle;
        padding-right: 1rem;
    }

    &:after {
        ${buttonHover};
    }
`;

export default ({ ...rest }) => (
    <TransparentButton {...rest} />
);
