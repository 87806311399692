import React, { SFC, Fragment } from 'react';
import theme from '../constants/theme';
import { ThemeProvider } from '../styled-components';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost';
import routes from '../constants/routes';
import { renderRoutes } from 'react-router-config';
import { HelmetProvider } from 'react-helmet-async';
import Meta from './atoms/Meta';
import { Context } from '../server';
import withTracker from '../hocs/withTracker';

interface Props {
    client: ApolloClient<NormalizedCacheObject>;
    helmetContext?: Context;
}

const App: SFC<Props> = ({ client, helmetContext }) => (
    <HelmetProvider context={helmetContext}>
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <Fragment>
                    <Meta/>
                    {renderRoutes(routes)}
                </Fragment>
            </ThemeProvider>
        </ApolloProvider>
    </HelmetProvider>
);

export default withTracker<Props>()(App);