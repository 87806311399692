import gql from 'graphql-tag';
import React, { SFC } from 'react';
import { ContactFormContentBlockFragment } from '../../entities/operationResults';
import ContactUs from '../organisms/ContactUs';
import ContactSlideover from '../organisms/ContactSlideover';
import Container from '../atoms/Container';
import styled from 'styled-components';

export const fragment = gql`
    fragment ContactFormContentBlock on contentBlocks_contactForm_BlockType {
        id
        ctaText
        buttonText
        compactDisplay
        documentUpload
        description
    }
`;

interface Props {
    contentBlock: ContactFormContentBlockFragment;
}

const ContactFormContentBlock: SFC<Props> = ({ contentBlock: { id, ctaText, description, buttonText, compactDisplay, documentUpload } }) => {
    const blockId = parseInt(id || '', 10);
    const inner = <ContactUsWrapper>
        <ContactUs
            slideover={ContactSlideover}
            contactBlockId={blockId}
            contactFormText={description}
            buttonText={buttonText}
            ctaText={ctaText}
            compact={!!compactDisplay}
            documentUpload={!!documentUpload}
        /> 
    </ContactUsWrapper>
    return compactDisplay ? (
        <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'9.6rem'} marginTopL={'-6.6rem'} className={'pre-footer'}>
            {inner}
        </Container>
    ) : (
        <Container maxWidth={true} paddingTopL={'6.3rem'} paddingBottom={'6rem'} paddingBottomL={'13.2rem'} className={'pre-footer'}>
            {inner}
        </Container>
    );
};

const ContactUsWrapper = styled.div`
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto;
    }
`;

export default ContactFormContentBlock;