import React, { ReactNode } from 'react';
import styled from '../../styled-components';
import LinkButton from '../atoms/buttons/LinkButton';

interface Props {
    title: ReactNode;
    link?: string;
    linkLabel?: string;
    backgroundColor?: string;
}

const SectionHeader = ({ title, link, linkLabel }: Props) => (
    <SectionHeaderWrap>
        <h3>{title}</h3>
        {linkLabel && (
            <LinkButton colorScheme={'dark'} to={link ? link : '#'}>
                {linkLabel}
            </LinkButton>
        )}
    </SectionHeaderWrap>
);

const SectionHeaderWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: calc(100vw - 6.6rem);
    margin: 0 auto;
    padding: 0 0 2.5rem 0;
    h3 {
        margin:0;
        font: ${(props) => props.theme.fonts.small.sectionHeaderTitle}
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        max-width: ${(props) => props.theme.maxWidths.s}px;
        h3 {
            font: ${(props) => props.theme.fonts.medium.sectionHeaderTitle}
        }
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        max-width: ${(props) => props.theme.maxWidths.m}px;
        h3 {
            font: ${(props) => props.theme.fonts.large.sectionHeaderTitle}
        }
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        max-width: ${(props) => props.theme.maxWidths.l}px;
        padding: 0 0 3.5rem 0;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.xl}) {
        max-width: ${(props) => props.theme.maxWidths.xl}px;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.xxl}) {
        max-width: ${(props) => props.theme.maxWidths.xxl}px;
    }        
`;

export default SectionHeader;
