import React from 'react';
import styled from '../../../styled-components';
import IconButton from '../../atoms/buttons/IconButton';
import LinkIconRight from '../../atoms/icons/LinkIconRight';
import ContactSlideover from '../../organisms/ContactSlideover';
import { compose, withState } from 'recompose';
import { getStartedRoute } from '../../../constants/routes';
import { ExchangeParams } from '../../Exchange';
import generatePath from 'react-router-dom/generatePath';
import { RouteComponentProps, withRouter } from 'react-router';

interface InProps {
    mailChimpUrl: string | undefined;
    contactFormText: string | undefined;
}

interface OutProps extends RouteComponentProps<ExchangeParams> {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
}

const FooterContact = (props: InProps & OutProps) => (
    <FooterContactWrap>
        {props.mailChimpUrl && (
            <div>
                <FooterTitle>Subscribe to our newsletter</FooterTitle>
                <form action={props.mailChimpUrl} method="post" target="_blank">
                    <FormGroup>
                        <UnderlinedInput name="EMAIL" placeholder="Type email address"/>
                        <IconButton type="submit" colorScheme="colored" icon={<LinkIconRight/>} />
                    </FormGroup>
                </form>
            </div>
        )}
        <div>
            <FooterTitle>Got a question?</FooterTitle>
            <FooterLink
                onClick={(e) => {
                    e.preventDefault();
                    props.setIsModalOpen(true);
                }}
            >
                Contact us
            </FooterLink>
            <ContactSlideover
                contactFormText={props.contactFormText}
                isOpen={props.isModalOpen}
                onClose={() => props.setIsModalOpen(false)}
                getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, { exchange: props.match.params.exchange })}
            />
        </div>
    </FooterContactWrap>
);

const FooterContactWrap = styled.div`
    color: pink; 
    flex: 1;
    display: inline-flex;
    justify-content: space-between;
    >div{
      margin-bottom: 3.3rem;
    }
  @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
    display: block;
  }
  @media screen and (max-width: ${(props) => props.theme.mediaQueries.xs}) {
    flex-direction: column;
  }
`;

const FooterTitle = styled.h4`
  font: ${(props) => props.theme.fonts.large.eventDate};
  color: ${(props) => props.theme.colors.baliHai};
  text-transform: uppercase;
  margin: 0 0 0.7rem 0;
`;

const FormGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  input{
    margin-right: 1rem;
  }
`;

const FooterLink = styled.button`
  font-weight: bold;
  display: block;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0.8rem 0;
  color:white;
  background-color: transparent;
  width: 100%;
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;
`;

const UnderlinedInput = styled.input`
  outline: none;
  padding-right: 3rem;
  font: 1.4rem/4rem chalet-london-sixty, sans-serif;
  color: ${(props) => props.theme.colors.baliHai};
  background-color: transparent;
  border: none;
  border-bottom: 2px solid ${(props) => props.theme.colors.shuttleGray};
  &::placeholder{
    font: 1.4rem/4rem chalet-london-sixty, sans-serif;
    color: ${(props) => props.theme.colors.baliHai};
  } 
`;

const enhance = compose<OutProps, InProps>(
    withRouter,
    withState('isModalOpen', 'setIsModalOpen', false)
);

export default enhance(FooterContact);