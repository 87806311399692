import { ThemeInterface } from '../constants/theme';
import { ColorScheme } from '../entities/ColorScheme';

type ButtonState = 'neutral' | 'hover' | 'focus';

export const buttonColor = (type: ColorScheme, state: ButtonState, theme: ThemeInterface) => {
    if ((type === 'light' || type === 'subtle') && state === 'neutral') {
        return theme.colors.black;
    }
    if (type === 'light' && state === 'hover') {
        return theme.colors.black;
    }
    return theme.colors.white;
};

export const buttonBackgroundColor = (type: ColorScheme, buttonState: ButtonState, theme: ThemeInterface) => {
    const schemes: { [key in ColorScheme]: { [buttonKey in ButtonState]: string } } = {
        colored: {
            neutral: theme.colors.tango,
            hover: theme.colors.christine,
            focus: theme.colors.bamboo,
        },
        light: {
            neutral: theme.colors.white,
            hover: theme.colors.mystic,
            focus: theme.colors.baliHai,
        },
        dark: {
            neutral: theme.colors.mirage,
            hover: theme.colors.black,
            focus: theme.colors.shuttleGray,
        },
        subtle: {
            neutral: theme.colors.mystic,
            hover: theme.colors.baliHai,
            focus: theme.colors.mirage
        },
        shuttle: {
            neutral: theme.colors.shuttleGray,
            hover: theme.colors.baliHai,
            focus: theme.colors.mirage
        }
    };

    return schemes[type][buttonState];
};
