import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    type: 'large' | 'small';
}

const ArrowDownIcon = ({ type, color = 'currentColor', ...rest }: Props) => (
    <React.Fragment>
        {type === 'large' &&
        <svg width="19" height="13" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M2 2l7.517 7.517 7.287-7.287" stroke={color} strokeWidth="4" fill="none" fillRule="evenodd"/>
        </svg>
        }
        {type === 'small' &&
            <svg width="12" height="8" xmlns="http://www.w3.org/2000/svg" {...rest}>
                <path d="M1.177 1.016l4.666 4.697L10.525 1" stroke={color} strokeWidth="2.1" fill="none" fillRule="evenodd"/>
            </svg>
        }
    </React.Fragment>
);

export default ArrowDownIcon;
