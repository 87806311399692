import React from 'react';

const UserProfileIcon = () => (
    <svg width="16" height="18" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0 1)" stroke="currentColor" strokeWidth="1.89" fill="none" fillRule="evenodd">
            <rect x="4" width="8" height="9" rx="4" />
            <path d="M1 17v-2c0-1.657 1.567-3 3.5-3h7c1.933 0 3.5 1.343 3.5 3v2" />
        </g>
    </svg>
);

export default UserProfileIcon;
