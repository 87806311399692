import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const Logo30 = ({ color = 'currentColor', ...rest }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="70"
        fill="none"
        viewBox="0 0 195 89"
    >
        <path
            fill="#fff"
            d="M16.73 57.94v-1.948c1.597-.493 2.726-.727 3.706-.727 2.088 0 3.087.836 3.087 2.526v5.671c0 .536.065.62.596.792l1.192.343v.45l-1.533.514c-.192.065-.596.127-.894.15h-.086c-.64 0-1-.322-1.13-1.03l-.257.128-.723.343c-.511.237-.831.365-.958.406-.277.106-.64.15-1.046.15-1.725 0-2.916-1.07-2.916-2.591 0-1.285.788-2.12 2.258-2.441.853-.194 2.107-.365 3.578-.493v-2.31c0-1.329-.512-1.756-2.129-1.756-.235 0-.32 0-.577.044l-.893 1.777h-1.278l.002.002zm4.876 2.847c-.788.084-1.47.215-2.237.406-1.023.255-1.47.748-1.47 1.627 0 1.048.788 1.712 2.045 1.712a3.3 3.3 0 00.81-.087c.064 0 .275-.044.682-.15.043 0 .086-.022.17-.044v-3.467.003zM26.516 65.601v-.536l1.79-.748v-7.383l-1.79-.515v-.514l3.707-.836v1.22l.276-.128 1.107-.428c1.3-.492 1.511-.557 2.15-.557.98 0 1.577.343 2.15 1.24.128-.043.233-.087.298-.106.193-.062.661-.234 1.45-.555 1.107-.427 1.767-.599 2.341-.599.81 0 1.555.322 2.088.877.64.642.85 1.284.85 2.547v5.737l1.79.748v.536h-5.386v-.536l1.682-.748v-5.329c0-1.733-.788-2.44-2.77-2.44-.788 0-1.511.105-2.088.298.236.537.298.964.298 1.99v5.478l1.703.749v.536h-5.196v-.537l1.597-.748v-5.328c0-1.777-.723-2.442-2.64-2.442-.617 0-.98.044-1.703.215v7.555l1.682.749v.536h-5.386v.002zM46.04 65.218v-2.376h.893l.98 1.927c.425.065.596.084.937.084 1.322 0 2.066-.558 2.066-1.519 0-.664-.233-.985-1.064-1.477l-1.939-1.092c-1.194-.707-1.768-1.584-1.768-2.718 0-1.756 1.235-2.89 3.214-2.89.767 0 1.449.127 2.749.47l.17.066v2.139h-.893l-.853-1.734a2.89 2.89 0 00-.894-.106c-1.213.022-1.917.514-1.917 1.369 0 .599.363 1.026 1.319 1.562l1.662.92c1.362.748 1.896 1.562 1.896 2.846 0 1.8-1.406 3.125-3.344 3.125-.723 0-1.94-.234-3.217-.6l.002.004z"
        />
        <path
            fill="#F07E26"
            d="M56.995 56.45l-1.64 1.776-.363-.3c.81-1.69 2.023-2.802 3.108-2.802.596 0 .853.277.853.857 0 .128-.021.256-.043.406l-1.064 7.639v.087c0 .15.043.237.127.343l.106.106 1.66-1.8.363.322c-.788 1.65-2.045 2.803-3.087 2.803-.575 0-.873-.278-.873-.835 0-.15.022-.322.044-.493l1.064-7.576v-.106c0-.107-.022-.194-.127-.278l-.127-.15zm1.788-5.052c.555 0 .872.321.872.92 0 .898-.447 1.434-1.192 1.434-.555 0-.874-.32-.874-.898 0-.876.468-1.456 1.191-1.456h.003zM66.825 60.08l1.852 3.981c.087.193.106.193.255.256l1.108-1.54.384.255c-.512 1.606-1.449 2.782-2.237 2.782-.447 0-.552-.107-.958-.964l-1.769-3.788-.192.237c-.233.256-.552.599-.958 1.026-.596.642-.705.77-1.021 1.197.32.515.468.898.468 1.241 0 .642-.468 1.048-1.213 1.048-.745 0-1.107-.321-1.107-.92 0-.406.254-.963.7-1.5.81-.963 2.173-2.332 3.004-3.04l-1.598-3.382c-.084-.215-.105-.237-.276-.278l-1.13 1.519-.403-.256.087-.193c.745-1.84 1.362-2.61 2.128-2.61.49 0 .64.15 1.065 1.048l1.489 3.19c.533-.515 1.362-1.478 1.768-1.99l-.105-.193c-.214-.45-.277-.686-.277-1.007 0-.686.425-1.048 1.214-1.048.66 0 1.023.321 1.023.857 0 .493-.254 1.007-.853 1.65a47.992 47.992 0 01-2.277 2.247l-.17.172v.003zM40.255 42.2c-.682.748-1.614 1.747-2.792 2.993-1.08 1.165-1.61 1.75-2.08 2.327a15.984 15.984 0 009.1 1.85l-3.667-7.856-.56.686z"
        />
        <path
            fill="#F07E26"
            d="M39.884 39.457L35.23 29.6c-.25-.623-.312-.686-.807-.811l-3.29 4.43-1.181-.748.249-.56c2.172-5.365 3.972-7.61 6.206-7.61 1.428 0 1.864.436 3.104 3.056l4.343 9.297c1.552-1.497 3.973-4.306 5.153-5.802l-.308-.561c-.62-1.312-.807-1.997-.807-2.931 0-1.995 1.242-3.056 3.539-3.056 1.922 0 2.979.936 2.979 2.495 0 1.435-.745 2.931-2.484 4.804-1.738 1.872-4.284 4.368-6.642 6.55l-.496.498 4.441 9.541a16.204 16.204 0 009.89-14.943c0-8.948-7.217-16.2-16.12-16.2-8.904 0-16.12 7.252-16.12 16.2 0 5.212 2.453 9.846 6.257 12.81 2.229-2.409 4.926-5.048 6.748-6.597v-.005z"
        />
        <path
            fill="#fff"
            d="M171.94 33.121c0 13.207-10.631 23.913-23.746 23.913-13.114 0-23.748-10.706-23.748-23.913 0-.623.024-1.238.071-1.848l-1.631 2.45-11.6.201.468.068c7.179 1.078 12.278 7.685 12.346 15.104 0 .1 0 .204-.003.305-.095 9.03-7.479 16.415-16.567 16.415-9.088 0-16.442-7.421-16.57-16.415h8.387c.1 4.93 3.295 8.529 8.251 8.529 4.925-.068 8.069-3.726 8.112-8.53.003-.032.003-.067.003-.103 0-5.192-3.691-8.36-9.058-8.36h-8.383l10.466-16.048h-13.75v-7.887h35.662c4.343-4.79 10.596-7.797 17.544-7.797 13.115 0 23.746 10.71 23.746 23.916z"
        />
        <path
            fill="#fff"
            d="M171.94 33.121c0 13.207-10.631 23.913-23.746 23.913-13.114 0-23.748-10.706-23.748-23.913 0-.623.024-1.238.071-1.848l-1.631 2.45-11.6.201.468.068c7.179 1.078 12.278 7.685 12.346 15.104 0 .1 0 .204-.003.305-.095 9.03-7.479 16.415-16.567 16.415-9.088 0-16.442-7.421-16.57-16.415h8.387c.1 4.93 3.295 8.529 8.251 8.529 4.925-.068 8.069-3.726 8.112-8.53.003-.032.003-.067.003-.103 0-5.192-3.691-8.36-9.058-8.36h-8.383l10.466-16.048H94.976v-7.887h35.674c4.343-4.79 10.596-7.797 17.544-7.797 13.115 0 23.746 10.71 23.746 23.916z"
        />
        <path
            fill="url(#paint0_linear_86_2157)"
            d="M124.478 17.002H69.85v32.402h54.628V17.002z"
        />
        <path
            fill="#F07E26"
            d="M149.811 17.002h-25.33v32.402h25.33V17.002z"
        />
        <path
            fill="#fff"
            d="M148.191 57.113c13.116 0 23.749-10.706 23.749-23.913S161.307 9.287 148.191 9.287c-13.115 0-23.748 10.706-23.748 23.913s10.633 23.913 23.748 23.913zM111.754 33.992l-.468-.068v-.201l11.135-16.72H94.984v7.886h13.754L98.272 40.937h8.383c5.367 0 9.058 3.168 9.058 8.36 0 .036 0 .071-.003.104-.043 4.803-3.187 8.46-8.112 8.529-4.956 0-8.151-3.598-8.251-8.53H90.96c.128 8.995 7.458 16.416 16.57 16.416s16.472-7.386 16.567-16.415c.003-.101.003-.204.003-.305-.068-7.419-5.167-14.026-12.346-15.104z"
        />
        <path
            fill="#F07E26"
            d="M148.192 49.047c8.69 0 15.735-7.08 15.735-15.814s-7.045-15.814-15.735-15.814c-8.691 0-15.736 7.08-15.736 15.814s7.045 15.814 15.736 15.814z"
        />
        <path
            fill="#fff"
            d="M139.239 36.471c-.341.915-.882 1.288-1.857 1.181v-.742c.636.046.902-.153 1.11-.724l.257-.686-1.868-4.877h.872l1.421 3.905 1.395-3.905h.861l-2.188 5.848h-.003zM146.545 34.003c-.341 1.048-1.175 1.638-2.304 1.638-1.403 0-2.502-1.124-2.502-2.563 0-1.44 1.099-2.583 2.456-2.583 1.262 0 2.475 1.048 2.464 2.792h-4.105c.103 1.02.883 1.62 1.687 1.62.693 0 1.186-.335 1.451-.907h.853v.003zm-.777-1.44c-.2-.8-.864-1.333-1.573-1.333-.674 0-1.384.495-1.592 1.333h3.165zM150.859 34.966c-.35.43-.921.678-1.679.678-1.005 0-1.907-.457-1.907-1.516s.732-1.467 1.991-1.535c1.194-.057 1.581-.277 1.581-.666 0-.39-.368-.686-1.194-.686-.512 0-1.186.16-1.27.876h-.834c.057-.982.864-1.62 2.115-1.62 1.251 0 1.971.61 1.971 1.525v2.705c0 .248.065.61.141.8h-.796a1.49 1.49 0 01-.125-.563l.006.002zm-.011-2.076c-.322.286-.902.351-1.479.392-1.061.068-1.299.438-1.299.849 0 .533.511.762 1.137.762.834 0 1.622-.362 1.638-1.25v-.753h.003zM155.351 31.306c-.845-.046-1.565.4-1.565 1.382v2.839h-.804V30.62h.777v.553c.371-.525 1.035-.735 1.592-.667v.8zM157.965 35.652c-1.187 0-2.048-.64-2.18-1.676h.815c.103.571.663.925 1.365.925.758 0 1.223-.373 1.223-.8 0-.343-.132-.602-1.326-.762-1.186-.172-1.907-.457-1.907-1.383 0-.704.607-1.458 1.915-1.458 1.129 0 1.887.571 1.99 1.535h-.815c-.114-.526-.577-.792-1.175-.792-.645 0-1.11.305-1.11.686 0 .353.276.476 1.232.628 1.08.172 1.971.381 1.971 1.497 0 .982-.834 1.6-2.001 1.6h.003z"
        />
        <defs>
            <linearGradient
                id="paint0_linear_86_2157"
                x1="69.85"
                x2="124.478"
                y1="33.2"
                y2="33.2"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.02" stopColor="#F07E26" stopOpacity="0"/>
                <stop offset="0.08" stopColor="#F07E26" stopOpacity="0.05"/>
                <stop offset="0.56" stopColor="#F07E26"/>
            </linearGradient>
        </defs>
    </svg>
);

export default Logo30;