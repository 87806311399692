import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import Flex from "../atoms/Flex";

interface Props {
    buttonText: string;
    buttonLink: string;
}

const CTABlock: FC<PropsWithChildren<Props>> = ({ buttonText, buttonLink, children }) => 
    <CTABlockContainer>
        {children}
        <CTAButton to={buttonLink}><span>{buttonText}</span></CTAButton>
    </CTABlockContainer>

const CTABlockContainer = styled(Flex)`
    position: sticky;
    bottom: 0;
    background-color: #F7F9FA;   
    padding: 3.2rem 6.4rem;
`

const CTAButton = styled(Link)`
    display: flex;
    padding: 0 3rem;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
    border-radius: 100rem;
    background: #192229;
    margin-left: auto;
    border: 1px solid #192229;
    max-height: 48px;
    
    span {
        color: #FFF;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.1rem;
        letter-spacing: 0.42px;
        text-transform: uppercase;
    }
    
    &:hover {
        background-color: ${props => props.theme.colors.white};
        span {
            color: #192229;
        }
    }
`

export default CTABlock;