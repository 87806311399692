import React, { Fragment, SFC } from 'react';
import { compose } from 'recompose';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import ObserveSize from 'react-observe-size';
import gql from 'graphql-tag';
import { DataValue } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';
import Container from '../atoms/Container';
import Separator from '../atoms/Separator';
import FooterContact from '../molecules/footer/FooterContact';
import FooterLinks from '../molecules/footer/FooterLinks';
import FooterSocial from '../molecules/footer/FooterSocial';
import { FooterLinkFragment, FooterSocialsFragment, MenuFragment, MenuQuery } from '../../entities/operationResults';
import { getEntriesOfType } from '../../services/entryTypes';
import FooterMenu from '../molecules/footer/FooterMenu';
import { path } from 'ramda';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

export const FooterFragments = gql`
    fragment FooterLink on footerLink_footerLink_Entry {
        id
        title
        linkEntry{
            uri
        }
    }
`;

const enhanced = compose<OutProps, InProps>(
    withTheme,
    withRouter,
);

interface InProps {
    data: DataValue<MenuQuery> | null;
}

interface OutProps extends ThemeProps, RouteComponentProps<ExchangeParams> {
}

const Footer: SFC<OutProps & InProps> = ({ data, theme }) => {
    const menuItems: MenuFragment[] | null = getEntriesOfType('menu_menu_Entry', data && data.menuItems);
    const footerLinks: FooterLinkFragment[] | null = getEntriesOfType('footerLink_footerLink_Entry', data && data.footerLinks);
    const socialLinks = path<FooterSocialsFragment>(['globals', 'socialMedia'], data || {});
    const mailChimpUrl = path<string>(['globalSet', 'mailChimpSubscriptionUrl'], data || {});
    const footerContactFormText = path<string>(['globals', 'exchangeConfig', 'footerContactFormText'], data || {});
    const CookieLinkObj = footerLinks && footerLinks.find(obj => {
        return obj.title === 'Cookie policy';
    });
    const cookieLink = CookieLinkObj && CookieLinkObj.linkEntry && CookieLinkObj.linkEntry[0];
    return (
        <Fragment>
            <ObserveSize>
                {({ width }) =>
                    <Wrapper>
                        <Container
                            maxWidth
                            paddingTop={'3rem'}
                            paddingTopL={'7.2rem'}
                            paddingBottom={'1rem'}
                            paddingBottomL={'9.5rem'}
                        >
                            <FooterWrap>
                                <FooterContact mailChimpUrl={mailChimpUrl} contactFormText={footerContactFormText} />
                                {width >= theme.mediaQueriesValues.m &&
                                <FooterMenu menuItems={menuItems || []} />
                                }
                            </FooterWrap>
                        </Container>
                            <Separator color={theme.colors.shuttleGray}/>
                        <Container maxWidth paddingTop={'4rem'} paddingTopL={'2rem'} paddingBottom={'2rem'} paddingBottomL={'4rem'}>
                            <FooterWrap className={'small-centered'}>
                            <FooterLinks footerLinks={footerLinks || []}/>
                            <FooterSocial socialLinks={socialLinks}/>
                            </FooterWrap>
                        </Container>
                    </Wrapper>
                }
            </ObserveSize>
            <CookieConsent
                buttonStyle={{
                    color: 'white',
                    fontSize: '16px',
                    lineHeight: '1.5em',
                    backgroundColor: theme.colors.vulcan,
                    height: '100%',
                    margin: '0px',
                    paddingLeft: '61px',
                    paddingRight: '61px',
                    paddingTop: '17px',
                    paddingBottom: '17px'
                }}
                style={{
                    background: theme.colors.tango,
                    fontSize: '16px',
                    lineHeight: '1.5em',
                    margin: '0px',
                    boxShadow: '0px 0px 10px 5px rgba(0,0,0,0.2)'
                }}
                buttonText="Close"
            >
                    This site uses cookies for analytics and personalized content. By continuing to browse
                this site, you agree to this use. Learn more in our {cookieLink && cookieLink.uri ?
                    <CookieLink to={`/${cookieLink.uri}`}>Cookie Policy</CookieLink> : 'Cookie Policy'}
            </CookieConsent>
        </Fragment>
    );
};

const CookieLink = (styled(Link))`
    text-decoration: underline;
`;

const Wrapper = styled.div`
    position: relative;
    z-index: 10;
    background-color: ${props => props.theme.colors.black};
`;

const FooterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &.small-centered  {
    text-align: center;
    flex-direction: column;
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
      text-align: left;
      flex-direction: row;
    }
  }
`;

export default enhanced(Footer);