import React, { Fragment, SFC } from 'react';
import * as Form from '../atoms/Form';

const RegisterCommercialDetails: SFC = () => {
    return (
        <Fragment>
            <Form.Field name="commercialEmail" label="Commercial Email *" placeholder="Commercial Email"/>
            <Form.Field name="typesOfServices" label="Types of Services *" placeholder="Types of Services"/>
        </Fragment>
    );
};

export default RegisterCommercialDetails;
