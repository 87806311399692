import React, { SFC } from 'react';
import Link from 'react-scroll/modules/components/Link';
import { compose, withProps as withPropsEnhancer, withState } from 'recompose';
import styled from '../../styled-components';

interface InProps {
    menu: MenuItem[];
    isModal?: boolean;
}

interface OutProps {
    activeId: string | null;
    setActiveId: (activeId: string | null) => void;
}

export interface MenuItem extends React.HTMLAttributes<HTMLUListElement> {
    id: string;
    title: string;
    children?: Array<{
        id: string;
        title: string;
    }>;
}

const PageNav: SFC<InProps & OutProps> = ({ activeId, setActiveId, menu, isModal, ...rest }) => {
    const activeParent: MenuItem | undefined = menu.find(
        item => typeof (item.children && item.children.find(child => child.id === activeId)) !== 'undefined'
    );
    const activeParentId = activeParent && activeParent.id;
    let scrollConfig = {
        duration: 1000,
        smooth: true,
        offset: -180,
        onSetActive: (to: string) => setActiveId(to),
        spy: true,
        containerId: '',
    };

    if (isModal) {
        scrollConfig = {
            ...scrollConfig,
            containerId: 'modal',
        };
    }

    return (
        <NavList {...rest}>
            {menu.map(item => {
                const itemActive = activeId === item.id;
                const childActive = activeParentId === item.id;
                const expandIcon =
                    item.children && item.children.length ? (
                        <ExpandIcon>{itemActive || childActive ? '-' : '+'}</ExpandIcon>
                    ) : (
                        ''
                    );
                return (
                    <SectionItem key={item.id}>
                        <SectionLink className={itemActive ? 'active' : undefined}>
                            <Link href={`#${item.id}`} to={item.id} {...scrollConfig}>
                                {item.title}
                                {expandIcon}
                            </Link>
                        </SectionLink>
                        {item.children && !!item.children.length && (
                            <SubList isOpen={itemActive || childActive}>
                                {item.children.map(child => (
                                    <SubsectionItem key={child.id}>
                                        <SubsectionLink className={child.id === activeId ? 'active' : undefined}>
                                            <Link href={`#${child.id}`} to={child.id} {...scrollConfig}>
                                                {child.title}
                                            </Link>
                                        </SubsectionLink>
                                    </SubsectionItem>
                                ))}
                            </SubList>
                        )}
                    </SectionItem>
                );
            })}
        </NavList>
    );
};

const SectionItem = styled.li`
    list-style: none;
    border-left: 0.1rem solid rgba(92, 109, 128, 0.4);
`;

const SubsectionItem = styled(SectionItem)`
    border: none;
`;

const SectionLink = styled.div`
    font: 1.4rem chalet-new-york-sixty, sans-serif;
    color: inherit;
    line-height: 1.6rem;
    padding: 0.8rem 2rem 0.8rem 1.5rem;
    max-width: 25rem;
    position: relative;
    &.active {
        color: ${props => props.theme.colors.blazeOrange};

        &:after {
            position: absolute;
            width: 0.3rem;
            top: 0;
            left: -0.2rem;
            bottom: 0;
            content: '';
            background: ${props => props.theme.colors.blazeOrange};
        }
    }

    @media screen and (max-width: ${props => props.theme.mediaQueries.l}) {
        max-width: none;
    }
`;

const SubsectionLink = styled(SectionLink)`
    padding-left: 3rem;
    &.active {
        padding-left: 2.8rem;
    }
`;

const NavList = styled.ul`
    color: #92a5b3;
    padding: 0;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        padding-top: 40px;
    }

    ul {
        padding-left: 0;
    }
`;

const ExpandIcon = styled.span`
    float: right;
    font-size: 16px;
    margin-right: 5px;
`;

const SubList = styled.ul<{ isOpen?: boolean }>`
    ${props => (props.isOpen ? '' : 'display: none')};

    @media screen and (max-width: ${props => props.theme.mediaQueries.l}) {
        display: block;
    }
`;

const enhance = compose<OutProps, InProps>(
    withState('activeId', 'setActiveId', null),
    withPropsEnhancer(({ activeId, menu }) => ({
        activeId: activeId === null && menu.length ? menu[0].id : activeId,
    }))
);

export default enhance(PageNav);
