import React, { Fragment, SFC } from 'react';
import * as Form from '../atoms/Form';
import styled from 'styled-components';
import theme from '../../constants/theme';
import countries from 'countries-list/dist/countries.json';

interface Props {
    resellerForm?: boolean;
}

export const GenerateCountryList = () => {
    const countryList = Object.keys(countries).map((key) => {
        const country = countries[key];
        return country && country.name;
    });

    return countryList;
};

const RegisterCompanyDetails: SFC<Props> = ({ resellerForm }: Props) => {
    const industryOptions = ['Cloud Service Provider', 'Software', 'Software as a service', 'Telecom & Network Services',
        'Data Center Solutions', 'Content Delivery Network (CDN)', 'Infrastructure as a Service', 'Platform as a Service',
        'Public sector', 'Financial services', 'Education & Research', 'Media & Entertainment', 'Gaming',
        'Advertising', 'Internet exchanges', 'Content Provider', 'Enterprise'];

    const countryList = GenerateCountryList();

    return (

        <Fragment>
            <Form.Field name="companyName" label="Company Name *" placeholder="Your company's full name" />
            <Form.Field name="url" label="URL *" placeholder="Link to your company's website" />
            <Form.Field
                name="industry"
                label="Industry *"
                placeholder="Choose..."
                type="select"
                options={industryOptions}
            />
            <Form.Field
                name="mainLocation"
                label="Main headquarter’s location *"
                placeholder="Choose..."
                type="select"
                options={countryList}
            />
            {!resellerForm && (
                <Fragment>
                    <StyledStrong>Do you wish to become a member of the Association?</StyledStrong>
                    <Text>Read more about the benefits of becoming a member <StyledLink href={'/ams/about-amsix'} target="_blank">here.</StyledLink></Text>
                </Fragment>
            )}
        </Fragment>
    );
};

export default RegisterCompanyDetails;

const StyledStrong = styled.strong`
  font-size: 1.2rem;
  line-height: 2.4rem;
`;

const Text = styled.p`
  font-size: 1.2rem;
  color: ${theme.colors.shuttleGray};
  margin-top: 0;
`;

const StyledLink = styled.a`
  color: ${theme.colors.tango};
  border-bottom: .1rem solid ${theme.colors.tango}
`;