import linkIcon from '../../assets/link_icon.svg';
import styled from '../../styled-components';
import { BodyLink } from './buttons/BodyLink';

interface Props {
    color?: string;
    maxWidth?: string;
    maxWidthM?: string;
    textShadow?: boolean;
}

const ContentBody = styled.div<Props>`
    font: ${props => props.theme.fonts.small.contentBody};
    color: ${props => (props.color ? props.color : props.theme.colors.black)};
    ${props => (props.textShadow ? 'text-shadow: 0 0 20px rgba(0, 0 , 0, 0.15);' : '')}
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        font: ${props => props.theme.fonts.large.contentBody};
    }
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
    }
    
    ${BodyLink}
    
    b, strong {
        font-weight: normal; 
        font-family: chalet-new-york-sixty;
    }
    
    p {
        margin-bottom: 2.4rem;
        margin-top: 0;
    }
    
    table {
          width: 100%;
          min-width: 620px;
          border: none;
          font: ${props => props.theme.fonts.small.sectionItemTitle};
          letter-spacing: 0.16px;
          border-spacing: 0;
          margin-bottom: 2.4rem;
              
          @media screen and (min-width: ${props => props.theme.mediaQueries.l}px) {
              font: ${props => props.theme.fonts.large.sectionItemTitle};
          }
          
          td, th {
              padding: 1.5rem 1.5rem 1.5rem 0;
          }
          
          tr td a {
              left: -1rem;
          }
          
          tr td:last-child {
              text-align: right;
              padding-right: 0;
              a {
                left: 1rem;
              }
          }
          
          tr th, tr:not(:last-child) td {
              border-bottom: 1px solid ${props => props.theme.colors.alto};
          }
          
          tr td:not(:first-child) {
              font: ${props => props.theme.fonts.small.sectionItemSubtitle};
              color: ${props => props.theme.colors.shuttleGray};
              font-size: 2rem;
              
              @media screen and (min-width: ${props => props.theme.mediaQueries.l}px) {
                  font: ${props => props.theme.fonts.large.sectionItemSubtitle};
              }
          }
          
          thead {
              color: ${props => props.theme.colors.baliHai};
              
              th {
                  text-align: left;
              }
          }
          
          a {
              position: relative;
              display:inline-block;
              margin: 0 1rem;
              letter-spacing: 0.86px;
              font: ${props => props.theme.fonts.large.textLink};
              line-height: 3.6rem;
              text-decoration: inherit;
              color: ${props => props.theme.colors.black};
              text-transform: uppercase;
              padding-right: 2.9rem;
              
              :after {
                  content: "";
                  background-image: url(${linkIcon});
                  background-repeat: no-repeat;
                  background-color: transparent;
                  width: 17px;
                  height: 17px;
                  right: 0;
                  left: initial;
                  position: absolute;
                  top: calc(50% - 7px);
              }
              
              &:hover:after, &:focus:after {
                    opacity: 1;
              }
              @media screen and (min-width: ${props => props.theme.mediaQueries.l}px) {
                  font: ${props => props.theme.fonts.large.textLink};
              }
          }
      }
    
`;

export default ContentBody;
