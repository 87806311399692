import React from 'react';
import styled from '../../../styled-components';

interface Props extends React.HTMLAttributes<HTMLElement>{
    text: string;
    clicked: boolean;
}

const TabButton = styled.button<{ pressed: boolean; }>`
    background-color: white;
    border: none;
    border-bottom: 2px solid ${props => (props.pressed ? props.theme.colors.blazeOrange : props.theme.colors.transparentGrey)};
    color: ${(props) => (props.pressed ? props.theme.colors.blazeOrange : props.theme.colors.baliHai)};
    cursor: pointer
    display: block;
    float: left;
    font-size: 17px;
    font-family: urw-din;
    font-weight: 600;
    font-size: 1.2rem;
    outline: none;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    &:hover {
        border-bottom: 2px solid ${(props) => props.theme.colors.blazeOrange};
        color: ${(props) => props.theme.colors.blazeOrange};
    }
`;

export default ({text, clicked, ...rest }: Props) => (
    <TabButton type="button" pressed={clicked} {...rest }>{text}</TabButton>
);
