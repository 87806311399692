import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const CloseIcon = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M2 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM7 5a2 2 0 1 1 0 4 2 2 0 1 1 0-4zM12 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM2 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM12 10a2 2 0 1 1 0 4 2 2 0 1 1 0-4z"
                fill={color}
            />
            <path d="M1.667 1.667l10.666 10.666M12.333 1.667L1.667 12.333" stroke={color} strokeWidth="1.4" />
        </g>
    </svg>
);

export default CloseIcon;