import styled from '../../styled-components';
import { BodyLink } from '../atoms/buttons/BodyLink';
import Separator from '../atoms/Separator';

export const Container = styled.div`
    padding-top: 3.4rem;

    h1 {
        font: ${props => props.theme.fonts.small.introTitle};
        margin: 0 0 3.2rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-top: 8.8rem;
        h1 {
            font: ${props => props.theme.fonts.medium.introTitle};
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        max-width: 900px;
        margin: 0 auto;

        h1 {
            font: ${props => props.theme.fonts.large.introTitle};
        }
    }
`;

export const Text = styled.div`
    font: ${props => props.theme.fonts.small.introText};
    letter-spacing: 0.3px;
    margin-top: 3.2rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        /* max-width: 75%; */
        font: ${props => props.theme.fonts.large.introText};
    }

    ${BodyLink}

    p {
        margin-top: 0;
        margin-bottom: 3.2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const Sep = styled(Separator).attrs((props) => ({
    color: props.theme.colors.tango,
}))`
    width: 12rem;
    height: 2px;
`;
