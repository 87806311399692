import Raven from 'raven-js';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import createClient from './createClient';
import GlobalStyling from './globalStyling';
import ScrollToTop from './hocs/ScrollToTop';

const root = document.getElementById('root');

if (process.env.NODE_ENV === 'production') {
    Raven.config('https://e6e369dfef374a63a6be292ff4383927@sentry.io/1202354').install();

    ReactGA.initialize(process.env.RAZZLE_APP_GA_CODE || '');
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const client = createClient(window.__APOLLO_STATE__);

if (root) {
    ReactDOM.hydrate(
        <Fragment>
            <BrowserRouter>
                <ScrollToTop>
                    <App client={client} />
                </ScrollToTop>
            </BrowserRouter>
            <GlobalStyling />
        </Fragment>,
        root
    );
}

if (module.hot) {
    module.hot.accept();
}
