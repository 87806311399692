import * as styledComponents from 'styled-components';
import { ThemeProps as StyledComponentThemeProps } from 'styled-components';
import { ThemeInterface } from './constants/theme';

type ThemeProps = StyledComponentThemeProps<ThemeInterface>;

const {
    default: styled,
    css,
    createGlobalStyle,
    keyframes,
    ThemeProvider,
    withTheme,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeInterface>;

export { css, createGlobalStyle, keyframes, ThemeProvider, withTheme, ThemeProps };
export default styled;
