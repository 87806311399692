import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const PlusIcon = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g id="ATOMS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="icon" transform="translate(-0.666667, -0.666667)">
                <rect id="terminal" fill={color} x="0.666666667" y="6.66666667" width="4" height="4" rx="2" />
                <rect id="terminal" fill={color} x="6.66666667" y="12.6666667" width="4" height="4" rx="2" />
                <rect id="terminal" fill={color} x="12.6666667" y="6.66666667" width="4" height="4" rx="2" />
                <rect id="terminal" fill={color} x="6.66666667" y="0.666666667" width="4" height="4" rx="2" />
                <path d="M3.33333333,8.66666667 L14,8.66666667" id="line" stroke={color} strokeWidth="1.4" />
                <path d="M8.66666667,3.33333333 L8.66666667,14" id="line" stroke={color} strokeWidth="1.4" />
            </g>
        </g>
    </svg>
);

export default PlusIcon;