import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const LinkedIn = ({ type, color = 'currentColor', ...rest }: Props) => (
    <svg width="16" height="16">
        <g fill={color} fillRule="nonzero">
            <path d="M3.724 5.147H.512a.258.258 0 0 0-.258.258v10.32c0 .142.116.257.258.257h3.212a.258.258 0 0 0 .258-.258V5.405a.258.258 0 0 0-.258-.258zM2.12.018A2.12 2.12 0 0 0 0 2.135c0 1.168.95 2.118 2.12 2.118a2.12 2.12 0 0 0 2.117-2.118A2.12 2.12 0 0 0 2.12.018zM11.894 4.89c-1.29 0-2.243.555-2.822 1.186v-.67a.258.258 0 0 0-.258-.259H5.738a.258.258 0 0 0-.258.258v10.32c0 .142.116.257.258.257h3.205a.258.258 0 0 0 .258-.258V10.62c0-1.72.467-2.39 1.667-2.39 1.306 0 1.41 1.074 1.41 2.478v5.017c0 .143.115.258.258.258h3.206a.258.258 0 0 0 .258-.258v-5.66c0-2.558-.488-5.173-4.106-5.173z" />
        </g>
    </svg>
);

export default LinkedIn;
