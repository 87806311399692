import React, { ComponentType, SFC } from 'react';
import { ModalInProps } from '../../hocs/withModal';
import generatePath from 'react-router-dom/generatePath';
import { ExchangeParams } from '../Exchange';
import { getStartedRoute } from '../../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, withState } from 'recompose';
import { Block as ButtonBlock } from './ButtonContentBlock';
import DefaultButton from '../atoms/buttons/DefaultButton';
import RegisterSlideover from '../organisms/RegisterSlideover';
import Container from '../atoms/Container';
import styled from 'styled-components';

interface InProps {
    resellerForm?: boolean;
    buttonText: string;
    slideover: ComponentType<ModalInProps>;
}

interface OutProps extends RouteComponentProps<ExchangeParams> {
    isModalOpen: boolean;
    setIsModalOpen: (isModalOpen: boolean) => void;
}

const RegisterSlide: SFC<InProps & OutProps> = ({ resellerForm, buttonText, slideover: Slideover, isModalOpen, setIsModalOpen, match, ...rest }) => {
    return (
        <Container maxWidth paddingBottom={'6rem'} paddingBottomL={'9.6rem'}>
            <Wrapper>
                <ButtonBlock>
                    <DefaultButton
                        colorScheme={'colored'}
                        onClick={(e) => {
                            e.preventDefault();
                            setIsModalOpen(true);
                        }}
                        to={'#'}
                    >
                        <span>{buttonText}</span>
                    </DefaultButton>
                </ButtonBlock>
                <RegisterSlideover
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    getStartedPath={generatePath<ExchangeParams>(getStartedRoute.path, { exchange: match.params.exchange })}
                    resellerForm={resellerForm}
                    {...rest}
                />
            </Wrapper>
        </Container>
    );
};

const Wrapper = styled.div`
    text-align: center;
`;

const enhance = compose<OutProps, InProps>(
    withRouter,
    withState('isModalOpen', 'setIsModalOpen', false)
);

export default enhance(RegisterSlide);
