import React, { useMemo } from 'react';
import styled from '../../../../styled-components';
import Container from '../../../atoms/Container';
import { MenuColumnFragment, MenuFragment, MenuItemFragment } from '../../../../entities/operationResults';
import { NavHeading, NavList } from '../../../atoms/NavItems';
import { getEntriesOfType } from '../../../../services/entryTypes';
import LinkIconRight from '../../../atoms/icons/LinkIconRightV2';
import { Link, NavLinkProps } from 'react-router-dom';
import CTABlock from '../../CTABlock';
import Flex from '../../../atoms/Flex';
import { CTAHeading, CTAText } from './DropdownLocations';

interface Props {
    menuSubs: MenuFragment;
    setVisible: (visible: boolean) => void;
    reverse?: boolean;
}

function getMenuItemsLayout(menuColumn: MenuColumnFragment): MenuItemFragment[] {
    return getEntriesOfType('menu_menu_Entry', menuColumn.children);
}

const DropdownServices = ({ menuSubs, setVisible }: Props) => {
    const currentExchange = useMemo(() => window.location.pathname.split('/')[1], [window.location.pathname]);
    if (!menuSubs) {
        return null;
    }
    
    const menuColumns: MenuColumnFragment[] = getEntriesOfType('menu_menu_Entry', menuSubs.children);
    return (
        <>
            <Container paddingTop={'6.4rem'} paddingBottom={'4rem'} maxWidth>
                <Flex gap={16} alignItems='center' style={{paddingBottom: 56}}>
                    <LinkIconRight width={38} height={38} color="#F58025"/>
                    <DropdownHeading>Our Services</DropdownHeading>
                </Flex>
                <GridContainer>
                    {menuColumns.map((menuColumn, index) => {
                        if (!menuColumn) {
                            return null;
                        }

                        return(
                            <div key={`servicenavcolumn-${index}`}>
                                <StyledNavHeading>{menuColumn.title}</StyledNavHeading>
                                <StyledNavList column>
                                    {getMenuItemsLayout(menuColumn).map((menuItem) => {
                                        const menuItemLink = menuItem.externalLink
                                            ? menuItem.externalLink
                                            : menuItem.linkEntry && menuItem.linkEntry[0]
                                                ? '/' + menuItem.linkEntry[0]!.uri
                                                : null;
                                        return(
                                            menuItemLink &&
                                            <NavItem key={menuItem.id || ''} className="NAVITEM">
                                                {
                                                    menuItem.externalLink ?
                                                        <NavExternalLink href={ menuItemLink } target={ '_blank' }>
                                                            {menuItem.title}
                                                        </NavExternalLink>
                                                        :
                                                        <NavLink
                                                            to={ menuItemLink }
                                                            onClick={() => { setVisible(false); }}
                                                        >
                                                            {menuItem.title}
                                                        </NavLink>
                                                }
                                            </NavItem>
                                        );
                                    })}
                                </StyledNavList>
                            </div>
                        );
                    })}
                </GridContainer>
                
            </Container>
            <CTABlock buttonText="Contact us" buttonLink={`/${currentExchange}/contact-us`}>
                <Flex direction="column" gap={8}>
                    <CTAHeading>Unsure about which services fit your needs best?</CTAHeading>
                    <CTAText>Contact us for more information about our services</CTAText>
                </Flex>
            </CTABlock>
        </>
    );
};

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto-fit 1fr;
    grid-row-gap: 3.2rem;
    > .list-column {
        padding-bottom: 96px;
    }
`
const NavLink = styled(Link)<NavLinkProps>`
    color: inherit;
    font: 1.6rem/2.4rem chalet-new-york-sixty,sans-serif;
    color: ${(props) => props.isActive ? props.theme.colors.tango : 'inherit'};
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 600;
    justify-content: space-between;
    &:hover{
        color: ${(props) => props.theme.colors.tango};
    }
`;

const NavExternalLink = styled.a<{ 'data-active'?: boolean }>`
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ 'data-active': active, theme }) => (active ? theme.colors.tango : 'inherit')};

    svg {
        margin-left: 0.7rem;
    }

    &:hover {
        color: ${props => props.theme.colors.tango};
    }
`;

const StyledNavHeading = styled(NavHeading)`
    color: ${props => props.theme.colors.baliHai};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem; /* 171.429% */
    text-transform: uppercase;
    margin-bottom: .8rem;
`

const NavItem = styled.li`
    /* color: #192229; */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 32.4px */
    letter-spacing: 0.1px;
`

const StyledNavList = styled(NavList)`
    margin-top: 8px;
    gap: 8px;
`
const DropdownHeading = styled.h3`
    color: inherit;
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 2.4rem;
    margin: 0;
`

export default DropdownServices;
