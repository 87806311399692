import React from 'react';
import styled from '../../styled-components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    backgroundColor?: string;
    maxWidth?: boolean;
    paddingTop?: string;
    paddingBottom?: string;
    marginTop?: string;
    paddingTopL?: string;
    paddingBottomL?: string;
    marginTopL?: string;
}

const Container = styled.section<Props>`
    padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
    padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'none')};
    padding-left: ${props => (props.maxWidth ? '2.4rem' : '0')};
    padding-right: ${props => (props.maxWidth ? '2.4rem' : '0')};
    margin-top: ${props => (props.marginTop ? props.marginTop : '0')};

    > div {
        @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
            max-width: ${props => (props.maxWidth ? props.theme.maxWidths.s + 'px' : '')};
            margin: 0 auto;
        }
        @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
            max-width: ${props => (props.maxWidth ? props.theme.maxWidths.m + 'px' : '')};
        }
        @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
            max-width: ${props => (props.maxWidth ? props.theme.maxWidths.l + 'px' : '')};
        }
        @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
            max-width: ${props => (props.maxWidth ? props.theme.maxWidths.xl + 'px' : '')};
        }
        @media screen and (min-width: ${props => props.theme.mediaQueries.xxl}) {
            max-width: ${props => (props.maxWidth ? props.theme.maxWidths.xxl + 'px' : '')};
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        padding-left: ${props => (props.maxWidth ? '5.6rem' : '0')};
        padding-right: ${props => (props.maxWidth ? '5.6rem' : '0')};
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-left: ${props => (props.maxWidth ? '8rem' : '0')};
        padding-right: ${props => (props.maxWidth ? '8rem' : '0')};
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        ${props => (props.paddingTopL ? `padding-top: ${props.paddingTopL}` : '')};
        ${props => (props.paddingBottomL ? `padding-bottom: ${props.paddingBottomL}` : '')};
        ${props => (props.marginTopL ? `margin-top: ${props.marginTopL}` : '')};
    }

    &::after {
        clear: both;
        content: '';
        display: table;
    }
`;

export default ({ children, ...rest }: Props) => (
    <Container {...rest}>
        <div>{children}</div>
    </Container>
);
