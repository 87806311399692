import withModal, { ModalInProps } from '../../hocs/withModal';
import React, { SFC } from 'react';
import { compose } from 'recompose';
import Container from '../atoms/Container';
import { DataProps } from 'react-apollo';
import { ContactSlideoverQuery } from '../../entities/operationResults';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExchangeParams } from '../Exchange';
import styled from '../../styled-components';
import RegisterForm from '../molecules/RegisterForm';

interface InProps extends ModalInProps {
    resellerForm?: boolean;
}

type OutProps = RouteComponentProps<ExchangeParams> & DataProps<ContactSlideoverQuery>;

type Props = InProps & OutProps;

const RegisterSlideover: SFC<Props> = ({ resellerForm }) => {
    return (
        <Wrapper maxWidth>
            <div>
                <RegisterForm resellerForm={resellerForm}/>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    overflow: scroll;
    
    > div {
        display: flex;
        flex-direction: column;
    }

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        > div {
            flex-direction: row;
        }
    }
`;

const enhance = compose<OutProps, InProps>(
    withModal,
    withRouter,
);

export default enhance(RegisterSlideover);