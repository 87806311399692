const scrollToSectionHash = () => {
    if (typeof window !== undefined) {
        const hash = window.location.hash;
        if (hash) {
            const sectionEl = document.getElementsByName(hash.replace('#', ''))[0];
            if (sectionEl) {
                const topOffset = sectionEl.getBoundingClientRect().top - 96;
                window.scrollTo({
                    top: topOffset,
                    behavior: 'smooth',
                })
            }
        }
    }
}

export default scrollToSectionHash;