export const formatBitUnits = (mb: number | null) => {
    if (!mb) {
        return {
            amount: 0,
            unit: 'Mb'
        };
    }

    if (mb > 1000000) {
        return {
            amount: +(mb / 1000000).toFixed(3),
            unit: 'Tb'
        };
    } else if (mb > 1000) {
        return {
            amount: +(mb / 1000).toFixed(2),
            unit: 'Gb'
        };
    } else {
        return {
            amount: mb,
            unit: 'Mb'
        };
    }
};