export default (startDate: string, endDate: string | null) => {
    if (endDate && startDate !== endDate) {
        if (startDate.split(' ')[1] === endDate.split(' ')[1]) {
            return `${startDate.split(' ')[0]} - ${endDate}`;
        } else {
            return `${startDate} - ${endDate}`;
        }
    } else {
        return startDate;
    }
};
