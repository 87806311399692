import React, { Fragment, SFC } from 'react';
import * as Form from '../atoms/Form';
import styled from 'styled-components';
import { GenerateCountryList } from './RegisterCompanyDetails';
import { Values } from './RegisterForm';

interface Props {
    values: Values;
}

const RegisterBilling: SFC<Props> = ({ values }: Props) => {
    const countryList = GenerateCountryList();

    return (
        <Fragment>
            <StyledH3Title>Legal Address </StyledH3Title>

            <Form.Field name="address" label="Address line *" placeholder="Your address" type="textarea"/>
            <Form.Field name="city" label="City *" placeholder="Your city"/>
            <Form.Field name="postalCode" label="Postal (zip) code *" placeholder="Your postalcode"/>
            <Form.Field
                name="country"
                label="Country *"
                placeholder="Choose..."
                type="select"
                options={countryList}
            />

            <StyledH3Title>Billing information </StyledH3Title>

            <Form.Field
                name="sameAsRegisteredOfficeAddress"
                label="Same as registered office address"
                type="checkbox"
                defaultChecked={values.sameAsRegisteredOfficeAddress}
            />

            <OptionalForm hide={values.sameAsRegisteredOfficeAddress}>
                <Form.Field name="billingName" label="Billing Name *" placeholder="Billing name"/>
                <Form.Field type="textarea" name="billingAddress" label="Address line *" placeholder="Billing address"/>
                <Form.Field name="billingCity" label="City *" placeholder="Billing city"/>
                <Form.Field name="billingPostalCode" label="Postal (zip) code *" placeholder="Billing postalcode"/>
            </OptionalForm>

            <StyledH3Title>Additional Billing Details</StyledH3Title>
            <Form.Field name="vatNumber" label="VAT number" placeholder="Your VAT number"/>

        </Fragment>
    );
};

export default RegisterBilling;

const StyledH3Title = styled.h3 `
    font: ${(props) => props.theme.fonts.small.introText};
    max-width: 80rem;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        font: ${(props) => props.theme.fonts.large.introText};
    }
`;

const OptionalForm = styled.div<{hide: boolean}>`
    display: ${(props) => props.hide ? 'none' : 'block'};
`;