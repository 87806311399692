import styled from '../../styled-components';

interface Props {
    color?: string;
}

const Separator = styled.div<Props>`
    width: 100%;
    height: 1px;
    background-color: ${({ color, theme }) => (color ? color : theme.colors.mystic)};
`;

export default Separator;
