/* tslint:disable */
//  This file was automatically generated and should not be edited.

export enum MenuScheme {
  dark = "dark",
  light = "light",
  transparent = "transparent",
}


export interface AllCollectionsQueryVariables {
  site?: Array< string > | null,
  type?: Array< string | null > | null,
  collectionType?: Array< string | null > | null,
  offset: number,
  itemLimit: number,
  order?: string | null,
  listExpiryDate?: Array< string | null > | null,
  listInPastEvents?: boolean | null,
};

export interface AllCollectionsQuery {
  // This query is used to query for entries.
  collectionItems:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s post date.
      date: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s post date.
      date: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      startDate: string | null,
      endDate: string | null,
      // The entry’s post date.
      date: string | null,
      listInPastEvents: boolean | null,
      location: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      newsletterUrl: string | null,
      newsletterDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s post date.
      date: string | null,
    }
  ) | null > | null,
  // This query is used to return the number of entries.
  entryCount: number,
  // This query is used to query for entries.
  collection:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      introHeading: string | null,
      introText: string | null,
      featuredStories:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          description: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          // The entry’s post date.
          postDate: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      introHeading: string | null,
      introText: string | null,
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      introHeading: string | null,
      introText: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      introHeading: string | null,
      introText: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
};

export interface ConnectedNetworksQueryVariables {
  exchange?: Array< string > | null,
  search?: string | null,
};

export interface ConnectedNetworksQuery {
  // This query is used to query for a single entry.
  connectedNetworksPage: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s title.
      title: string | null,
      introHeading: string | null,
      introText: string | null,
      exportLinks:  Array< {
        __typename: "exportLinks_TableRow",
        title: string | null,
        url: string | null,
      } | null > | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
  // This query is used to query for entries.
  connectedNetworksTable:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s title.
      title: string | null,
      memberUrl: string | null,
      asNumber: string | null,
      peeringPolicy: string | null,
      routeserver: boolean | null,
      vlan: string | null,
      colocation: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
  // This query is used to query for a single global set.
  connectedNetworksStats: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      routeserverPeers: string | null,
      connectedNetworks: string | null,
      customerPorts: string | null,
      ipv6Peers: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
};

export interface DocumentationQueryVariables {
  exchange?: Array< string > | null,
  slug?: Array< string > | null,
};

export interface DocumentationQuery {
  // This query is used to query for a single entry.
  entry: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s title.
      title: string | null,
      description: string | null,
      contentBlocks:  Array<( {
          __typename: "contentBlocks_intro_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_text_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_textWithImage_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          textHTML: string | null,
          imageAlignment: string | null,
        } | {
          __typename: "contentBlocks_image_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
          textAlignment: string | null,
        } | {
          __typename: "contentBlocks_graphic_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_video_BlockType",
          videoUrl: string | null,
        } | {
          __typename: "contentBlocks_audio_BlockType",
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "contentBlocks_table_BlockType",
          heading: string | null,
          table: string | null,
        } | {
          __typename: "contentBlocks_codeSnippet_BlockType",
          programmingLanguage: string | null,
          snippet: string | null,
        } | {
          __typename: "contentBlocks_contactForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          documentUpload: boolean | null,
          description: string | null,
        } | {
          __typename: "contentBlocks_logos_BlockType",
          logos:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_button_BlockType",
          // The ID of the entity
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_sectionEnding_BlockType",
        } | {
          __typename: "contentBlocks_users_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          users:  Array<( {
              __typename: "User",
              // The user’s full name.
              fullName: string | null,
              functionTitle: string | null,
              // The user’s photo.
              photo: ( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                }
              ) | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_trafficGraph_BlockType",
          // The ID of the entity
          id: string | null,
          dataset:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
              interval: string | null,
              peakIn: string | null,
              peakOut: string | null,
              averageIn: string | null,
              averageOut: string | null,
              currentIn: string | null,
              currentOut: string | null,
              averageInputData:  Array< {
                __typename: "averageInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "averageOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "peakOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "peakInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_applicationForm_BlockType",
          // The ID of the entity
          id: string | null,
          buttonText: string | null,
          applicationType: string | null,
        } | {
          __typename: "contentBlocks_registrationForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          description: string | null,
        }
      ) | null >,
      relatedItems:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "story_story_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          service:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "documentation_documentation_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "info_info_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface EventQueryVariables {
  exchange?: Array< string > | null,
  slug?: Array< string > | null,
};

export interface EventQuery {
  // This query is used to query for entries.
  entries:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
      description: string | null,
      location: string | null,
      startDate: string | null,
      endDate: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      contentBlocks:  Array<( {
          __typename: "contentBlocks_intro_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_text_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_textWithImage_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          textHTML: string | null,
          imageAlignment: string | null,
        } | {
          __typename: "contentBlocks_image_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
          textAlignment: string | null,
        } | {
          __typename: "contentBlocks_graphic_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_video_BlockType",
          videoUrl: string | null,
        } | {
          __typename: "contentBlocks_audio_BlockType",
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "contentBlocks_table_BlockType",
          heading: string | null,
          table: string | null,
        } | {
          __typename: "contentBlocks_codeSnippet_BlockType",
          programmingLanguage: string | null,
          snippet: string | null,
        } | {
          __typename: "contentBlocks_contactForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          documentUpload: boolean | null,
          description: string | null,
        } | {
          __typename: "contentBlocks_logos_BlockType",
          logos:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_button_BlockType",
          // The ID of the entity
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_sectionEnding_BlockType",
        } | {
          __typename: "contentBlocks_users_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          users:  Array<( {
              __typename: "User",
              // The user’s full name.
              fullName: string | null,
              functionTitle: string | null,
              // The user’s photo.
              photo: ( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                }
              ) | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_trafficGraph_BlockType",
          // The ID of the entity
          id: string | null,
          dataset:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
              interval: string | null,
              peakIn: string | null,
              peakOut: string | null,
              averageIn: string | null,
              averageOut: string | null,
              currentIn: string | null,
              currentOut: string | null,
              averageInputData:  Array< {
                __typename: "averageInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "averageOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "peakOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "peakInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_applicationForm_BlockType",
          // The ID of the entity
          id: string | null,
          buttonText: string | null,
          applicationType: string | null,
        } | {
          __typename: "contentBlocks_registrationForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          description: string | null,
        }
      ) | null >,
      relatedItems:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "story_story_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          service:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "documentation_documentation_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "info_info_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
};

export interface MenuQueryVariables {
  site?: Array< string > | null,
};

export interface MenuQuery {
  // This query is used to query for entries.
  menuItems:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s title.
      title: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      externalLink: string | null,
      // The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query.
      ancestors:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) >,
      menuDropdownType: string | null,
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              // The ID of the entity
              id: string | null,
              // The element’s title.
              title: string | null,
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "news_news_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "city_city_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "partner_partner_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "search_search_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "service_service_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "story_story_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_partner_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_network_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_registration_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_internal_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_document_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "country_country_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "footerLink_footerLink_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "graph_graph_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "network_network_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "getStarted_getStarted_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "event_event_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "documentation_documentation_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "info_info_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "menu_menu_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "newsletter_newsletter_Entry",
                  // The element’s URI.
                  uri: string | null,
                }
              ) | null >,
              externalLink: string | null,
              // The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query.
              ancestors:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "news_news_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "city_city_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "partner_partner_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "search_search_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "service_service_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "story_story_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_partner_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_network_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_registration_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_internal_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_document_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "country_country_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "footerLink_footerLink_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "graph_graph_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "network_network_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "getStarted_getStarted_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "event_event_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "documentation_documentation_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "info_info_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "menu_menu_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "newsletter_newsletter_Entry",
                  // The element’s title.
                  title: string | null,
                }
              ) >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
  menuConfig:  {
    __typename: "MenuConfig",
    scheme: MenuScheme | null,
  },
  // This query is used to query for a single global set.
  globalSet: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
      loginUrl: string | null,
      mailChimpSubscriptionUrl: string | null,
      footerContactFormText: string | null,
    } | {
      __typename: "globalStats_GlobalSet",
    } | {
      __typename: "modalSettings_GlobalSet",
      showModal: boolean | null,
      modalTitle: string | null,
      modalText: string | null,
      modalEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "socialMedia_GlobalSet",
      facebookUrl: string | null,
      linkedinUrl: string | null,
      twitterUrl: string | null,
      youtubeUrl: string | null,
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for entries.
  footerLinks:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s title.
      title: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
};

export interface GetStartedQueryVariables {
  exchange?: Array< string > | null,
};

export interface GetStartedQuery {
  // This query is used to query for a single entry.
  entry: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
          description: string | null,
          contentBlocks:  Array<( {
              __typename: "contentBlocks_intro_BlockType",
              // The ID of the entity
              id: string | null,
              heading: string | null,
              textHTML: string | null,
            } | {
              __typename: "contentBlocks_text_BlockType",
              // The ID of the entity
              id: string | null,
              heading: string | null,
              textHTML: string | null,
            } | {
              __typename: "contentBlocks_textWithImage_BlockType",
              image:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
              heading: string | null,
              textHTML: string | null,
              imageAlignment: string | null,
            } | {
              __typename: "contentBlocks_image_BlockType",
              image:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  urlSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  urlMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  urlLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  urlSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  urlMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  urlLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
              heading: string | null,
              text: string | null,
              buttonText: string | null,
              buttonLink:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "news_news_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "city_city_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "partner_partner_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "search_search_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "service_service_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "story_story_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_partner_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_network_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_registration_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_internal_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_document_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "country_country_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "footerLink_footerLink_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "graph_graph_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "network_network_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "getStarted_getStarted_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "event_event_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "documentation_documentation_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "info_info_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "menu_menu_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "newsletter_newsletter_Entry",
                  // The element’s URI.
                  uri: string | null,
                }
              ) | null >,
              textAlignment: string | null,
            } | {
              __typename: "contentBlocks_graphic_BlockType",
              image:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcOriginal: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthOriginal: number | null,
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcOriginal: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthOriginal: number | null,
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
            } | {
              __typename: "contentBlocks_video_BlockType",
              videoUrl: string | null,
            } | {
              __typename: "contentBlocks_audio_BlockType",
              podcastUrl: string | null,
              podcastDescription: string | null,
            } | {
              __typename: "contentBlocks_table_BlockType",
              heading: string | null,
              table: string | null,
            } | {
              __typename: "contentBlocks_codeSnippet_BlockType",
              programmingLanguage: string | null,
              snippet: string | null,
            } | {
              __typename: "contentBlocks_contactForm_BlockType",
              // The ID of the entity
              id: string | null,
              ctaText: string | null,
              buttonText: string | null,
              compactDisplay: boolean | null,
              documentUpload: boolean | null,
              description: string | null,
            } | {
              __typename: "contentBlocks_logos_BlockType",
              logos:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  src: string | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  src2x: string | null,
                  // The element’s title.
                  title: string | null,
                  // The ID of the entity
                  id: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  src: string | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  src2x: string | null,
                  // The element’s title.
                  title: string | null,
                  // The ID of the entity
                  id: string | null,
                }
              ) | null >,
            } | {
              __typename: "contentBlocks_button_BlockType",
              // The ID of the entity
              id: string | null,
              label: string | null,
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "news_news_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "city_city_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "partner_partner_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "search_search_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "service_service_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "story_story_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_partner_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_network_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_registration_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_internal_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "contactRequest_document_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "country_country_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "footerLink_footerLink_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "graph_graph_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "network_network_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "getStarted_getStarted_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "event_event_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "documentation_documentation_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "info_info_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "menu_menu_Entry",
                  // The element’s URI.
                  uri: string | null,
                } | {
                  __typename: "newsletter_newsletter_Entry",
                  // The element’s URI.
                  uri: string | null,
                }
              ) | null >,
            } | {
              __typename: "contentBlocks_sectionEnding_BlockType",
            } | {
              __typename: "contentBlocks_users_BlockType",
              // The ID of the entity
              id: string | null,
              heading: string | null,
              users:  Array<( {
                  __typename: "User",
                  // The user’s full name.
                  fullName: string | null,
                  functionTitle: string | null,
                  // The user’s photo.
                  photo: ( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                    }
                  ) | null,
                }
              ) | null >,
            } | {
              __typename: "contentBlocks_trafficGraph_BlockType",
              // The ID of the entity
              id: string | null,
              dataset:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                  // The element’s title.
                  title: string | null,
                  interval: string | null,
                  peakIn: string | null,
                  peakOut: string | null,
                  averageIn: string | null,
                  averageOut: string | null,
                  currentIn: string | null,
                  currentOut: string | null,
                  averageInputData:  Array< {
                    __typename: "averageInputData_TableRow",
                    time: string | null,
                    value: string | null,
                  } | null > | null,
                  averageOutputData:  Array< {
                    __typename: "averageOutputData_TableRow",
                    time: string | null,
                    value: string | null,
                  } | null > | null,
                  peakOutputData:  Array< {
                    __typename: "peakOutputData_TableRow",
                    time: string | null,
                    value: string | null,
                  } | null > | null,
                  peakInputData:  Array< {
                    __typename: "peakInputData_TableRow",
                    time: string | null,
                    value: string | null,
                  } | null > | null,
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "contentBlocks_applicationForm_BlockType",
              // The ID of the entity
              id: string | null,
              buttonText: string | null,
              applicationType: string | null,
            } | {
              __typename: "contentBlocks_registrationForm_BlockType",
              // The ID of the entity
              id: string | null,
              ctaText: string | null,
              buttonText: string | null,
              compactDisplay: boolean | null,
              description: string | null,
            }
          ) | null >,
          relatedItems:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
              // The ID of the entity
              id: string | null,
              // The element’s URI.
              uri: string | null,
              // The element’s title.
              title: string | null,
              // The entry’s post date.
              postDate: string | null,
              heroImage:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
              // The ID of the entity
              id: string | null,
              // The element’s URI.
              uri: string | null,
              // The element’s title.
              title: string | null,
              // The entry’s post date.
              postDate: string | null,
              relatedItemImage:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
            } | {
              __typename: "story_story_Entry",
              // The ID of the entity
              id: string | null,
              // The element’s URI.
              uri: string | null,
              // The element’s title.
              title: string | null,
              // The entry’s post date.
              postDate: string | null,
              heroImage:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
              service:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "news_news_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "city_city_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "partner_partner_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "search_search_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "service_service_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "story_story_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_partner_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_network_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_registration_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_internal_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "contactRequest_document_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "country_country_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "footerLink_footerLink_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "graph_graph_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "network_network_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "getStarted_getStarted_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "event_event_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "documentation_documentation_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "info_info_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "menu_menu_Entry",
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "newsletter_newsletter_Entry",
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
              // The ID of the entity
              id: string | null,
              // The element’s URI.
              uri: string | null,
              // The element’s title.
              title: string | null,
              // The entry’s post date.
              postDate: string | null,
              heroImage:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
            } | {
              __typename: "documentation_documentation_Entry",
              // The ID of the entity
              id: string | null,
              // The element’s URI.
              uri: string | null,
              // The element’s title.
              title: string | null,
              // The entry’s post date.
              postDate: string | null,
              relatedItemImage:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
            } | {
              __typename: "info_info_Entry",
              // The ID of the entity
              id: string | null,
              // The element’s URI.
              uri: string | null,
              // The element’s title.
              title: string | null,
              // The entry’s post date.
              postDate: string | null,
              relatedItemImage:  Array<( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcSmall: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthSmall: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcMedium: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthMedium: number | null,
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  srcLarge: string | null,
                  // The width in pixels or null if it’s not an image.
                  widthLarge: number | null,
                  // The element’s title.
                  title: string | null,
                }
              ) | null >,
            } | {
              __typename: "menu_menu_Entry",
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) | null >,
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface HomeQueryVariables {
  site?: Array< string > | null,
};

export interface HomeQuery {
  // This query is used to query for entries.
  exchangePage:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      contactFormText: string | null,
      contactBlockCtaText: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
};

export interface InfoQueryVariables {
  exchange?: Array< string > | null,
  slug?: Array< string > | null,
};

export interface InfoQuery {
  // This query is used to query for a single entry.
  entry: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
      // The element’s title.
      title: string | null,
      description: string | null,
      contentBlocks:  Array<( {
          __typename: "contentBlocks_intro_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_text_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_textWithImage_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          textHTML: string | null,
          imageAlignment: string | null,
        } | {
          __typename: "contentBlocks_image_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
          textAlignment: string | null,
        } | {
          __typename: "contentBlocks_graphic_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_video_BlockType",
          videoUrl: string | null,
        } | {
          __typename: "contentBlocks_audio_BlockType",
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "contentBlocks_table_BlockType",
          heading: string | null,
          table: string | null,
        } | {
          __typename: "contentBlocks_codeSnippet_BlockType",
          programmingLanguage: string | null,
          snippet: string | null,
        } | {
          __typename: "contentBlocks_contactForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          documentUpload: boolean | null,
          description: string | null,
        } | {
          __typename: "contentBlocks_logos_BlockType",
          logos:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_button_BlockType",
          // The ID of the entity
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_sectionEnding_BlockType",
        } | {
          __typename: "contentBlocks_users_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          users:  Array<( {
              __typename: "User",
              // The user’s full name.
              fullName: string | null,
              functionTitle: string | null,
              // The user’s photo.
              photo: ( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                }
              ) | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_trafficGraph_BlockType",
          // The ID of the entity
          id: string | null,
          dataset:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
              interval: string | null,
              peakIn: string | null,
              peakOut: string | null,
              averageIn: string | null,
              averageOut: string | null,
              currentIn: string | null,
              currentOut: string | null,
              averageInputData:  Array< {
                __typename: "averageInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "averageOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "peakOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "peakInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_applicationForm_BlockType",
          // The ID of the entity
          id: string | null,
          buttonText: string | null,
          applicationType: string | null,
        } | {
          __typename: "contentBlocks_registrationForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          description: string | null,
        }
      ) | null >,
      relatedItems:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "story_story_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          service:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "documentation_documentation_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "info_info_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface SingleNewsPageQueryVariables {
  exchange?: Array< string > | null,
  slug?: Array< string > | null,
};

export interface SingleNewsPageQuery {
  // This query is used to query for a single entry.
  entry: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The element’s title.
      title: string | null,
      description: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s author.
      author: ( {
          __typename: "User",
          // The user’s full name or username.
          name: string,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
              // The element’s title.
              title: string | null,
            }
          ) | null,
        }
      ) | null,
      contentBlocks:  Array<( {
          __typename: "contentBlocks_intro_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_text_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_textWithImage_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          textHTML: string | null,
          imageAlignment: string | null,
        } | {
          __typename: "contentBlocks_image_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
          textAlignment: string | null,
        } | {
          __typename: "contentBlocks_graphic_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_video_BlockType",
          videoUrl: string | null,
        } | {
          __typename: "contentBlocks_audio_BlockType",
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "contentBlocks_table_BlockType",
          heading: string | null,
          table: string | null,
        } | {
          __typename: "contentBlocks_codeSnippet_BlockType",
          programmingLanguage: string | null,
          snippet: string | null,
        } | {
          __typename: "contentBlocks_contactForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          documentUpload: boolean | null,
          description: string | null,
        } | {
          __typename: "contentBlocks_logos_BlockType",
          logos:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_button_BlockType",
          // The ID of the entity
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_sectionEnding_BlockType",
        } | {
          __typename: "contentBlocks_users_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          users:  Array<( {
              __typename: "User",
              // The user’s full name.
              fullName: string | null,
              functionTitle: string | null,
              // The user’s photo.
              photo: ( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                }
              ) | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_trafficGraph_BlockType",
          // The ID of the entity
          id: string | null,
          dataset:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
              interval: string | null,
              peakIn: string | null,
              peakOut: string | null,
              averageIn: string | null,
              averageOut: string | null,
              currentIn: string | null,
              currentOut: string | null,
              averageInputData:  Array< {
                __typename: "averageInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "averageOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "peakOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "peakInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_applicationForm_BlockType",
          // The ID of the entity
          id: string | null,
          buttonText: string | null,
          applicationType: string | null,
        } | {
          __typename: "contentBlocks_registrationForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          description: string | null,
        }
      ) | null >,
      relatedItems:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "story_story_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          service:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "documentation_documentation_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "info_info_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface PartnersQueryVariables {
  exchange?: Array< string > | null,
  country?: Array< string | null > | null,
  city?: Array< string | null > | null,
  search?: string | null,
  partnerType?: Array< string | null > | null,
};

export interface PartnersQuery {
  // This query is used to query for a single entry.
  partnerPage: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s title.
      title: string | null,
      introHeading: string | null,
      introText: string | null,
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
  // This query is used to query for entries.
  partnersTable:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s title.
      title: string | null,
      datacenter: string | null,
      city: string | null,
      country: string | null,
      partnerType: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
  // This query is used to query for entries.
  countries:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
  // This query is used to query for entries.
  cities:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
};

export interface SearchQueryVariables {
  site?: Array< string > | null,
  offset?: number | null,
  itemLimit?: number | null,
  query?: string | null,
};

export interface SearchQuery {
  // This query is used to query for entries.
  items:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s post date.
      date: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s post date.
      date: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      startDate: string | null,
      endDate: string | null,
      // The entry’s post date.
      date: string | null,
      listInPastEvents: boolean | null,
      location: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s title.
      title: string | null,
    }
  ) | null > | null,
  // This query is used to query for a single entry.
  page: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
      introHeading: string | null,
      introText: string | null,
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface ServiceQueryVariables {
  exchange?: Array< string > | null,
  slug?: Array< string > | null,
};

export interface ServiceQuery {
  // This query is used to query for a single entry.
  service: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s title.
      title: string | null,
      howItWorks: string | null,
      description: string | null,
      pricing: string | null,
      showDurationSwitch: boolean | null,
      showContactPricing: boolean | null,
      commitmentModule:  Array<( {
          __typename: "commitmentModule_speedBlock_BlockType",
          blockTitle: string | null,
          price3Months: string | null,
          price12Months: string | null,
          price24Months: string | null,
          price36Months: string | null,
          checkmarkList:  Array< {
            __typename: "checkmarkList_TableRow",
            listItem: string | null,
          } | null > | null,
        }
      ) | null >,
      icon:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      benefits:  Array<( {
          __typename: "benefits_benefit_BlockType",
          icon:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null >,
          heading: string | null,
          description: string | null,
        }
      ) | null >,
      storyShowcase:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
          description: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
      contactBlockCtaText: string | null,
      contactFormText: string | null,
      relatedItems:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "story_story_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          service:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "documentation_documentation_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "info_info_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface StoryQueryVariables {
  exchange?: Array< string > | null,
  slug?: Array< string > | null,
};

export interface StoryQuery {
  // This query is used to query for a single entry.
  entry: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
      // The element’s title.
      title: string | null,
      description: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s author.
      author: ( {
          __typename: "User",
          // The user’s full name or username.
          name: string,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
              // The element’s title.
              title: string | null,
            }
          ) | null,
        }
      ) | null,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      contentBlocks:  Array<( {
          __typename: "contentBlocks_intro_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_text_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_textWithImage_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          textHTML: string | null,
          imageAlignment: string | null,
        } | {
          __typename: "contentBlocks_image_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
          textAlignment: string | null,
        } | {
          __typename: "contentBlocks_graphic_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_video_BlockType",
          videoUrl: string | null,
        } | {
          __typename: "contentBlocks_audio_BlockType",
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "contentBlocks_table_BlockType",
          heading: string | null,
          table: string | null,
        } | {
          __typename: "contentBlocks_codeSnippet_BlockType",
          programmingLanguage: string | null,
          snippet: string | null,
        } | {
          __typename: "contentBlocks_contactForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          documentUpload: boolean | null,
          description: string | null,
        } | {
          __typename: "contentBlocks_logos_BlockType",
          logos:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_button_BlockType",
          // The ID of the entity
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_sectionEnding_BlockType",
        } | {
          __typename: "contentBlocks_users_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          users:  Array<( {
              __typename: "User",
              // The user’s full name.
              fullName: string | null,
              functionTitle: string | null,
              // The user’s photo.
              photo: ( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                }
              ) | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_trafficGraph_BlockType",
          // The ID of the entity
          id: string | null,
          dataset:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
              interval: string | null,
              peakIn: string | null,
              peakOut: string | null,
              averageIn: string | null,
              averageOut: string | null,
              currentIn: string | null,
              currentOut: string | null,
              averageInputData:  Array< {
                __typename: "averageInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "averageOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "peakOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "peakInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_applicationForm_BlockType",
          // The ID of the entity
          id: string | null,
          buttonText: string | null,
          applicationType: string | null,
        } | {
          __typename: "contentBlocks_registrationForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          description: string | null,
        }
      ) | null >,
      relatedItems:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "story_story_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          service:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "documentation_documentation_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "info_info_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface sendContactRequestMutationVariables {
  siteId?: number | null,
  subject?: string | null,
  comment?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  phoneNumber?: string | null,
  contactFormId?: string | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
  authorId: string,
};

export interface sendContactRequestMutation {
  // Save a “Internal” entry in the “Contact Request” section.
  save_contactRequest_internal_Entry:  {
    __typename: "contactRequest_internal_Entry",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestDocumentMutationVariables {
  siteId?: number | null,
  comment?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  phoneNumber?: string | null,
  contactFormId?: string | null,
  recaptcha?: string | null,
  cv?: Array< number | null > | null,
  siteLocation?: string | null,
  authorId: string,
};

export interface sendContactRequestDocumentMutation {
  // Save a “Document” entry in the “Contact Request” section.
  save_contactRequest_document_Entry:  {
    __typename: "contactRequest_document_Entry",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestToPartnerMutationVariables {
  siteId?: number | null,
  comment?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  phoneNumber?: string | null,
  partner?: Array< number | null > | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
  authorId: string,
};

export interface sendContactRequestToPartnerMutation {
  // Save a “Partner” entry in the “Contact Request” section.
  save_contactRequest_partner_Entry:  {
    __typename: "contactRequest_partner_Entry",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestToNetworkMutationVariables {
  siteId?: number | null,
  comment?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  phoneNumber?: string | null,
  connectedNetwork?: Array< number | null > | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
  authorId: string,
};

export interface sendContactRequestToNetworkMutation {
  // Save a “Network” entry in the “Contact Request” section.
  save_contactRequest_network_Entry:  {
    __typename: "contactRequest_network_Entry",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestForWishlistMutationVariables {
  siteId?: number | null,
  subject?: string | null,
  emailAddress?: string | null,
  fullName?: string | null,
  company?: string | null,
  contactFormId?: string | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
  requestedAs?: string | null,
  yourAsNumber?: string | null,
  authorId: string,
};

export interface sendContactRequestForWishlistMutation {
  // Save a “Wishlist” entry in the “Contact Request” section.
  save_contactRequest_wishlist_Entry:  {
    __typename: "contactRequest_wishlist_Entry",
    mailSent: boolean | null,
  } | null,
};

export interface sendContactRequestForRegistrationMutationVariables {
  siteId?: number | null,
  firstName?: string | null,
  lastName?: string | null,
  organization?: string | null,
  jobTitle?: string | null,
  workEmail?: string | null,
  contactFormId?: string | null,
  recaptcha?: string | null,
  siteLocation?: string | null,
  authorId: string,
};

export interface sendContactRequestForRegistrationMutation {
  // Save a “Registration” entry in the “Contact Request” section.
  save_contactRequest_registration_Entry:  {
    __typename: "contactRequest_registration_Entry",
    mailSent: boolean | null,
  } | null,
};

export interface MenuStatsQueryVariables {
  site?: Array< string > | null,
};

export interface MenuStatsQuery {
  // This query is used to query for entries.
  stats:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
      averageInputData:  Array< {
        __typename: "averageInputData_TableRow",
        time: string | null,
        value: string | null,
      } | null > | null,
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
  // This query is used to query for a single global set.
  vitals: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      currentTraffic: string | null,
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
};

export interface GlossaryQueryQuery {
  // This query is used to query for a single global set.
  glossary: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
      glossaryTable:  Array< {
        __typename: "glossaryTable_TableRow",
        word: string | null,
        meaning: string | null,
      } | null > | null,
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
};

export interface DropDownStatsQueryVariables {
  site?: Array< string > | null,
};

export interface DropDownStatsQuery {
  // This query is used to query for entries.
  dropdownStats:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
      // The element’s title.
      title: string | null,
      interval: string | null,
      peakIn: string | null,
      peakOut: string | null,
      averageIn: string | null,
      averageOut: string | null,
      currentIn: string | null,
      currentOut: string | null,
      averageInputData:  Array< {
        __typename: "averageInputData_TableRow",
        time: string | null,
        value: string | null,
      } | null > | null,
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
  // This query is used to query for a single global set.
  vitals: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
};

export interface PinpointCardStatsQueryVariables {
  ams: string,
  bay: string,
  car: string,
  chi: string,
  hk: string,
  mum: string,
  kol: string,
  sin: string,
  hyd: string,
  lag: string,
  ban: string,
  dji: string,
};

export interface PinpointCardStatsQuery {
  // This query is used to query for a single global set.
  vitalsAms: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsBay: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsCar: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsChi: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsHK: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsMum: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsKol: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsSin: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsHyd: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsLag: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsBan: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitalsDji: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      peakTraffic: string | null,
      connectedNetworks: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
};

export interface StatsQueryVariables {
  site?: Array< string > | null,
};

export interface StatsQuery {
  // This query is used to query for a single entry.
  exchangePage: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      numbersCtaText: string | null,
      hideColocations: boolean | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
  // This query is used to query for a single global set.
  vitals: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
      connectedNetworks: string | null,
      peakTraffic: string | null,
      colocations: string | null,
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single entry.
  whereToConnectPage: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface ConnectedNetworkQueryVariables {
  exchange?: Array< string > | null,
  id?: Array< string | null > | null,
};

export interface ConnectedNetworkQuery {
  // This query is used to query for a single entry.
  connectedNetwork: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
      connections:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          IPv4: string | null,
          IPv6: string | null,
          Speed: string | null,
          Location: string | null,
          VLAN: string | null,
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface ContactSlideoverQueryVariables {
  site?: Array< string > | null,
};

export interface ContactSlideoverQuery {
  // This query is used to query for a single global set.
  exchangeConfig: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
      address: string | null,
      contactInfo: string | null,
    } | {
      __typename: "globalStats_GlobalSet",
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  socialMedia: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
      facebookUrl: string | null,
      linkedinUrl: string | null,
      twitterUrl: string | null,
      youtubeUrl: string | null,
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  email: ( {
      __typename: "email_GlobalSet",
      registrationEmailAdditionalSubject: string | null,
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
};

export interface EventsQueryVariables {
  listExpiryDate: Array< string | null >,
  site?: Array< string > | null,
};

export interface EventsQuery {
  // This query is used to query for entries.
  events:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
      location: string | null,
      startDate: string | null,
      endDate: string | null,
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
  // This query is used to query for entries.
  next:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
      location: string | null,
      startDate: string | null,
      endDate: string | null,
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
  // This query is used to query for entries.
  exchangePage:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      featuredEvent:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
          location: string | null,
          startDate: string | null,
          endDate: string | null,
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
};

export interface HeroQueryVariables {
  site?: Array< string > | null,
};

export interface HeroQuery {
  // This query is used to query for entries.
  exchangePage:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
};

export interface NewsQueryVariables {
  site?: Array< string > | null,
};

export interface NewsQuery {
  // This query is used to query for entries.
  news:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The width in pixels or null if it’s not an image.
          width: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The width in pixels or null if it’s not an image.
          width2x: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s post date.
      postDate: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null > | null,
};

export interface FeaturedStoriesQueryVariables {
  site?: Array< string > | null,
};

export interface FeaturedStoriesQuery {
  // This query is used to query for a single entry.
  exchangePage: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      featuredStories:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s title.
          title: string | null,
          // The element’s URI.
          uri: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          description: string | null,
          service:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          // The entry’s post date.
          postDate: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface ServiceHowItWorksQueryVariables {
  exchange?: Array< string > | null,
  slug?: Array< string > | null,
};

export interface ServiceHowItWorksQuery {
  // This query is used to query for a single entry.
  service: ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      contentBlocks:  Array<( {
          __typename: "contentBlocks_intro_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_text_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_textWithImage_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          textHTML: string | null,
          imageAlignment: string | null,
        } | {
          __typename: "contentBlocks_image_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
          textAlignment: string | null,
        } | {
          __typename: "contentBlocks_graphic_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_video_BlockType",
          videoUrl: string | null,
        } | {
          __typename: "contentBlocks_audio_BlockType",
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "contentBlocks_table_BlockType",
          heading: string | null,
          table: string | null,
        } | {
          __typename: "contentBlocks_codeSnippet_BlockType",
          programmingLanguage: string | null,
          snippet: string | null,
        } | {
          __typename: "contentBlocks_contactForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          documentUpload: boolean | null,
          description: string | null,
        } | {
          __typename: "contentBlocks_logos_BlockType",
          logos:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_button_BlockType",
          // The ID of the entity
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_sectionEnding_BlockType",
        } | {
          __typename: "contentBlocks_users_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          users:  Array<( {
              __typename: "User",
              // The user’s full name.
              fullName: string | null,
              functionTitle: string | null,
              // The user’s photo.
              photo: ( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                }
              ) | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_trafficGraph_BlockType",
          // The ID of the entity
          id: string | null,
          dataset:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
              interval: string | null,
              peakIn: string | null,
              peakOut: string | null,
              averageIn: string | null,
              averageOut: string | null,
              currentIn: string | null,
              currentOut: string | null,
              averageInputData:  Array< {
                __typename: "averageInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "averageOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "peakOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "peakInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_applicationForm_BlockType",
          // The ID of the entity
          id: string | null,
          buttonText: string | null,
          applicationType: string | null,
        } | {
          __typename: "contentBlocks_registrationForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          description: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null,
};

export interface ServicesQueryVariables {
  site?: Array< string > | null,
};

export interface ServicesQuery {
  // This query is used to query for a single entry.
  services: ( {
      __typename: "storyCollection_storyCollection_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "news_news_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "city_city_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "partner_partner_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "search_search_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "service_service_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "story_story_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "contactRequest_network_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "contactRequest_document_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "country_country_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "graph_graph_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "network_network_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "event_event_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "documentation_documentation_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "info_info_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "menu_menu_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
          children:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
              linkEntry:  Array<( {
                  __typename: "storyCollection_storyCollection_Entry",
                } | {
                  __typename: "networkConnection_networkConnection_Entry",
                } | {
                  __typename: "news_news_Entry",
                } | {
                  __typename: "newsCollection_newsCollection_Entry",
                } | {
                  __typename: "city_city_Entry",
                } | {
                  __typename: "partner_partner_Entry",
                } | {
                  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
                } | {
                  __typename: "partnerCollection_partnerCollection_Entry",
                } | {
                  __typename: "search_search_Entry",
                } | {
                  __typename: "service_service_Entry",
                  // The ID of the entity
                  id: string | null,
                  // The element’s title.
                  title: string | null,
                  description: string | null,
                  // The element’s URI.
                  uri: string | null,
                  // The element’s slug.
                  slug: string | null,
                  icon:  Array<( {
                      __typename: "images_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    } | {
                      __typename: "uploadAssets_Asset",
                      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                      url: string | null,
                      // The element’s title.
                      title: string | null,
                    }
                  ) | null >,
                } | {
                  __typename: "story_story_Entry",
                } | {
                  __typename: "contactRequest_partner_Entry",
                } | {
                  __typename: "contactRequest_network_Entry",
                } | {
                  __typename: "contactRequest_wishlist_Entry",
                } | {
                  __typename: "contactRequest_registration_Entry",
                } | {
                  __typename: "contactRequest_internal_Entry",
                } | {
                  __typename: "contactRequest_document_Entry",
                } | {
                  __typename: "country_country_Entry",
                } | {
                  __typename: "eventCollection_eventCollection_Entry",
                } | {
                  __typename: "exchangePage_exchangePage_Entry",
                } | {
                  __typename: "footerLink_footerLink_Entry",
                } | {
                  __typename: "graph_graph_Entry",
                } | {
                  __typename: "network_network_Entry",
                } | {
                  __typename: "newsletterCollection_newsletterCollection_Entry",
                } | {
                  __typename: "getStarted_getStarted_Entry",
                } | {
                  __typename: "event_event_Entry",
                } | {
                  __typename: "documentation_documentation_Entry",
                } | {
                  __typename: "info_info_Entry",
                } | {
                  __typename: "menu_menu_Entry",
                } | {
                  __typename: "newsletter_newsletter_Entry",
                }
              ) | null >,
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    }
  ) | null,
};

export interface WishlistSlideOverQueryVariables {
  site?: Array< string > | null,
};

export interface WishlistSlideOverQuery {
  // This query is used to query for a single global set.
  exchangeConfig: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
      address: string | null,
      contactInfo: string | null,
    } | {
      __typename: "globalStats_GlobalSet",
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
  // This query is used to query for a single global set.
  socialMedia: ( {
      __typename: "email_GlobalSet",
    } | {
      __typename: "exchangeConfig_GlobalSet",
    } | {
      __typename: "globalStats_GlobalSet",
    } | {
      __typename: "modalSettings_GlobalSet",
    } | {
      __typename: "socialMedia_GlobalSet",
      facebookUrl: string | null,
      linkedinUrl: string | null,
      twitterUrl: string | null,
      youtubeUrl: string | null,
    } | {
      __typename: "glossary_GlobalSet",
    } | {
      __typename: "commitmentModule_GlobalSet",
    } | {
      __typename: "smartSnippets_GlobalSet",
    }
  ) | null,
};

export interface setSchemeMutationVariables {
  theme?: MenuScheme | null,
};

export interface setSchemeMutation {
  setScheme:  {
    __typename: "MenuConfig",
    scheme: MenuScheme | null,
  } | null,
};

export interface EventCollectionPageFragment {
  __typename: "eventCollection_eventCollection_Entry",
  introHeading: string | null,
  introText: string | null,
};

export interface NewsCollectionPageFragment {
  __typename: "newsCollection_newsCollection_Entry",
  introHeading: string | null,
  introText: string | null,
};

export interface NewsletterCollectionPageFragment {
  __typename: "newsletterCollection_newsletterCollection_Entry",
  introHeading: string | null,
  introText: string | null,
};

export interface StoriesCollectionPageFragment {
  __typename: "storyCollection_storyCollection_Entry",
  introHeading: string | null,
  introText: string | null,
  featuredStories:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s post date.
      postDate: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface ConnectedNetworksStatsFragment {
  __typename: "globalStats_GlobalSet",
  routeserverPeers: string | null,
  connectedNetworks: string | null,
  customerPorts: string | null,
  ipv6Peers: string | null,
};

export interface ConnectedNetworkInfoFragment {
  __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
  // The element’s title.
  title: string | null,
  introHeading: string | null,
  introText: string | null,
  exportLinks:  Array< {
    __typename: "exportLinks_TableRow",
    title: string | null,
    url: string | null,
  } | null > | null,
};

export interface ConnectedNetworkkRowFragment {
  __typename: "network_network_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s title.
  title: string | null,
  memberUrl: string | null,
  asNumber: string | null,
  peeringPolicy: string | null,
  routeserver: boolean | null,
  vlan: string | null,
  colocation: string | null,
};

export interface DocumentationFragment {
  __typename: "documentation_documentation_Entry",
  // The element’s title.
  title: string | null,
  description: string | null,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
  relatedItems:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface EventFragment {
  __typename: "event_event_Entry",
  // The element’s title.
  title: string | null,
  description: string | null,
  location: string | null,
  startDate: string | null,
  endDate: string | null,
  // The entry’s post date.
  postDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
  relatedItems:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface SingleEventFragment {
  __typename: "event_event_Entry",
  // The element’s title.
  title: string | null,
  description: string | null,
  location: string | null,
  startDate: string | null,
  endDate: string | null,
  // The entry’s post date.
  postDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
};

export interface FooterSocialsFragment {
  __typename: "socialMedia_GlobalSet",
  facebookUrl: string | null,
  linkedinUrl: string | null,
  twitterUrl: string | null,
  youtubeUrl: string | null,
};

export interface GetStartedFragment {
  __typename: "getStarted_getStarted_Entry",
  linkEntry:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s title.
      title: string | null,
      description: string | null,
      contentBlocks:  Array<( {
          __typename: "contentBlocks_intro_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_text_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          textHTML: string | null,
        } | {
          __typename: "contentBlocks_textWithImage_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          textHTML: string | null,
          imageAlignment: string | null,
        } | {
          __typename: "contentBlocks_image_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              urlLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          heading: string | null,
          text: string | null,
          buttonText: string | null,
          buttonLink:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
          textAlignment: string | null,
        } | {
          __typename: "contentBlocks_graphic_BlockType",
          image:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcOriginal: string | null,
              // The width in pixels or null if it’s not an image.
              widthOriginal: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_video_BlockType",
          videoUrl: string | null,
        } | {
          __typename: "contentBlocks_audio_BlockType",
          podcastUrl: string | null,
          podcastDescription: string | null,
        } | {
          __typename: "contentBlocks_table_BlockType",
          heading: string | null,
          table: string | null,
        } | {
          __typename: "contentBlocks_codeSnippet_BlockType",
          programmingLanguage: string | null,
          snippet: string | null,
        } | {
          __typename: "contentBlocks_contactForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          documentUpload: boolean | null,
          description: string | null,
        } | {
          __typename: "contentBlocks_logos_BlockType",
          logos:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src: string | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              src2x: string | null,
              // The element’s title.
              title: string | null,
              // The ID of the entity
              id: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_button_BlockType",
          // The ID of the entity
          id: string | null,
          label: string | null,
          linkEntry:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_sectionEnding_BlockType",
        } | {
          __typename: "contentBlocks_users_BlockType",
          // The ID of the entity
          id: string | null,
          heading: string | null,
          users:  Array<( {
              __typename: "User",
              // The user’s full name.
              fullName: string | null,
              functionTitle: string | null,
              // The user’s photo.
              photo: ( {
                  __typename: "images_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                } | {
                  __typename: "uploadAssets_Asset",
                  // The full URL of the asset. This field accepts the same fields as the `transform` directive.
                  url: string | null,
                }
              ) | null,
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_trafficGraph_BlockType",
          // The ID of the entity
          id: string | null,
          dataset:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
            } | {
              __typename: "networkConnection_networkConnection_Entry",
            } | {
              __typename: "news_news_Entry",
            } | {
              __typename: "newsCollection_newsCollection_Entry",
            } | {
              __typename: "city_city_Entry",
            } | {
              __typename: "partner_partner_Entry",
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
            } | {
              __typename: "search_search_Entry",
            } | {
              __typename: "service_service_Entry",
            } | {
              __typename: "story_story_Entry",
            } | {
              __typename: "contactRequest_partner_Entry",
            } | {
              __typename: "contactRequest_network_Entry",
            } | {
              __typename: "contactRequest_wishlist_Entry",
            } | {
              __typename: "contactRequest_registration_Entry",
            } | {
              __typename: "contactRequest_internal_Entry",
            } | {
              __typename: "contactRequest_document_Entry",
            } | {
              __typename: "country_country_Entry",
            } | {
              __typename: "eventCollection_eventCollection_Entry",
            } | {
              __typename: "exchangePage_exchangePage_Entry",
            } | {
              __typename: "footerLink_footerLink_Entry",
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
              interval: string | null,
              peakIn: string | null,
              peakOut: string | null,
              averageIn: string | null,
              averageOut: string | null,
              currentIn: string | null,
              currentOut: string | null,
              averageInputData:  Array< {
                __typename: "averageInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              averageOutputData:  Array< {
                __typename: "averageOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakOutputData:  Array< {
                __typename: "peakOutputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
              peakInputData:  Array< {
                __typename: "peakInputData_TableRow",
                time: string | null,
                value: string | null,
              } | null > | null,
            } | {
              __typename: "network_network_Entry",
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
            } | {
              __typename: "getStarted_getStarted_Entry",
            } | {
              __typename: "event_event_Entry",
            } | {
              __typename: "documentation_documentation_Entry",
            } | {
              __typename: "info_info_Entry",
            } | {
              __typename: "menu_menu_Entry",
            } | {
              __typename: "newsletter_newsletter_Entry",
            }
          ) | null >,
        } | {
          __typename: "contentBlocks_applicationForm_BlockType",
          // The ID of the entity
          id: string | null,
          buttonText: string | null,
          applicationType: string | null,
        } | {
          __typename: "contentBlocks_registrationForm_BlockType",
          // The ID of the entity
          id: string | null,
          ctaText: string | null,
          buttonText: string | null,
          compactDisplay: boolean | null,
          description: string | null,
        }
      ) | null >,
      relatedItems:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "story_story_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
          service:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          heroImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "documentation_documentation_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "info_info_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s title.
          title: string | null,
          // The entry’s post date.
          postDate: string | null,
          relatedItemImage:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcSmall: string | null,
              // The width in pixels or null if it’s not an image.
              widthSmall: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcMedium: string | null,
              // The width in pixels or null if it’s not an image.
              widthMedium: number | null,
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              srcLarge: string | null,
              // The width in pixels or null if it’s not an image.
              widthLarge: number | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface GetStartedPageFragment {
  __typename: "documentation_documentation_Entry",
  // The element’s title.
  title: string | null,
  description: string | null,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
  relatedItems:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface InfoFragment {
  __typename: "info_info_Entry",
  // The element’s title.
  title: string | null,
  description: string | null,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
  relatedItems:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface SingleNewsPageFragment {
  __typename: "news_news_Entry",
  // The element’s title.
  title: string | null,
  description: string | null,
  // The entry’s post date.
  postDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s author.
  author: ( {
      __typename: "User",
      // The user’s full name or username.
      name: string,
      functionTitle: string | null,
      // The user’s photo.
      photo: ( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        }
      ) | null,
    }
  ) | null,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
  relatedItems:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface SingleNewsFragment {
  __typename: "news_news_Entry",
  // The element’s title.
  title: string | null,
  description: string | null,
  // The entry’s post date.
  postDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s author.
  author: ( {
      __typename: "User",
      // The user’s full name or username.
      name: string,
      functionTitle: string | null,
      // The user’s photo.
      photo: ( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        }
      ) | null,
    }
  ) | null,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
};

export type PartnerInfoFragment = ( {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s title.
      title: string | null,
      introHeading: string | null,
      introText: string | null,
    }
  );

export interface PartnerTableRowFragment {
  __typename: "partner_partner_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s title.
  title: string | null,
  datacenter: string | null,
  city: string | null,
  country: string | null,
  partnerType: string | null,
};

export interface CountriesFragment {
  __typename: "country_country_Entry",
  // The element’s title.
  title: string | null,
};

export interface CitiesFragment {
  __typename: "city_city_Entry",
  // The element’s title.
  title: string | null,
};

export interface SearchPageFragment {
  __typename: "search_search_Entry",
  introHeading: string | null,
  introText: string | null,
};

export interface ServicePageFragment {
  __typename: "service_service_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s title.
  title: string | null,
  howItWorks: string | null,
  description: string | null,
  pricing: string | null,
  showDurationSwitch: boolean | null,
  showContactPricing: boolean | null,
  commitmentModule:  Array<( {
      __typename: "commitmentModule_speedBlock_BlockType",
      blockTitle: string | null,
      price3Months: string | null,
      price12Months: string | null,
      price24Months: string | null,
      price36Months: string | null,
      checkmarkList:  Array< {
        __typename: "checkmarkList_TableRow",
        listItem: string | null,
      } | null > | null,
    }
  ) | null >,
  icon:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  benefits:  Array<( {
      __typename: "benefits_benefit_BlockType",
      icon:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
        }
      ) | null >,
      heading: string | null,
      description: string | null,
    }
  ) | null >,
  storyShowcase:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
      // The element’s title.
      title: string | null,
      // The element’s URI.
      uri: string | null,
      description: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
  contactBlockCtaText: string | null,
  contactFormText: string | null,
  relatedItems:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface StoryFragment {
  __typename: "story_story_Entry",
  // The element’s title.
  title: string | null,
  description: string | null,
  // The entry’s post date.
  postDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s author.
  author: ( {
      __typename: "User",
      // The user’s full name or username.
      name: string,
      functionTitle: string | null,
      // The user’s photo.
      photo: ( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        }
      ) | null,
    }
  ) | null,
  service:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
  relatedItems:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface SingleStoryFragment {
  __typename: "story_story_Entry",
  // The element’s title.
  title: string | null,
  description: string | null,
  // The entry’s post date.
  postDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s author.
  author: ( {
      __typename: "User",
      // The user’s full name or username.
      name: string,
      functionTitle: string | null,
      // The user’s photo.
      photo: ( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        }
      ) | null,
    }
  ) | null,
  service:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
};

export interface ApplicationFormContentBlockFragment {
  __typename: "contentBlocks_applicationForm_BlockType",
  // The ID of the entity
  id: string | null,
  buttonText: string | null,
  applicationType: string | null,
};

export interface AudioContentBlockFragment {
  __typename: "contentBlocks_audio_BlockType",
  podcastUrl: string | null,
  podcastDescription: string | null,
};

export interface ButtonContentBlockFragment {
  __typename: "contentBlocks_button_BlockType",
  // The ID of the entity
  id: string | null,
  label: string | null,
  linkEntry:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s URI.
      uri: string | null,
    }
  ) | null >,
};

export interface CodeContentBlockFragment {
  __typename: "contentBlocks_codeSnippet_BlockType",
  programmingLanguage: string | null,
  snippet: string | null,
};

export interface ContactFormContentBlockFragment {
  __typename: "contentBlocks_contactForm_BlockType",
  // The ID of the entity
  id: string | null,
  ctaText: string | null,
  buttonText: string | null,
  compactDisplay: boolean | null,
  documentUpload: boolean | null,
  description: string | null,
};

export interface ContactInfoSocialsFragment {
  __typename: "socialMedia_GlobalSet",
  facebookUrl: string | null,
  linkedinUrl: string | null,
  twitterUrl: string | null,
  youtubeUrl: string | null,
};

export interface GraphicContentBlockFragment {
  __typename: "contentBlocks_graphic_BlockType",
  image:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcOriginal: string | null,
      // The width in pixels or null if it’s not an image.
      widthOriginal: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcOriginal: string | null,
      // The width in pixels or null if it’s not an image.
      widthOriginal: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface MenuStatsFragment {
  __typename: "graph_graph_Entry",
  averageInputData:  Array< {
    __typename: "averageInputData_TableRow",
    time: string | null,
    value: string | null,
  } | null > | null,
};

export interface ImageContentBlockFragment {
  __typename: "contentBlocks_image_BlockType",
  image:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      urlSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      urlMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      urlLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      urlSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      urlMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      urlLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  heading: string | null,
  text: string | null,
  buttonText: string | null,
  buttonLink:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s URI.
      uri: string | null,
    }
  ) | null >,
  textAlignment: string | null,
};

export interface IntroContentBlockFragment {
  __typename: "contentBlocks_intro_BlockType",
  // The ID of the entity
  id: string | null,
  heading: string | null,
  textHTML: string | null,
};

export interface LogosContentBlockFragment {
  __typename: "contentBlocks_logos_BlockType",
  logos:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The element’s title.
      title: string | null,
      // The ID of the entity
      id: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The element’s title.
      title: string | null,
      // The ID of the entity
      id: string | null,
    }
  ) | null >,
};

export interface RegistrationFormContentBlockFragment {
  __typename: "contentBlocks_registrationForm_BlockType",
  // The ID of the entity
  id: string | null,
  ctaText: string | null,
  buttonText: string | null,
  compactDisplay: boolean | null,
  description: string | null,
};

export interface ServicePageBenefitFragment {
  __typename: "benefits_benefit_BlockType",
  icon:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      url: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      url: string | null,
    }
  ) | null >,
  heading: string | null,
  description: string | null,
};

export interface ShowcaseStoryFragment {
  __typename: "story_story_Entry",
  // The element’s title.
  title: string | null,
  // The element’s URI.
  uri: string | null,
  description: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface TableContentBlockFragment {
  __typename: "contentBlocks_table_BlockType",
  heading: string | null,
  table: string | null,
};

export interface TextContentBlockFragment {
  __typename: "contentBlocks_text_BlockType",
  // The ID of the entity
  id: string | null,
  heading: string | null,
  textHTML: string | null,
};

export interface TextWithImageContentBlockFragment {
  __typename: "contentBlocks_textWithImage_BlockType",
  image:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  heading: string | null,
  textHTML: string | null,
  imageAlignment: string | null,
};

export interface TrafficGraphContentBlockFragment {
  __typename: "contentBlocks_trafficGraph_BlockType",
  // The ID of the entity
  id: string | null,
  dataset:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
      // The element’s title.
      title: string | null,
      interval: string | null,
      peakIn: string | null,
      peakOut: string | null,
      averageIn: string | null,
      averageOut: string | null,
      currentIn: string | null,
      currentOut: string | null,
      averageInputData:  Array< {
        __typename: "averageInputData_TableRow",
        time: string | null,
        value: string | null,
      } | null > | null,
      averageOutputData:  Array< {
        __typename: "averageOutputData_TableRow",
        time: string | null,
        value: string | null,
      } | null > | null,
      peakOutputData:  Array< {
        __typename: "peakOutputData_TableRow",
        time: string | null,
        value: string | null,
      } | null > | null,
      peakInputData:  Array< {
        __typename: "peakInputData_TableRow",
        time: string | null,
        value: string | null,
      } | null > | null,
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface DropdownStatsCompleteFragment {
  __typename: "graph_graph_Entry",
  // The element’s title.
  title: string | null,
  interval: string | null,
  peakIn: string | null,
  peakOut: string | null,
  averageIn: string | null,
  averageOut: string | null,
  currentIn: string | null,
  currentOut: string | null,
  averageInputData:  Array< {
    __typename: "averageInputData_TableRow",
    time: string | null,
    value: string | null,
  } | null > | null,
  averageOutputData:  Array< {
    __typename: "averageOutputData_TableRow",
    time: string | null,
    value: string | null,
  } | null > | null,
  peakOutputData:  Array< {
    __typename: "peakOutputData_TableRow",
    time: string | null,
    value: string | null,
  } | null > | null,
  peakInputData:  Array< {
    __typename: "peakInputData_TableRow",
    time: string | null,
    value: string | null,
  } | null > | null,
};

export interface UsersContentBlockFragment {
  __typename: "contentBlocks_users_BlockType",
  // The ID of the entity
  id: string | null,
  heading: string | null,
  users:  Array<( {
      __typename: "User",
      // The user’s full name.
      fullName: string | null,
      functionTitle: string | null,
      // The user’s photo.
      photo: ( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
        }
      ) | null,
    }
  ) | null >,
};

export interface VideoContentBlockFragment {
  __typename: "contentBlocks_video_BlockType",
  videoUrl: string | null,
};

export interface DropdownStatsFragment {
  __typename: "graph_graph_Entry",
  // The element’s title.
  title: string | null,
  interval: string | null,
  peakIn: string | null,
  peakOut: string | null,
  averageIn: string | null,
  averageOut: string | null,
  currentIn: string | null,
  currentOut: string | null,
  averageInputData:  Array< {
    __typename: "averageInputData_TableRow",
    time: string | null,
    value: string | null,
  } | null > | null,
};

export interface WhereToConnectLinkFragment {
  __typename: "menu_menu_Entry",
  linkEntry:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s URI.
      uri: string | null,
    }
  ) | null >,
};

export interface StatsFragment {
  __typename: "exchangePage_exchangePage_Entry",
  numbersCtaText: string | null,
  hideColocations: boolean | null,
};

export interface ConnectedNetworkDetailsFragment {
  __typename: "network_network_Entry",
  connections:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      IPv4: string | null,
      IPv6: string | null,
      Speed: string | null,
      Location: string | null,
      VLAN: string | null,
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface ConnectedNetworkConnectionFragment {
  __typename: "networkConnection_networkConnection_Entry",
  IPv4: string | null,
  IPv6: string | null,
  Speed: string | null,
  Location: string | null,
  VLAN: string | null,
};

export type ContentBlockFragment = ( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  );

export interface ExchangePageEventsFragment {
  __typename: "exchangePage_exchangePage_Entry",
  featuredEvent:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
      location: string | null,
      startDate: string | null,
      endDate: string | null,
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface EventSummaryFragment {
  __typename: "event_event_Entry",
  // The element’s title.
  title: string | null,
  location: string | null,
  startDate: string | null,
  endDate: string | null,
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
};

export interface FeaturedEventFragment {
  __typename: "event_event_Entry",
  // The element’s title.
  title: string | null,
  location: string | null,
  startDate: string | null,
  endDate: string | null,
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface FooterLinkFragment {
  __typename: "footerLink_footerLink_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s title.
  title: string | null,
  linkEntry:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s URI.
      uri: string | null,
    }
  ) | null >,
};

export interface MenuFragment {
  __typename: "menu_menu_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s title.
  title: string | null,
  linkEntry:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s URI.
      uri: string | null,
    }
  ) | null >,
  externalLink: string | null,
  // The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query.
  ancestors:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s title.
      title: string | null,
    }
  ) >,
  menuDropdownType: string | null,
  // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
  children:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
      // The element’s title.
      title: string | null,
      // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
      children:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s title.
          title: string | null,
          linkEntry:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s URI.
              uri: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s URI.
              uri: string | null,
            }
          ) | null >,
          externalLink: string | null,
          // The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query.
          ancestors:  Array<( {
              __typename: "storyCollection_storyCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "networkConnection_networkConnection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "news_news_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsCollection_newsCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "city_city_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partner_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "partnerCollection_partnerCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "search_search_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "service_service_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "story_story_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_partner_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_wishlist_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_registration_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_internal_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "contactRequest_document_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "country_country_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "eventCollection_eventCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "exchangePage_exchangePage_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "footerLink_footerLink_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "graph_graph_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "network_network_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletterCollection_newsletterCollection_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "getStarted_getStarted_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "event_event_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "documentation_documentation_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "info_info_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "menu_menu_Entry",
              // The element’s title.
              title: string | null,
            } | {
              __typename: "newsletter_newsletter_Entry",
              // The element’s title.
              title: string | null,
            }
          ) >,
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) >,
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) >,
};

export interface MenuColumnFragment {
  __typename: "menu_menu_Entry",
  // The element’s title.
  title: string | null,
  // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
  children:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s title.
      title: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      externalLink: string | null,
      // The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query.
      ancestors:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) >,
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) >,
};

export interface MenuItemFragment {
  __typename: "menu_menu_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s title.
  title: string | null,
  linkEntry:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s URI.
      uri: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s URI.
      uri: string | null,
    }
  ) | null >,
  externalLink: string | null,
  // The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query.
  ancestors:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s title.
      title: string | null,
    }
  ) >,
};

export interface HeroFragment {
  __typename: "exchangePage_exchangePage_Entry",
  description: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface NewsItemFragment {
  __typename: "news_news_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s post date.
  postDate: string | null,
};

export interface NewsItemsFragment {
  __typename: "news_news_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s post date.
  postDate: string | null,
};

export interface RelatedStoryFragment {
  __typename: "story_story_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  // The entry’s post date.
  postDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  service:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface RelatedEventFragment {
  __typename: "event_event_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  // The entry’s post date.
  postDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface RelatedServiceFragment {
  __typename: "service_service_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  // The entry’s post date.
  postDate: string | null,
  relatedItemImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface RelatedInfoFragment {
  __typename: "info_info_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  // The entry’s post date.
  postDate: string | null,
  relatedItemImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface RelatedDocumentationFragment {
  __typename: "documentation_documentation_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  // The entry’s post date.
  postDate: string | null,
  relatedItemImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface RelatedNewsFragment {
  __typename: "news_news_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  // The entry’s post date.
  postDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export type RelatedFragment = ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "documentation_documentation_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "info_info_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s title.
      title: string | null,
      // The entry’s post date.
      postDate: string | null,
      relatedItemImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  );

export interface FeaturedStoryFragment {
  __typename: "story_story_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s title.
  title: string | null,
  // The element’s URI.
  uri: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  description: string | null,
  service:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "networkConnection_networkConnection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "news_news_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsCollection_newsCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "city_city_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partner_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "search_search_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "service_service_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "story_story_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_wishlist_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_registration_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_internal_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "contactRequest_document_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "country_country_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "eventCollection_eventCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "exchangePage_exchangePage_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "footerLink_footerLink_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "graph_graph_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "network_network_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "getStarted_getStarted_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "event_event_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "documentation_documentation_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "info_info_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "menu_menu_Entry",
      // The element’s title.
      title: string | null,
    } | {
      __typename: "newsletter_newsletter_Entry",
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s post date.
  postDate: string | null,
};

export interface FeaturedStoriesFragment {
  __typename: "exchangePage_exchangePage_Entry",
  featuredStories:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s title.
      title: string | null,
      // The element’s URI.
      uri: string | null,
      heroImage:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      description: string | null,
      service:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s title.
          title: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      // The entry’s post date.
      postDate: string | null,
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface ServiceHowItWorksFragment {
  __typename: "service_service_Entry",
  // The ID of the entity
  id: string | null,
  contentBlocks:  Array<( {
      __typename: "contentBlocks_intro_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_text_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      textHTML: string | null,
    } | {
      __typename: "contentBlocks_textWithImage_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      textHTML: string | null,
      imageAlignment: string | null,
    } | {
      __typename: "contentBlocks_image_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          urlLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
      heading: string | null,
      text: string | null,
      buttonText: string | null,
      buttonLink:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
      textAlignment: string | null,
    } | {
      __typename: "contentBlocks_graphic_BlockType",
      image:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcSmall: string | null,
          // The width in pixels or null if it’s not an image.
          widthSmall: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcMedium: string | null,
          // The width in pixels or null if it’s not an image.
          widthMedium: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcLarge: string | null,
          // The width in pixels or null if it’s not an image.
          widthLarge: number | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          srcOriginal: string | null,
          // The width in pixels or null if it’s not an image.
          widthOriginal: number | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_video_BlockType",
      videoUrl: string | null,
    } | {
      __typename: "contentBlocks_audio_BlockType",
      podcastUrl: string | null,
      podcastDescription: string | null,
    } | {
      __typename: "contentBlocks_table_BlockType",
      heading: string | null,
      table: string | null,
    } | {
      __typename: "contentBlocks_codeSnippet_BlockType",
      programmingLanguage: string | null,
      snippet: string | null,
    } | {
      __typename: "contentBlocks_contactForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      documentUpload: boolean | null,
      description: string | null,
    } | {
      __typename: "contentBlocks_logos_BlockType",
      logos:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src: string | null,
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          src2x: string | null,
          // The element’s title.
          title: string | null,
          // The ID of the entity
          id: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_button_BlockType",
      // The ID of the entity
      id: string | null,
      label: string | null,
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "networkConnection_networkConnection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "news_news_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsCollection_newsCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "city_city_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partner_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "search_search_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "service_service_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "story_story_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_partner_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_wishlist_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_registration_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_internal_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "contactRequest_document_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "country_country_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "eventCollection_eventCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "exchangePage_exchangePage_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "footerLink_footerLink_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "graph_graph_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "network_network_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "getStarted_getStarted_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "event_event_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "documentation_documentation_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "info_info_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "menu_menu_Entry",
          // The element’s URI.
          uri: string | null,
        } | {
          __typename: "newsletter_newsletter_Entry",
          // The element’s URI.
          uri: string | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_sectionEnding_BlockType",
    } | {
      __typename: "contentBlocks_users_BlockType",
      // The ID of the entity
      id: string | null,
      heading: string | null,
      users:  Array<( {
          __typename: "User",
          // The user’s full name.
          fullName: string | null,
          functionTitle: string | null,
          // The user’s photo.
          photo: ( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
            }
          ) | null,
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_trafficGraph_BlockType",
      // The ID of the entity
      id: string | null,
      dataset:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
          // The element’s title.
          title: string | null,
          interval: string | null,
          peakIn: string | null,
          peakOut: string | null,
          averageIn: string | null,
          averageOut: string | null,
          currentIn: string | null,
          currentOut: string | null,
          averageInputData:  Array< {
            __typename: "averageInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          averageOutputData:  Array< {
            __typename: "averageOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakOutputData:  Array< {
            __typename: "peakOutputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
          peakInputData:  Array< {
            __typename: "peakInputData_TableRow",
            time: string | null,
            value: string | null,
          } | null > | null,
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "contentBlocks_applicationForm_BlockType",
      // The ID of the entity
      id: string | null,
      buttonText: string | null,
      applicationType: string | null,
    } | {
      __typename: "contentBlocks_registrationForm_BlockType",
      // The ID of the entity
      id: string | null,
      ctaText: string | null,
      buttonText: string | null,
      compactDisplay: boolean | null,
      description: string | null,
    }
  ) | null >,
};

export interface ServiceMenuParentFragment {
  __typename: "menu_menu_Entry",
  // The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query.
  children:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
      linkEntry:  Array<( {
          __typename: "storyCollection_storyCollection_Entry",
        } | {
          __typename: "networkConnection_networkConnection_Entry",
        } | {
          __typename: "news_news_Entry",
        } | {
          __typename: "newsCollection_newsCollection_Entry",
        } | {
          __typename: "city_city_Entry",
        } | {
          __typename: "partner_partner_Entry",
        } | {
          __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
        } | {
          __typename: "partnerCollection_partnerCollection_Entry",
        } | {
          __typename: "search_search_Entry",
        } | {
          __typename: "service_service_Entry",
          // The ID of the entity
          id: string | null,
          // The element’s title.
          title: string | null,
          description: string | null,
          // The element’s URI.
          uri: string | null,
          // The element’s slug.
          slug: string | null,
          icon:  Array<( {
              __typename: "images_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
              // The element’s title.
              title: string | null,
            } | {
              __typename: "uploadAssets_Asset",
              // The full URL of the asset. This field accepts the same fields as the `transform` directive.
              url: string | null,
              // The element’s title.
              title: string | null,
            }
          ) | null >,
        } | {
          __typename: "story_story_Entry",
        } | {
          __typename: "contactRequest_partner_Entry",
        } | {
          __typename: "contactRequest_network_Entry",
        } | {
          __typename: "contactRequest_wishlist_Entry",
        } | {
          __typename: "contactRequest_registration_Entry",
        } | {
          __typename: "contactRequest_internal_Entry",
        } | {
          __typename: "contactRequest_document_Entry",
        } | {
          __typename: "country_country_Entry",
        } | {
          __typename: "eventCollection_eventCollection_Entry",
        } | {
          __typename: "exchangePage_exchangePage_Entry",
        } | {
          __typename: "footerLink_footerLink_Entry",
        } | {
          __typename: "graph_graph_Entry",
        } | {
          __typename: "network_network_Entry",
        } | {
          __typename: "newsletterCollection_newsletterCollection_Entry",
        } | {
          __typename: "getStarted_getStarted_Entry",
        } | {
          __typename: "event_event_Entry",
        } | {
          __typename: "documentation_documentation_Entry",
        } | {
          __typename: "info_info_Entry",
        } | {
          __typename: "menu_menu_Entry",
        } | {
          __typename: "newsletter_newsletter_Entry",
        }
      ) | null >,
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) >,
};

export interface ServiceMenuFragment {
  __typename: "menu_menu_Entry",
  linkEntry:  Array<( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
      // The ID of the entity
      id: string | null,
      // The element’s title.
      title: string | null,
      description: string | null,
      // The element’s URI.
      uri: string | null,
      // The element’s slug.
      slug: string | null,
      icon:  Array<( {
          __typename: "images_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        } | {
          __typename: "uploadAssets_Asset",
          // The full URL of the asset. This field accepts the same fields as the `transform` directive.
          url: string | null,
          // The element’s title.
          title: string | null,
        }
      ) | null >,
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  ) | null >,
};

export interface ServiceFragment {
  __typename: "service_service_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s title.
  title: string | null,
  description: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s slug.
  slug: string | null,
  icon:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      url: string | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      url: string | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export interface FeaturedStoryCollectionItemFragment {
  __typename: "story_story_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  description: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcSmall: string | null,
      // The width in pixels or null if it’s not an image.
      widthSmall: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcMedium: string | null,
      // The width in pixels or null if it’s not an image.
      widthMedium: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      srcLarge: string | null,
      // The width in pixels or null if it’s not an image.
      widthLarge: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s post date.
  postDate: string | null,
};

export interface NewsCollectionItemFragment {
  __typename: "news_news_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  description: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s post date.
  date: string | null,
};

export interface NewsletterCollectionItemFragment {
  __typename: "newsletter_newsletter_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  newsletterUrl: string | null,
  newsletterDate: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s post date.
  date: string | null,
};

export interface EventCollectionItemFragment {
  __typename: "event_event_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  description: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  startDate: string | null,
  endDate: string | null,
  // The entry’s post date.
  date: string | null,
  listInPastEvents: boolean | null,
  location: string | null,
};

export interface StoryCollectionItemFragment {
  __typename: "story_story_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  description: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
  // The entry’s post date.
  date: string | null,
};

export interface InfoCollectionItemFragment {
  __typename: "info_info_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  description: string | null,
};

export interface DocumentationCollectionItemFragment {
  __typename: "documentation_documentation_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  description: string | null,
};

export interface ServiceCollectionItemFragment {
  __typename: "service_service_Entry",
  // The ID of the entity
  id: string | null,
  // The element’s URI.
  uri: string | null,
  // The element’s title.
  title: string | null,
  description: string | null,
  heroImage:  Array<( {
      __typename: "images_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    } | {
      __typename: "uploadAssets_Asset",
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src: string | null,
      // The width in pixels or null if it’s not an image.
      width: number | null,
      // The full URL of the asset. This field accepts the same fields as the `transform` directive.
      src2x: string | null,
      // The width in pixels or null if it’s not an image.
      width2x: number | null,
      // The element’s title.
      title: string | null,
    }
  ) | null >,
};

export type EntryTypesFragment = ( {
      __typename: "storyCollection_storyCollection_Entry",
    } | {
      __typename: "networkConnection_networkConnection_Entry",
    } | {
      __typename: "news_news_Entry",
    } | {
      __typename: "newsCollection_newsCollection_Entry",
    } | {
      __typename: "city_city_Entry",
    } | {
      __typename: "partner_partner_Entry",
    } | {
      __typename: "connectedNetworkCollection_connectedNetworkCollection_Entry",
    } | {
      __typename: "partnerCollection_partnerCollection_Entry",
    } | {
      __typename: "search_search_Entry",
    } | {
      __typename: "service_service_Entry",
    } | {
      __typename: "story_story_Entry",
    } | {
      __typename: "contactRequest_partner_Entry",
    } | {
      __typename: "contactRequest_network_Entry",
    } | {
      __typename: "contactRequest_wishlist_Entry",
    } | {
      __typename: "contactRequest_registration_Entry",
    } | {
      __typename: "contactRequest_internal_Entry",
    } | {
      __typename: "contactRequest_document_Entry",
    } | {
      __typename: "country_country_Entry",
    } | {
      __typename: "eventCollection_eventCollection_Entry",
    } | {
      __typename: "exchangePage_exchangePage_Entry",
    } | {
      __typename: "footerLink_footerLink_Entry",
    } | {
      __typename: "graph_graph_Entry",
    } | {
      __typename: "network_network_Entry",
    } | {
      __typename: "newsletterCollection_newsletterCollection_Entry",
    } | {
      __typename: "getStarted_getStarted_Entry",
    } | {
      __typename: "event_event_Entry",
    } | {
      __typename: "documentation_documentation_Entry",
    } | {
      __typename: "info_info_Entry",
    } | {
      __typename: "menu_menu_Entry",
    } | {
      __typename: "newsletter_newsletter_Entry",
    }
  );
