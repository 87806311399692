import React, { FC, useState } from 'react';
import styled from '../../../../styled-components';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import { RouteComponentProps, withRouter } from 'react-router';
import { DataProps, graphql } from 'react-apollo';
import { PinpointCardStatsQuery } from '../../../../entities/operationResults';
import { ExchangeParams } from '../../../Exchange';
import PinpointCard from './PinpointCard'
import { poweredBySites } from '../../../../constants/sites';
import WorldMapPoints from './WorldMapPoints';

const GET_LOCATION_STATISTICS = gql`
    query PinpointCardStats($ams: String!, $bay: String!, $car: String!, $chi: String!, $hk: String!, $mum: String!, $kol: String!, $sin: String!, $hyd: String!, $lag: String!, $ban: String!, $dji: String!) {
        vitalsAms: globalSet(site: [$ams], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
		}
		vitalsBay: globalSet(site: [$bay], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
		}
		vitalsCar: globalSet(site: [$car], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
		}
		vitalsChi: globalSet(site: [$chi], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
        }
        vitalsHK: globalSet(site: [$hk], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
        }
        vitalsMum: globalSet(site: [$mum], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
        }
		vitalsKol: globalSet(site: [$kol], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
        }
		vitalsSin: globalSet(site: [$sin], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
        }
		vitalsHyd: globalSet(site: [$hyd], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
        }
		vitalsLag: globalSet(site: [$lag], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
        }
		vitalsBan: globalSet(site: [$ban], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
        }
        vitalsDji: globalSet(site: [$dji], handle: "globalStats") {
            ...on globalStats_GlobalSet {
                peakTraffic
                connectedNetworks
            }
        }
    }
`;

interface InProps {
	locationHandle: string;
	locationName: string;
    currentExchange: string;
}

type VitalsType =
	{__typename: "email_GlobalSet"} |
	{__typename: "exchangeConfig_GlobalSet"} |
	{__typename: "globalStats_GlobalSet", peakTraffic: string | null, connectedNetworks: string | null} |
	{__typename: "modalSettings_GlobalSet"} |
	{__typename: "socialMedia_GlobalSet"} |
	{__typename: "glossary_GlobalSet"} |
	{__typename: "commitmentModule_GlobalSet"} |
	{__typename: "smartSnippets_GlobalSet"} |
	null |
	undefined;

type ReactText = string | number;

const convertReactTextToNumber = (asns: ReactText): number | null | undefined => {
	let asnsNumber: number | null | undefined = null;

	if (typeof asns === 'string') {
		asnsNumber = parseFloat(asns);
	} else if (typeof asns === 'number') {
		asnsNumber = asns;
	}

	return asnsNumber;
}

interface OutProps extends DataProps<PinpointCardStatsQuery>, RouteComponentProps<ExchangeParams> {}

type StatsType = 'peak' | 'connectedNetworks';

const WHITE_COLOR = '#ffffff';
const AMSIX_ORANGE = '#F47F22';
const POWERED_BY_AMSIX = '#6A83AF';

// Important: location handles are hardcoded. In previous versions we used constants
// for each location. But the problem was that during a build script the linting service
// took a long time to finish creating a build (so long that it never finished).
// By hardcoding these handles this problem is avoided.
// Some locations in India have a custom ForeignObject component. This is done
// so that extra information is only displayed if a user hovers a link or the circle
// of that location. This is made this way because on some places the extra information
// hides the circle of another location.
const WorldMap: FC<InProps & OutProps> = props => {
	const [showCard, setShowCard] = useState(false);
	const [showCardHandle, setShowCardHandle] = useState('');
	const [keepVisible, setKeepVisible] = useState(false);

	const { vitalsAms, vitalsBay, vitalsCar, vitalsChi, vitalsHK, vitalsHyd, vitalsKol, vitalsMum, vitalsSin, vitalsLag, vitalsBan, vitalsDji} = props.data;
	const { currentExchange, locationHandle, locationName } = props

	const hoverLocation = (handle: string) => {
		if (showCard) {
			setShowCardHandle('');
			setShowCard(false);
		} else {
			setShowCardHandle(handle);
			setShowCard(true);
		};
	};

	// Used to be able to click the button in a powered by location when hovering over the card
	const hoverLocationPoweredBy = (handle: string) => {
		if (showCard) {
			setShowCard(false);
		} else {
			setShowCardHandle(handle);
			setShowCard(true);
			setKeepVisible(true);
		};
	};

	const removeKeepVisisble = () => {
		setKeepVisible(false);
		setShowCardHandle('');
	};

	// If used browser is Safari, do not show Pinpointcard containing information of location
	// This is due to render issues on Safari, related to https://bugs.webkit.org/show_bug.cgi?id=23113
	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	return (
		<WorldMapContainer>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="-65 50 650.01 183.97" width="100%" height="100%">
				<WorldMapPoints/>
				<filter id="neon" x="-200%" y="-200%" width="100px" height="100px">
					<feFlood floodColor="rgb(244,127,34)" floodOpacity="1.5" in="SourceGraphic" />
					<feComposite operator="in" in2="SourceGraphic" />
					<feGaussianBlur stdDeviation="4" />
					<feComponentTransfer result="glow1">
						<feFuncA type="linear" slope="3" intercept="0" />
					</feComponentTransfer>
					<feMerge>
						<feMergeNode in="glow1" />
						<feMergeNode in="SourceGraphic" />
					</feMerge>
				</filter>
				<filter id="neonPoweredByAmsix" x="-200%" y="-200%" width="100px" height="100px">
					<feFlood floodColor="rgb(39,114,245)" floodOpacity="1.5" in="SourceGraphic" />
					<feComposite operator="in" in2="SourceGraphic" />
					<feGaussianBlur stdDeviation="4" />
					<feComponentTransfer result="glow1">
						<feFuncA type="linear" slope="3" intercept="0" />
					</feComponentTransfer>
					<feMerge>
						<feMergeNode in="glow1" />
						<feMergeNode in="SourceGraphic" />
					</feMerge>
				</filter>
				<g id="Locations">
					{/* Hong Kong */}
					{!isSafari &&
						<foreignObject x="677.23" y="62.53" width="30%" height="130" transform="scale(0.6,0.6)">
							{locationHandle === 'hk' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsHK, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsHK, 'connectedNetworks'))}
								/>
							)}
							{showCardHandle === 'hk' && showCard &&
								<PinpointCard
									locationName='Hong Kong'
									peak={getStatsData(vitalsHK, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsHK, 'connectedNetworks'))}
								/>
							}
						</foreignObject>
					 }
					<Circle
						id="hk"
						filter={currentExchange === 'hk' ? 'url(#neon)' : locationHandle === 'hk' ? 'url(#neon)' : showCardHandle === 'hk' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'hk' ? WHITE_COLOR : currentExchange === 'hk' ? WHITE_COLOR : showCardHandle === 'hk' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="465.23" cy="114.53" r="3.4"
						onMouseOver={() => hoverLocation('hk')}
						onMouseLeave={() => hoverLocation('hk')}
					/>
					{/* Singapore */}
					{!isSafari &&
						<foreignObject x="657.23" y="276.53" width="30%" height="130" transform="scale(0.6,0.6)">
							{locationHandle === 'sin' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsSin, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsSin, 'connectedNetworks'))}
									alternative
								/>
							)}
							{showCardHandle === 'sin' && showCard &&
								<PinpointCard
									locationName='Singapore'
									peak={getStatsData(vitalsSin, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsSin, 'connectedNetworks'))}
									alternative
								/>
							}
						</foreignObject>
					}
					<Circle
						id="sin"
						filter={currentExchange === 'sin' ? 'url(#neon)' : locationHandle === 'sin' ? 'url(#neon)' : showCardHandle === 'sin' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'sin' ? WHITE_COLOR : currentExchange === 'sin' ? WHITE_COLOR : showCardHandle === 'sin' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="452.23" cy="160.53" r="3.4"
						onMouseOver={() => hoverLocation('sin')}
						onMouseLeave={() => hoverLocation('sin')}
					/>
					{/* Hyderabad */}
					{!isSafari &&
						<ForeignObject x="572.23" y="232.53" width="30%" height="130" transform="scale(0.6,0.6)" hover={showCardHandle === 'hyd'} locationHandle={locationHandle === 'hyd'}>
							{locationHandle === 'hyd' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsHyd, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsHyd, 'connectedNetworks'))}
									alternative
								/>
							)}
							{showCardHandle === 'hyd' && showCard &&
								<PinpointCard
									locationName='Hyderabad'
									peak={getStatsData(vitalsHyd, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsHyd, 'connectedNetworks'))}
									alternative
								/>
							}
						</ForeignObject>
					}
					<Circle
						id="hyd"
						filter={currentExchange === 'hyd' ? 'url(#neon)' : locationHandle === 'hyd' ? 'url(#neon)' : showCardHandle === 'hyd' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'hyd' ? WHITE_COLOR : currentExchange === 'hyd' ? WHITE_COLOR : showCardHandle === 'hyd' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="401.3" cy="131.6" r="3.4"
						onMouseOver={() => hoverLocation('hyd')}
						onMouseLeave={() => hoverLocation('hyd')}
					/>
					{/* Amsterdam */}
					{!isSafari &&
						<foreignObject x="337.23" y="-30.53" width="30%" height="130" transform="scale(0.6,0.6)">
							{locationHandle === 'ams' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsAms, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsAms, 'connectedNetworks'))}
								/>
							)}
							{showCardHandle === 'ams' && showCard &&
								<PinpointCard
									locationName='Amsterdam'
									peak={getStatsData(vitalsAms, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsAms, 'connectedNetworks'))}
								/>
							}
						</foreignObject>
					}
					<Circle
						id="ams"
						filter={currentExchange === 'ams' ? 'url(#neon)' : locationHandle === 'ams' ? 'url(#neon)' : showCardHandle === 'ams' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'ams' ? WHITE_COLOR : currentExchange === 'ams' ? WHITE_COLOR : showCardHandle === 'ams' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="261.44" cy="58.75" r="3.4"
						onMouseOver={() => hoverLocation('ams')}
						onMouseLeave={() => hoverLocation('ams')}
					/>
					{/* Chicago */}
					{!isSafari &&
						<foreignObject x="84.23" y="-35" width="30%" height="130" transform="scale(0.6,0.6)">
							{locationHandle === 'chi' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsChi, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsChi, 'connectedNetworks'))}
								/>
							)}
							{showCardHandle === 'chi' && showCard &&
								<PinpointCard
									locationName='Chicago'
									peak={getStatsData(vitalsChi, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsChi, 'connectedNetworks'))}
								/>
							}
						</foreignObject>
					}
					<Circle
						id="chi"
						filter={currentExchange === 'chi' ? 'url(#neon)' : locationHandle === 'chi' ? 'url(#neon)' : showCardHandle === 'chi' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'chi' ? WHITE_COLOR : currentExchange === 'chi' ? WHITE_COLOR : showCardHandle === 'chi' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="109.54" cy="58.72" r="3.4"
						onMouseOver={() => hoverLocation('chi')}
						onMouseLeave={() => hoverLocation('chi')}
					/>
					{/* Bay Area */}
					{!isSafari &&
						<foreignObject x="-43" y="18.53" width="30%" height="130" transform="scale(0.6,0.6)">
							{locationHandle === 'bay' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsBay, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsBay, 'connectedNetworks'))}
								/>
							)}
							{showCardHandle === 'bay' && showCard &&
								<PinpointCard
									locationName='Bay Area'
									peak={getStatsData(vitalsBay, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsBay, 'connectedNetworks'))}
								/>
							}
						</foreignObject>
					}
					<Circle
						id="bay"
						filter={currentExchange === 'bay' ? 'url(#neon)' : locationHandle === 'bay' ? 'url(#neon)' : showCardHandle === 'bay' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'bay' ? WHITE_COLOR : currentExchange === 'bay' ? WHITE_COLOR : showCardHandle === 'bay' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="33.39" cy="89.01" r="3.4"
						onMouseOver={() => hoverLocation('bay')}
						onMouseLeave={() => hoverLocation('bay')}
					/>
					{/* Caribbean */}
					{!isSafari &&
						<foreignObject x="93.23" y="234.53" width="30%" height="130" transform="scale(0.6,0.6)">
							{locationHandle === 'car' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsCar, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsCar, 'connectedNetworks'))}
									alternative
								/>
							)}
							{showCardHandle === 'car' && showCard &&
								<PinpointCard
									locationName='Caribbean'
									peak={getStatsData(vitalsCar, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsCar, 'connectedNetworks'))}
									alternative
								/>
							}
						</foreignObject>
					}
					<Circle
						id="car"
						filter={currentExchange === 'car' ? 'url(#neon)' : locationHandle === 'car' ? 'url(#neon)' : showCardHandle === 'car' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'car' ? WHITE_COLOR : currentExchange === 'car' ? WHITE_COLOR : showCardHandle === 'car' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="113.9" cy="135.4" r="3.4"
						onMouseOver={() => hoverLocation('car')}
						onMouseLeave={() => hoverLocation('car')}
					/>
					{/* Mumbai */}
					{!isSafari &&
						<ForeignObject x="558.23" y="220.53" width="30%" height="130" transform="scale(0.6,0.6)" hover={showCardHandle === 'mum'} locationHandle={locationHandle === 'mum'}>
							{locationHandle === 'mum' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsMum, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsMum, 'connectedNetworks'))}
									alternative
								/>
							)}
							{showCardHandle === 'mum' && showCard &&
								<PinpointCard
									locationName='Mumbai'
									peak={getStatsData(vitalsMum, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsMum, 'connectedNetworks'))}
									alternative
								/>
							}
						</ForeignObject>
					}
					<Circle
						id="in"
						filter={currentExchange === 'mum' ? 'url(#neon)' : locationHandle === 'mum' ? 'url(#neon)' : showCardHandle === 'mum' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'mum' ? WHITE_COLOR : currentExchange === 'mum' ? WHITE_COLOR : showCardHandle === 'mum' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="392.99" cy="127.01" r="3.4"
						onMouseOver={() => hoverLocation('mum')}
						onMouseLeave={() => hoverLocation('mum')}
					/>
					{/* Kuching */}
					{!isSafari &&
						<ForeignObject x="685.23" y="276.53" width="30%" height="150" transform="scale(0.6,0.6)" onMouseLeave={() => removeKeepVisisble()} hover={showCardHandle === 'kuc'} locationHandle={locationHandle === 'kuc'}>
							{locationHandle === 'kuc' && (
								<PinpointCard locationName={locationName} internationalExchange='irix' url={getUrl(locationHandle)} poweredBy alternative/>
							)}
							{showCardHandle === 'kuc' && keepVisible &&
								<PinpointCard locationName='Kuching' internationalExchange='irix' url={getUrl('kuc')} poweredBy alternative/>
							}
						</ForeignObject>
					}
					<Circle
						id="kuc"
						filter={locationHandle === 'kuc' ? 'url(#neonPoweredByAmsix)' : showCardHandle === 'kuc' ? 'url(#neonPoweredByAmsix)' : 'none'}
						fill={locationHandle === 'kuc' ? WHITE_COLOR : showCardHandle === 'kuc' ? WHITE_COLOR : POWERED_BY_AMSIX}
						className="cls-2" cx="469.25" cy="160.53" r="3.4"
						onMouseOver={() => hoverLocationPoweredBy('kuc')}
						onMouseLeave={() => hoverLocationPoweredBy('kuc')}
					/>
					{/* Bangkok */}
					{!isSafari &&
						<ForeignObject x="650.23" y="238.53" width="30%" height="130" transform="scale(0.6,0.6)" hover={showCardHandle === 'ban'} locationHandle={locationHandle === 'ban'}>
							{locationHandle === 'ban' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsBan, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsBan, 'connectedNetworks'))}
									alternative
								/>
							)}
							{showCardHandle === 'ban' && showCard &&
								<PinpointCard
									locationName='Bangkok'
									peak={getStatsData(vitalsBan, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsBan, 'connectedNetworks'))}
									alternative
								/>
							}
						</ForeignObject>
					}
					<Circle
						id="ban"
						filter={currentExchange === 'ban' ? 'url(#neon)' : locationHandle === 'ban' ? 'url(#neon)' : showCardHandle === 'ban' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'ban' ? WHITE_COLOR : currentExchange === 'ban' ? WHITE_COLOR : showCardHandle === 'ban' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="448.12" cy="135.53" r="3.4"
						onMouseOver={() => hoverLocation('ban')}
						onMouseLeave={() => hoverLocation('ban')}
					/>
					{/* Kolkata */}
					{!isSafari &&
						<ForeignObject x="600.23" y="205.53" width="30%" height="130" transform="scale(0.6,0.6)" hover={showCardHandle === 'kol'} locationHandle={locationHandle === 'kol'}>
							{locationHandle === 'kol' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsKol, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsKol, 'connectedNetworks'))}
									alternative
								/>
							)}
							{showCardHandle === 'kol' && showCard &&
								<PinpointCard
									locationName='Kolkata'
									peak={getStatsData(vitalsKol, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsKol, 'connectedNetworks'))}
									alternative
								/>
							}
						</ForeignObject>
					}
					<Circle
						id="kol"
						filter={currentExchange === 'kol' ? 'url(#neon)' : locationHandle === 'kol' ? 'url(#neon)' : showCardHandle === 'kol' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'kol' ? WHITE_COLOR : currentExchange === 'kol' ? WHITE_COLOR : showCardHandle === 'kol' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="418.5" cy="118.9" r="3.4"
						onMouseOver={() => hoverLocation('kol')}
						onMouseLeave={() => hoverLocation('kol')}
					/>
					{/* Manila */}
					{!isSafari &&
						<ForeignObject x="714.23" y="230.53" width="30%" height="130" transform="scale(0.6,0.6)" hover={showCardHandle === 'man'} locationHandle={locationHandle === 'man'}>
							{locationHandle === 'man' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsKol, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsKol, 'connectedNetworks'))}
									alternative
									comingSoon
								/>
							)}
							{showCardHandle === 'man' && showCard &&
								<PinpointCard
									locationName='Manila'
									peak={getStatsData(vitalsKol, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsKol, 'connectedNetworks'))}
									alternative
									comingSoon
								/>
							}
						</ForeignObject>
					}
					<Circle
						id="man"
						filter={currentExchange === 'man' ? 'url(#neon)' : locationHandle === 'man' ? 'url(#neon)' : showCardHandle === 'man' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'man' ? WHITE_COLOR : currentExchange === 'man' ? WHITE_COLOR : showCardHandle === 'man' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="486.5" cy="134.53" r="3.4"
						onMouseOver={() => hoverLocation('man')}
						onMouseLeave={() => hoverLocation('man')}
					/>
					{/* Lagos */}
					{!isSafari &&
						<ForeignObject x="326.23" y="255.53" width="30%" height="130" transform="scale(0.6,0.6)" hover={showCardHandle === 'lag'} locationHandle={locationHandle === 'lag'}>
							{locationHandle === 'lag' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsLag, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsLag, 'connectedNetworks'))}
									alternative
								/>
							)}
							{showCardHandle === 'lag' && showCard &&
								<PinpointCard
									locationName='Lagos'
									peak={getStatsData(vitalsLag, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsLag, 'connectedNetworks'))}
									alternative
								/>
							}
						</ForeignObject>
					}
					<Circle
						id="lag"
						filter={currentExchange === 'lag' ? 'url(#neon)' : locationHandle === 'lag' ? 'url(#neon)' : showCardHandle === 'lag' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'lag' ? WHITE_COLOR : currentExchange === 'lag' ? WHITE_COLOR : showCardHandle === 'lag' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="253.5" cy="148.53" r="3.4"
						onMouseOver={() => hoverLocation('lag')}
						onMouseLeave={() => hoverLocation('lag')}
					/>
					{/* Djibouti */}
					{!isSafari &&
						<ForeignObject x="453.23" y="243.53" width="30%" height="130" transform="scale(0.6,0.6)" hover={showCardHandle === 'dji'} locationHandle={locationHandle === 'dji'}>
							{locationHandle === 'dji' && (
								<PinpointCard
									locationName={locationName}
									peak={getStatsData(vitalsDji, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsDji, 'connectedNetworks'))}
									alternative
								/>
							)}
							{showCardHandle === 'dji' && showCard &&
								<PinpointCard
									locationName='Djibouti'
									peak={getStatsData(vitalsDji, 'peak')}
									asns={convertReactTextToNumber(getStatsData(vitalsDji, 'connectedNetworks'))}
									alternative
								/>
							}
						</ForeignObject>
					}
					<Circle
						id="dji"
						filter={currentExchange === 'dji' ? 'url(#neon)' : locationHandle === 'dji' ? 'url(#neon)' : showCardHandle === 'dji' ? 'url(#neon)' : 'none'}
						fill={locationHandle === 'dji' ? WHITE_COLOR : currentExchange === 'dji' ? WHITE_COLOR : showCardHandle === 'dji' ? WHITE_COLOR : AMSIX_ORANGE}
						className="cls-2" cx="329.8" cy="140.1" r="3.4"
						onMouseOver={() => hoverLocation('dji')}
						onMouseLeave={() => hoverLocation('dji')}
					/>
					{/* Manama */}
					{!isSafari &&
						<ForeignObject x="472.23" y="48.53" width="30%" height="150" transform="scale(0.6,0.6)" onMouseLeave={() => removeKeepVisisble()} hover={showCardHandle === 'mnix'} locationHandle={locationHandle === 'mnix'}>
							{locationHandle === 'mnix' && (
								<PinpointCard locationName={locationName} internationalExchange='MN-IX' url={getUrl(locationHandle)} poweredBy/>
							)}
							{showCardHandle === 'mnix' && keepVisible &&
								<PinpointCard locationName='Manama' internationalExchange='MN-IX' url={getUrl('mnix')} poweredBy/>
							}
						</ForeignObject>
					}
					<Circle
						id="mnix"
						filter={locationHandle === 'mnix' ? 'url(#neonPoweredByAmsix)' : showCardHandle === 'mnix' ? 'url(#neonPoweredByAmsix)' : 'none'}
						fill={locationHandle === 'mnix' ? WHITE_COLOR : showCardHandle === 'mnix' ? WHITE_COLOR : POWERED_BY_AMSIX}
						className="cls-2" cx="342.36" cy="110.16" r="3.4"
						onMouseOver={() => hoverLocationPoweredBy('mnix')}
						onMouseLeave={() => hoverLocationPoweredBy('mnix')}
					/>
					{/* Muscat */}
					{!isSafari &&
						<ForeignObject x="501.23" y="204.53" width="30%" height="150" transform="scale(0.6,0.6)" onMouseLeave={() => removeKeepVisisble()} hover={showCardHandle === 'omix'} locationHandle={locationHandle === 'omix'}>
							{locationHandle === 'omix' && (
								<PinpointCard locationName={locationName} internationalExchange='OMAN-IX' url={getUrl(locationHandle)} poweredBy alternative />
							)}
							{showCardHandle === 'omix' && keepVisible &&
								<PinpointCard locationName='Muscat' internationalExchange='OMAN-IX' url={getUrl('omix')} poweredBy alternative />
							}
						</ForeignObject>
					}
					<Circle
						id="omix"
						filter={locationHandle === 'omix' ? 'url(#neonPoweredByAmsix)' : showCardHandle === 'omix' ? 'url(#neonPoweredByAmsix)' : 'none'}
						fill={locationHandle === 'omix' ? WHITE_COLOR : showCardHandle === 'omix' ? WHITE_COLOR : POWERED_BY_AMSIX}
						className="cls-2" cx="358.9" cy="118.7" r="3.4"
						onMouseOver={() => hoverLocationPoweredBy('omix')}
						onMouseLeave={() => hoverLocationPoweredBy('omix')}
					/>
					{/* Cairo */}
					{!isSafari &&
						<ForeignObject x="416.23" y="184.53" width="30%" height="150" transform="scale(0.6,0.6)" onMouseLeave={() => removeKeepVisisble()} hover={showCardHandle === 'cai'} locationHandle={locationHandle === 'cai'}>
							{locationHandle === 'cai' && (
								<PinpointCard locationName={locationName} internationalExchange='EG-IX' url={getUrl(locationHandle)} poweredBy alternative/>
							)}
							{showCardHandle === 'cai' && keepVisible &&
								<PinpointCard locationName='Cairo' internationalExchange='EG-IX' url={getUrl('cai')} poweredBy alternative/>
							}
						</ForeignObject>
					}
					<Circle
						id="cai"
						filter={locationHandle === 'cai' ? 'url(#neonPoweredByAmsix)' : showCardHandle === 'cai' ? 'url(#neonPoweredByAmsix)' : 'none'}
						fill={locationHandle === 'cai' ? WHITE_COLOR : showCardHandle === 'cai' ? WHITE_COLOR : POWERED_BY_AMSIX}
						className="cls-2" cx="308.3" cy="105.9" r="3.4"
						onMouseOver={() => hoverLocationPoweredBy('cai')}
						onMouseLeave={() => hoverLocationPoweredBy('cai')}
					/>
				</g>
			</svg>
		</WorldMapContainer>
	);
};

const getUrl = (handle: string) => {
	const location: any = poweredBySites.find(x => x.handle === handle);

	return location.url || '';
}

/**
 * This method returns the stats for a location, used in the Worldmap.
 *
 * @param vitals The data retrieved from the CMS for a location. Use as vitals[LOCATION_HANDLE]
 * @param type StatsType: peak for total traffic, connectedNetworks for amount of ASNS
 * @returns Peak or ASNS data
 */
const getStatsData = (vitals?: VitalsType | null, type?: StatsType) => {
	if (type === 'peak') {
		if (vitals && 'peakTraffic' in vitals && vitals.peakTraffic !== null) {
			return vitals.peakTraffic
		}
	}
	if (type === 'connectedNetworks') {
		if (vitals && 'connectedNetworks' in vitals && vitals.connectedNetworks !== null) {
			return vitals.connectedNetworks
		}
	}

	return 0
}

const Circle = styled.circle`
	transition: fill .2s ease;
	cursor: pointer;
`;

const WorldMapContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-shrink: 0;
	flex-grow: 1;
`;

const ForeignObject = styled.foreignObject<{ hover: boolean, locationHandle: boolean }>`
	display: ${props => props.hover ? 'inital' : props.locationHandle ? 'initial' : 'none'};
`;

const enhance = compose<OutProps, InProps>(
    withRouter,
    graphql(GET_LOCATION_STATISTICS, {
        options: () => ({
            variables: {
				ams: "ams",
				bay: "bay",
				car: "car",
				chi: "chi",
                hk: "hk",
                mum: "mum",
				kol: "kol",
				sin: "sin",
				hyd: "hyd",
				lag: "lag",
				ban: "ban",
				dji: "dji",
            },
        }),
    })
);

export default enhance(WorldMap);
