import withModal, { ModalInProps } from '../../hocs/withModal';
import React, { SFC } from 'react';
import styled from '../../styled-components';
import Container from '../atoms/Container';
import * as Intro from '../molecules/Intro';
import ContactForm from '../molecules/ContactForm';

interface Props extends ModalInProps {
    partnerName: string;
    partnerId: number;
}

const PartnerSlideover: SFC<Props> = ({ partnerName, partnerId }) => (
    <Container maxWidth>
        <Intro.Container>
            <h1>
                Connect via
                <PartnerName>{ partnerName }</PartnerName>
            </h1>
            <Intro.Sep />
            <Intro.Text>
                <p>
                    You can contact our partner directly by filling in the form below. <br/><br/>
                    <b>Important:</b> this contact form is meant for remote connection requests only. Any other type of request can be considered as spam.
                </p>
            </Intro.Text>
        </Intro.Container>
        <ContactForm toPartner={partnerId} isDark />
    </Container>
);

const PartnerName = styled.span`
    display: block;
    color: ${props => props.theme.colors.tango};
`;

export default withModal(PartnerSlideover);