import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const OpenMenuIcon = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="22" height="16" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M2 0a2 2 0 1 1 0 4 2 2 0 1 1 0-4zM20 0a2 2 0 1 1 0 4 2 2 0 1 1 0-4zM20 12a2 2 0 1 1 0 4 2 2 0 1 1 0-4z"
                fill={color}
            />
            <path d="M3 14h16" stroke={color} strokeWidth="1.4" />
            <path
                d="M2 12a2 2 0 1 1 0 4 2 2 0 1 1 0-4zM2 6a2 2 0 1 1 0 4 2 2 0 1 1 0-4zM20 6a2 2 0 1 1 0 4 2 2 0 1 1 0-4z"
                fill={color}
            />
            <path d="M3 8h16M3 2h16" stroke={color} strokeWidth="1.4" />
        </g>
    </svg>
);

export default OpenMenuIcon;