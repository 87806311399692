import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const Twitter = ({ type, color = 'currentColor', ...rest }: Props) => (
    <svg width="16" height="14">
        <path
            d="M15.638 2.02c-.25.11-.508.205-.77.28a3.41 3.41 0 0 0 .693-1.22.257.257 0 0 0-.376-.3 6.07 6.07 0 0 1-1.794.71 3.44 3.44 0 0 0-2.397-.977A3.428 3.428 0 0 0 7.6 4.379a8.765 8.765 0 0 1-6.016-3.192.257.257 0 0 0-.422.033 3.422 3.422 0 0 0-.464 1.722c0 .825.295 1.607.815 2.219a2.9 2.9 0 0 1-.458-.205.257.257 0 0 0-.382.22v.045c0 1.231.662 2.34 1.675 2.944a2.952 2.952 0 0 1-.26-.038.257.257 0 0 0-.293.332 3.423 3.423 0 0 0 2.508 2.294 6.057 6.057 0 0 1-3.238.924c-.243 0-.487-.014-.725-.042a.258.258 0 0 0-.17.472 9.233 9.233 0 0 0 4.993 1.463c3.486 0 5.667-1.643 6.882-3.022 1.516-1.72 2.385-3.996 2.385-6.244 0-.094-.001-.19-.004-.284a6.656 6.656 0 0 0 1.532-1.622.257.257 0 0 0-.319-.378z"
            fill={color}
            fillRule="nonzero"
        />
    </svg>
);

export default Twitter;
