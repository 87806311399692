import React from 'react';

const WORLD_MAP_COLOR = '#566778';

const WorldMapPoints = () => {
	return (
        <g id="World" fill={WORLD_MAP_COLOR}>
            <circle className="cls-1" cx="26.82" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="30.99" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="39.57" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="43.72" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="60.74" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="73.49" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="77.64" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="90.39" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="94.78" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="107.53" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="111.68" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="115.85" cy="82.43" r="1.44"/>
            <circle className="cls-1" cx="30.99" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="39.57" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="43.72" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="60.74" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="73.49" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="77.64" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="90.39" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="94.78" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="107.53" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="111.68" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="115.85" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="30.99" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="39.57" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="43.72" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="60.74" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="73.49" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="77.64" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="90.39" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="94.78" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="107.53" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="111.68" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="115.85" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="39.57" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="43.72" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="60.74" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="73.49" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="77.64" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="90.39" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="94.78" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="107.53" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="111.68" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="115.85" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="120.14" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="124.43" cy="69.44" r="1.44"/>
            <circle className="cls-1" cx="39.42" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="43.57" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="47.74" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="52.03" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="56.32" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="60.59" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="64.76" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="69.05" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="73.34" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="77.49" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="81.66" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="85.95" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="90.24" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="94.63" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="103.1" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="107.38" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="111.53" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="115.71" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="119.99" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="124.28" cy="65.52" r="1.44"/>
            <circle className="cls-1" cx="39.42" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="43.57" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="47.74" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="52.03" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="56.32" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="60.59" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="64.76" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="69.05" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="73.34" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="77.49" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="81.66" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="85.95" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="90.24" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="94.63" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="103.1" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="115.7" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="119.99" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="124.28" cy="61.19" r="1.44"/>
            <circle className="cls-1" cx="39.42" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="43.57" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="47.74" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="52.03" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="56.32" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="60.59" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="64.76" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="69.05" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="73.34" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="77.49" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="81.66" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="85.95" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="90.24" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="94.63" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="103.1" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="124.28" cy="56.86" r="1.44"/>
            <circle className="cls-1" cx="39.42" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="43.57" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="47.74" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="52.03" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="56.32" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="60.59" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="64.76" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="69.05" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="73.34" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="77.49" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="81.66" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="85.95" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="90.24" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="94.63" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="103.1" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="107.38" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="111.53" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="124.28" cy="52.53" r="1.44"/>
            <circle className="cls-1" cx="39.57" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="43.72" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="60.74" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="73.49" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="77.64" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="90.39" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="94.78" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="107.53" cy="48.2" r="1.44"/>
            <circle className="cls-1" cx="39.57" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="43.72" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="60.74" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="73.49" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="77.64" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="90.39" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="94.78" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="30.99" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="39.57" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="43.72" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="60.74" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="73.49" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="77.64" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="90.39" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="94.78" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="26.82" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="30.99" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="39.57" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="43.72" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="60.74" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="73.49" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="77.64" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="90.39" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="94.78" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="107.53" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="26.67" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="30.85" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="35.13" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="39.42" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="43.57" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="47.74" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="52.03" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="56.32" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="60.59" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="64.76" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="69.05" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="73.34" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="77.49" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="81.66" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="85.95" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="90.24" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="94.63" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="103.1" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="107.38" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="111.53" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="115.71" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="119.99" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="26.67" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="30.85" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="35.13" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="39.42" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="43.57" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="47.74" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="52.03" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="56.32" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="60.59" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="64.76" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="69.05" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="73.34" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="77.49" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="81.66" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="85.95" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="90.24" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="94.63" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="103.1" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="107.38" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="111.53" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="115.7" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="119.99" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="124.28" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="26.67" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="30.85" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="35.13" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="39.42" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="43.57" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="47.74" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="52.03" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="56.32" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="64.76" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="69.05" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="73.34" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="77.49" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="81.66" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="94.63" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="107.38" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="124.28" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="18.3" r="1.44"/>
            <circle className="cls-1" cx="103.1" cy="18.3" r="1.44"/>
            <circle className="cls-1" cx="1.45" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="5.73" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="9.88" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="14.05" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="18.34" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="22.63" cy="35.21" r="1.44"/>
            <circle className="cls-1" cx="9.74" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="13.91" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="18.2" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="22.49" cy="31.29" r="1.44"/>
            <circle className="cls-1" cx="18.2" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="22.49" cy="26.96" r="1.44"/>
            <circle className="cls-1" cx="22.49" cy="22.63" r="1.44"/>
            <circle className="cls-1" cx="1.44" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="5.59" cy="43.87" r="1.44"/>
            <circle className="cls-1" cx="1.45" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="5.59" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="9.77" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="14.05" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="18.34" cy="39.54" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="386.49" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="356.83" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="386.48" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="386.49" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="356.83" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="386.48" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="356.69" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="360.86" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="365.15" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="369.44" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="373.59" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="377.76" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="382.05" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="403.36" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="407.51" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="411.68" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="415.97" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="420.26" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="424.65" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="428.82" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="433.11" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="437.4" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="441.55" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="445.72" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="450.01" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="454.3" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="356.69" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="360.86" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="365.15" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="369.44" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="373.59" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="377.76" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="382.05" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="403.36" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="407.51" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="411.68" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="415.97" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="420.26" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="424.65" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="428.82" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="433.11" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="437.4" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="441.55" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="445.72" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="450.01" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="454.3" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="356.69" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="360.86" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="365.15" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="369.44" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="373.59" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="377.76" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="382.05" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="403.36" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="407.51" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="411.68" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="415.97" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="420.26" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="424.65" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="428.82" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="433.11" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="437.4" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="441.55" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="445.72" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="450.01" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="454.3" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="356.69" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="360.86" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="365.15" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="369.44" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="373.59" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="377.76" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="382.05" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="403.36" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="407.51" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="411.68" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="415.97" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="420.26" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="424.65" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="428.82" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="433.11" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="437.4" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="441.55" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="445.72" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="450.01" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="454.3" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="386.49" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="356.83" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="386.48" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="386.49" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="356.83" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="386.48" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="356.69" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="360.86" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="365.15" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="369.44" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="373.59" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="377.76" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="382.05" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="403.36" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="407.51" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="411.68" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="415.97" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="420.26" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="424.65" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="428.82" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="433.11" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="437.4" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="441.55" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="445.72" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="450.01" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="454.3" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="356.69" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="360.86" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="365.15" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="369.44" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="373.59" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="377.76" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="382.05" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="403.36" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="407.51" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="411.68" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="415.97" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="420.26" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="424.65" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="428.82" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="433.11" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="437.4" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="441.55" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="445.72" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="450.01" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="454.3" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="356.69" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="360.86" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="365.15" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="369.44" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="373.59" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="377.76" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="382.05" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="403.36" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="407.51" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="411.68" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="415.97" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="420.26" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="424.65" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="428.82" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="433.11" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="437.4" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="441.55" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="445.72" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="450.01" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="454.3" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="356.69" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="360.86" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="365.15" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="369.44" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="373.59" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="377.76" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="382.05" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="403.36" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="407.51" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="411.68" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="415.97" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="420.26" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="424.65" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="428.82" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="433.11" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="450.01" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="454.3" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="128.24" cy="69.34" r="1.44"/>
            <circle className="cls-1" cx="132.41" cy="69.34" r="1.44"/>
            <circle className="cls-1" cx="136.7" cy="69.34" r="1.44"/>
            <circle className="cls-1" cx="140.99" cy="69.34" r="1.44"/>
            <circle className="cls-1" cx="128.24" cy="65.01" r="1.44"/>
            <circle className="cls-1" cx="132.41" cy="65.01" r="1.44"/>
            <circle className="cls-1" cx="136.7" cy="65.01" r="1.44"/>
            <circle className="cls-1" cx="153.6" cy="65.01" r="1.44"/>
            <circle className="cls-1" cx="157.89" cy="65.01" r="1.44"/>
            <circle className="cls-1" cx="128.24" cy="60.68" r="1.44"/>
            <circle className="cls-1" cx="132.41" cy="60.68" r="1.44"/>
            <circle className="cls-1" cx="136.7" cy="60.68" r="1.44"/>
            <circle className="cls-1" cx="153.6" cy="60.68" r="1.44"/>
            <circle className="cls-1" cx="128.24" cy="56.35" r="1.44"/>
            <circle className="cls-1" cx="132.41" cy="56.35" r="1.44"/>
            <circle className="cls-1" cx="136.7" cy="56.35" r="1.44"/>
            <circle className="cls-1" cx="140.99" cy="56.35" r="1.44"/>
            <circle className="cls-1" cx="145.14" cy="56.35" r="1.44"/>
            <circle className="cls-1" cx="149.31" cy="56.35" r="1.44"/>
            <circle className="cls-1" cx="153.6" cy="56.35" r="1.44"/>
            <circle className="cls-1" cx="157.89" cy="56.35" r="1.44"/>
            <circle className="cls-1" cx="128.09" cy="52.43" r="1.44"/>
            <circle className="cls-1" cx="132.26" cy="52.43" r="1.44"/>
            <circle className="cls-1" cx="136.55" cy="52.43" r="1.44"/>
            <circle className="cls-1" cx="140.84" cy="52.43" r="1.44"/>
            <circle className="cls-1" cx="144.99" cy="52.43" r="1.44"/>
            <circle className="cls-1" cx="149.16" cy="52.43" r="1.44"/>
            <circle className="cls-1" cx="153.45" cy="52.43" r="1.44"/>
            <circle className="cls-1" cx="157.74" cy="52.43" r="1.44"/>
            <circle className="cls-1" cx="132.26" cy="48.1" r="1.44"/>
            <circle className="cls-1" cx="136.55" cy="48.1" r="1.44"/>
            <circle className="cls-1" cx="140.84" cy="48.1" r="1.44"/>
            <circle className="cls-1" cx="144.99" cy="48.1" r="1.44"/>
            <circle className="cls-1" cx="149.16" cy="48.1" r="1.44"/>
            <circle className="cls-1" cx="132.26" cy="43.77" r="1.44"/>
            <circle className="cls-1" cx="136.55" cy="43.77" r="1.44"/>
            <circle className="cls-1" cx="140.84" cy="43.77" r="1.44"/>
            <circle className="cls-1" cx="144.99" cy="43.77" r="1.44"/>
            <circle className="cls-1" cx="149.16" cy="43.77" r="1.44"/>
            <circle className="cls-1" cx="136.55" cy="39.44" r="1.44"/>
            <circle className="cls-1" cx="140.84" cy="39.44" r="1.44"/>
            <circle className="cls-1" cx="144.99" cy="39.44" r="1.44"/>
            <circle className="cls-1" cx="149.16" cy="39.44" r="1.44"/>
            <circle className="cls-1" cx="183.08" cy="39.44" r="1.44"/>
            <circle className="cls-1" cx="145.14" cy="35.11" r="1.44"/>
            <circle className="cls-1" cx="149.31" cy="35.11" r="1.44"/>
            <circle className="cls-1" cx="179.06" cy="35.11" r="1.44"/>
            <circle className="cls-1" cx="183.23" cy="35.11" r="1.44"/>
            <circle className="cls-1" cx="187.52" cy="35.11" r="1.44"/>
            <circle className="cls-1" cx="145.14" cy="30.78" r="1.44"/>
            <circle className="cls-1" cx="149.31" cy="30.78" r="1.44"/>
            <circle className="cls-1" cx="153.6" cy="30.78" r="1.44"/>
            <circle className="cls-1" cx="179.06" cy="30.78" r="1.44"/>
            <circle className="cls-1" cx="183.23" cy="30.78" r="1.44"/>
            <circle className="cls-1" cx="187.52" cy="30.78" r="1.44"/>
            <circle className="cls-1" cx="191.81" cy="30.78" r="1.44"/>
            <circle className="cls-1" cx="132.41" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="153.6" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="157.89" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="179.06" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="183.23" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="187.52" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="191.81" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="196.2" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="200.37" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="204.66" cy="26.45" r="1.44"/>
            <circle className="cls-1" cx="149.31" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="153.6" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="157.89" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="179.06" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="183.23" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="187.52" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="191.81" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="196.2" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="200.37" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="204.66" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="208.95" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="213.1" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="217.27" cy="22.12" r="1.44"/>
            <circle className="cls-1" cx="140.84" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="144.99" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="149.16" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="178.91" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="183.08" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="187.37" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="191.66" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="196.05" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="200.23" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="204.51" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="208.8" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="212.95" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="217.12" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="221.41" cy="18.2" r="1.44"/>
            <circle className="cls-1" cx="178.91" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="183.08" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="187.37" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="191.66" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="196.05" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="200.23" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="204.51" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="208.8" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="212.95" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="217.12" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="221.41" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="225.7" cy="13.87" r="1.44"/>
            <circle className="cls-1" cx="153.45" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="166.18" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="170.47" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="174.76" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="178.91" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="183.08" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="187.37" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="191.66" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="196.05" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="200.23" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="204.51" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="208.8" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="212.95" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="217.12" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="221.41" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="225.7" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="153.45" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="157.74" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="162.01" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="166.18" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="174.76" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="178.91" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="183.08" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="187.37" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="191.66" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="196.05" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="200.23" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="204.51" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="208.8" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="212.95" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="217.12" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="221.41" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="225.7" cy="5.21" r="1.44"/>
            <path className="cls-1" d="M162,2.89a1.45,1.45,0,1,0-1.44-1.45"/>
            <path className="cls-1" d="M160.57,1.44A1.44,1.44,0,0,0,162,2.89"/>
            <path className="cls-1" d="M166.18,2.89a1.45,1.45,0,1,0-1.44-1.45,1.45,1.45,0,0,0,1.44,1.45"/>
            <path className="cls-1" d="M170.47,2.89A1.45,1.45,0,1,0,169,1.44a1.45,1.45,0,0,0,1.44,1.45"/>
            <path className="cls-1" d="M174.76,2.89a1.45,1.45,0,1,0-1.44-1.45,1.45,1.45,0,0,0,1.44,1.45"/>
            <path className="cls-1" d="M178.91,2.89a1.45,1.45,0,1,0-1.45-1.45,1.44,1.44,0,0,0,1.45,1.45"/>
            <circle className="cls-1" cx="208.8" cy="1.44" r="1.44"/>
            <circle className="cls-1" cx="212.95" cy="1.44" r="1.44"/>
            <circle className="cls-1" cx="217.12" cy="1.44" r="1.44"/>
            <circle className="cls-1" cx="221.41" cy="1.44" r="1.44"/>
            <circle className="cls-1" cx="225.7" cy="1.44" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="479.65" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="505.25" cy="82.29" r="1.44"/>
            <circle className="cls-1" cx="458.57" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="475.47" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="479.65" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="483.94" cy="77.96" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="475.47" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="479.65" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="483.94" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="488.23" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="500.96" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="505.25" cy="73.63" r="1.44"/>
            <circle className="cls-1" cx="458.57" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="475.47" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="479.65" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="483.94" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="488.22" cy="69.3" r="1.44"/>
            <circle className="cls-1" cx="458.43" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="462.6" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="466.89" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="471.18" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="475.33" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="479.5" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="483.79" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="488.08" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="492.35" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="496.52" cy="65.39" r="1.44"/>
            <circle className="cls-1" cx="458.43" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="462.6" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="466.89" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="471.18" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="475.33" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="479.5" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="483.79" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="488.08" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="496.52" cy="61.06" r="1.44"/>
            <circle className="cls-1" cx="458.43" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="462.6" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="466.89" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="471.18" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="475.33" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="479.5" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="483.79" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="488.08" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="492.35" cy="56.73" r="1.44"/>
            <circle className="cls-1" cx="458.43" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="462.6" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="466.89" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="471.18" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="475.33" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="479.5" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="483.79" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="509.25" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="513.42" cy="52.4" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="475.47" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="509.39" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="513.57" cy="48.07" r="1.44"/>
            <circle className="cls-1" cx="458.57" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="475.47" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="505.25" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="509.39" cy="43.74" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="475.47" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="479.65" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="483.94" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="488.23" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="492.49" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="509.39" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="513.57" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="517.86" cy="39.41" r="1.44"/>
            <circle className="cls-1" cx="458.57" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="475.47" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="479.65" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="483.94" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="488.22" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="492.49" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="496.67" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="500.96" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="505.25" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="509.39" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="513.57" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="517.86" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="522.14" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="526.54" cy="35.07" r="1.44"/>
            <circle className="cls-1" cx="458.43" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="462.6" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="466.89" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="471.18" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="475.33" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="479.5" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="483.79" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="488.08" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="492.35" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="496.52" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="500.81" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="505.1" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="509.25" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="513.42" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="517.71" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="530.56" cy="31.16" r="1.44"/>
            <circle className="cls-1" cx="458.43" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="462.6" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="466.89" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="471.18" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="475.33" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="479.5" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="483.79" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="488.08" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="492.35" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="496.52" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="500.81" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="505.1" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="509.25" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="513.42" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="517.71" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="522" cy="26.83" r="1.44"/>
            <circle className="cls-1" cx="458.43" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="462.6" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="466.89" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="471.18" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="475.33" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="479.5" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="483.79" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="488.08" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="492.35" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="496.52" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="500.81" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="505.1" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="509.25" cy="22.5" r="1.44"/>
            <circle className="cls-1" cx="458.43" cy="18.17" r="1.44"/>
            <circle className="cls-1" cx="407.8" cy="150.72" r="1.44"/>
            <circle className="cls-1" cx="446.02" cy="150.72" r="1.44"/>
            <circle className="cls-1" cx="399.36" cy="146.39" r="1.44"/>
            <circle className="cls-1" cx="403.65" cy="146.39" r="1.44"/>
            <circle className="cls-1" cx="454.59" cy="146.39" r="1.44"/>
            <circle className="cls-1" cx="399.36" cy="142.06" r="1.44"/>
            <circle className="cls-1" cx="441.84" cy="142.06" r="1.44"/>
            <circle className="cls-1" cx="450.3" cy="142.06" r="1.44"/>
            <circle className="cls-1" cx="454.59" cy="142.06" r="1.44"/>
            <circle className="cls-1" cx="395.07" cy="137.73" r="1.44"/>
            <circle className="cls-1" cx="399.36" cy="137.73" r="1.44"/>
            <circle className="cls-1" cx="441.84" cy="137.73" r="1.44"/>
            <circle className="cls-1" cx="454.59" cy="137.73" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="133.81" r="1.44"/>
            <circle className="cls-1" cx="441.7" cy="133.81" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="133.81" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="129.48" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="129.48" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="129.48" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="129.48" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="129.48" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="129.48" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="129.48" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="129.48" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="441.7" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="125.15" r="1.44"/>
            <circle className="cls-1" cx="386.49" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="382.34" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="386.63" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="390.9" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="395.08" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="399.36" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="403.65" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="407.8" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="411.97" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="424.94" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="429.12" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="433.41" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="437.69" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="441.84" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="446.02" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="450.3" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="454.59" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="365.44" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="369.73" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="373.88" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="378.05" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="382.34" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="386.63" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="390.9" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="395.07" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="399.36" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="403.65" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="407.8" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="411.97" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="416.26" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="420.55" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="424.94" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="429.12" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="433.41" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="437.69" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="441.84" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="446.02" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="450.3" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="454.59" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="356.98" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="361.16" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="365.44" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="369.73" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="373.88" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="378.05" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="382.34" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="386.63" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="390.9" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="395.08" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="407.8" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="411.97" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="416.26" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="420.55" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="424.94" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="429.12" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="433.41" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="437.69" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="441.84" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="446.02" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="450.3" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="454.59" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="356.98" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="361.16" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="365.44" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="369.73" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="373.88" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="378.05" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="382.34" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="386.63" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="390.9" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="395.07" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="407.8" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="411.97" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="416.26" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="420.55" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="424.94" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="429.12" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="433.41" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="437.69" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="441.84" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="446.02" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="450.3" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="454.59" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="386.49" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="441.7" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="386.49" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="386.49" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="441.7" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="356.84" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="361.01" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="365.3" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="369.59" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="382.2" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="386.49" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="390.75" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="394.93" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="399.22" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="403.51" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="407.65" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="411.83" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="416.12" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="420.4" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="424.8" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="428.97" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="433.26" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="437.55" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="441.69" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="445.87" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="450.16" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="454.45" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="492.64" cy="150.72" r="1.44"/>
            <circle className="cls-1" cx="496.82" cy="150.72" r="1.44"/>
            <circle className="cls-1" cx="479.79" cy="146.39" r="1.44"/>
            <circle className="cls-1" cx="488.37" cy="146.39" r="1.44"/>
            <circle className="cls-1" cx="458.72" cy="142.06" r="1.44"/>
            <circle className="cls-1" cx="462.9" cy="142.06" r="1.44"/>
            <circle className="cls-1" cx="492.64" cy="142.06" r="1.44"/>
            <circle className="cls-1" cx="458.72" cy="137.73" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="133.81" r="1.44"/>
            <circle className="cls-1" cx="483.94" cy="129.48" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="120.82" r="1.44"/>
            <circle className="cls-1" cx="458.72" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="471.47" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="479.79" cy="116.49" r="1.44"/>
            <circle className="cls-1" cx="458.72" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="471.47" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="475.62" cy="112.16" r="1.44"/>
            <circle className="cls-1" cx="458.72" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="462.9" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="467.18" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="471.47" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="475.62" cy="107.83" r="1.44"/>
            <circle className="cls-1" cx="458.72" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="462.9" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="467.18" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="471.47" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="475.62" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="479.79" cy="103.5" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="475.47" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="483.94" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="488.23" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="496.67" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="500.96" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="505.25" cy="90.92" r="1.44"/>
            <circle className="cls-1" cx="458.58" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="462.75" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="467.04" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="471.33" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="479.65" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="483.94" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="505.25" cy="86.59" r="1.44"/>
            <circle className="cls-1" cx="234.32" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="246.78" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="251.07" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="238.32" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="246.78" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="251.07" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="238.32" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="242.49" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="251.07" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="289.27" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="82.46" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="289.26" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="78.13" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="259.52" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="285" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="289.27" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="73.8" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="259.52" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="285" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="289.26" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="318.91" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="69.47" r="1.44"/>
            <circle className="cls-1" cx="255.2" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="259.37" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="263.66" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="267.95" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="272.1" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="276.27" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="280.56" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="284.85" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="289.12" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="293.29" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="318.77" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="323.16" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="327.33" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="331.62" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="335.91" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="340.06" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="344.23" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="348.52" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="352.81" cy="65.56" r="1.44"/>
            <circle className="cls-1" cx="255.2" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="267.95" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="272.1" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="276.27" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="280.56" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="284.85" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="289.12" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="293.29" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="318.77" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="323.16" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="327.33" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="331.62" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="335.91" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="340.06" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="344.23" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="348.52" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="352.81" cy="61.23" r="1.44"/>
            <circle className="cls-1" cx="267.95" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="272.1" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="276.27" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="280.56" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="284.85" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="289.12" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="293.29" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="318.77" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="323.16" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="327.33" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="331.62" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="335.91" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="340.06" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="344.23" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="348.52" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="352.81" cy="56.89" r="1.44"/>
            <circle className="cls-1" cx="267.95" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="272.1" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="276.27" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="280.56" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="284.85" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="289.12" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="293.29" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="318.77" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="323.16" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="327.33" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="331.62" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="335.91" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="340.06" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="344.23" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="348.52" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="352.81" cy="52.56" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="289.27" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="48.23" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="318.91" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="43.9" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="289.27" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="39.57" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="289.26" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="318.91" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="35.24" r="1.44"/>
            <circle className="cls-1" cx="272.1" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="276.27" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="280.56" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="284.85" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="289.12" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="293.29" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="318.77" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="323.16" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="327.33" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="331.62" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="335.91" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="340.06" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="344.23" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="348.52" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="352.81" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="272.1" cy="27" r="1.44"/>
            <circle className="cls-1" cx="276.27" cy="27" r="1.44"/>
            <circle className="cls-1" cx="280.56" cy="27" r="1.44"/>
            <circle className="cls-1" cx="284.85" cy="27" r="1.44"/>
            <circle className="cls-1" cx="289.12" cy="27" r="1.44"/>
            <circle className="cls-1" cx="293.29" cy="27" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="27" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="27" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="27" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="27" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="27" r="1.44"/>
            <circle className="cls-1" cx="318.77" cy="27" r="1.44"/>
            <circle className="cls-1" cx="323.16" cy="27" r="1.44"/>
            <circle className="cls-1" cx="327.33" cy="27" r="1.44"/>
            <circle className="cls-1" cx="331.62" cy="27" r="1.44"/>
            <circle className="cls-1" cx="335.91" cy="27" r="1.44"/>
            <circle className="cls-1" cx="340.06" cy="27" r="1.44"/>
            <circle className="cls-1" cx="344.23" cy="27" r="1.44"/>
            <circle className="cls-1" cx="348.52" cy="27" r="1.44"/>
            <circle className="cls-1" cx="352.81" cy="27" r="1.44"/>
            <circle className="cls-1" cx="280.56" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="284.85" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="289.12" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="293.29" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="340.06" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="344.23" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="352.81" cy="22.67" r="1.44"/>
            <circle className="cls-1" cx="331.62" cy="18.34" r="1.44"/>
            <circle className="cls-1" cx="348.52" cy="18.34" r="1.44"/>
            <circle className="cls-1" cx="352.81" cy="18.34" r="1.44"/>
            <circle className="cls-1" cx="234.47" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="238.61" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="242.79" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="247.08" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="234.46" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="238.61" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="242.79" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="247.07" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="234.47" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="238.61" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="242.79" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="247.08" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="251.36" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="234.46" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="238.61" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="242.79" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="247.07" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="251.36" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="234.32" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="234.32" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="234.32" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="234.32" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="234.47" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="238.61" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="242.79" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="247.08" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="251.36" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="234.46" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="238.61" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="242.79" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="247.07" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="251.36" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="234.47" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="238.61" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="242.79" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="247.08" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="251.36" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="234.46" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="238.61" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="242.79" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="247.07" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="251.36" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="234.32" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="251.22" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="238.47" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="242.64" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="246.93" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="259.67" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="263.96" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="268.24" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="272.39" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="276.57" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="285.14" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="289.41" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="293.59" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="319.06" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="327.63" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="331.92" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="336.21" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="340.35" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="344.53" cy="150.88" r="1.44"/>
            <circle className="cls-1" cx="259.67" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="263.95" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="268.24" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="272.39" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="276.56" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="285.14" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="289.41" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="293.59" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="319.06" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="327.63" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="331.92" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="336.2" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="340.35" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="344.53" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="348.81" cy="146.55" r="1.44"/>
            <circle className="cls-1" cx="255.49" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="259.67" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="263.96" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="268.24" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="272.39" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="276.57" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="285.14" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="289.41" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="293.59" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="319.06" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="142.22" r="1.44"/>
            <circle className="cls-1" cx="255.49" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="259.67" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="263.95" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="268.24" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="272.39" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="276.56" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="285.14" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="289.41" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="293.59" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="319.06" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="336.2" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="340.35" cy="137.89" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="259.52" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="285" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="289.27" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="133.98" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="259.52" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="285" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="289.27" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="129.65" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="259.52" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="285" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="289.27" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="125.32" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="259.52" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="285" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="289.27" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="120.99" r="1.44"/>
            <circle className="cls-1" cx="255.49" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="259.67" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="263.96" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="268.24" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="272.39" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="276.57" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="285.14" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="289.41" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="293.59" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="327.63" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="331.92" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="336.21" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="340.35" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="344.53" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="348.82" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="353.1" cy="116.66" r="1.44"/>
            <circle className="cls-1" cx="255.49" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="259.67" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="263.95" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="268.24" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="272.39" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="276.56" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="285.14" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="289.41" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="293.59" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="327.63" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="331.92" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="336.2" cy="112.33" r="1.44"/>
            <circle className="cls-1" cx="255.49" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="259.67" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="263.96" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="268.24" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="272.39" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="276.57" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="285.14" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="289.41" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="293.59" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="319.06" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="327.63" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="331.92" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="336.21" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="348.82" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="353.1" cy="107.99" r="1.44"/>
            <circle className="cls-1" cx="255.49" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="259.67" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="263.95" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="268.24" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="272.39" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="276.56" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="285.14" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="289.41" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="293.59" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="319.06" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="327.63" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="331.92" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="336.2" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="340.35" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="348.81" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="353.1" cy="103.66" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="259.52" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="272.24" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="289.27" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="99.75" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="259.52" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="95.42" r="1.44"/>
            <circle className="cls-1" cx="255.35" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="259.52" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="263.81" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="268.1" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="91.09" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="293.44" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="318.92" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="331.77" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="340.21" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="344.38" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="348.67" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="352.96" cy="86.76" r="1.44"/>
            <circle className="cls-1" cx="234.41" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="238.7" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="272.48" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="276.75" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="280.92" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="276.75" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="280.92" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="276.75" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="280.92" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="276.75" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="280.92" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="276.6" cy="184.93" r="1.44"/>
            <circle className="cls-1" cx="280.77" cy="184.93" r="1.44"/>
            <circle className="cls-1" cx="285.06" cy="184.93" r="1.44"/>
            <circle className="cls-1" cx="289.35" cy="184.93" r="1.44"/>
            <circle className="cls-1" cx="293.5" cy="184.93" r="1.44"/>
            <circle className="cls-1" cx="276.6" cy="180.6" r="1.44"/>
            <circle className="cls-1" cx="280.77" cy="180.6" r="1.44"/>
            <circle className="cls-1" cx="285.06" cy="180.6" r="1.44"/>
            <circle className="cls-1" cx="289.35" cy="180.6" r="1.44"/>
            <circle className="cls-1" cx="293.5" cy="180.6" r="1.44"/>
            <circle className="cls-1" cx="272.33" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="276.6" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="280.77" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="285.06" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="289.35" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="293.5" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="268.04" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="272.33" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="276.6" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="280.77" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="285.06" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="289.35" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="293.5" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="268.19" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="272.48" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="276.75" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="280.92" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="272.48" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="276.75" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="280.92" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="268.19" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="272.48" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="276.75" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="280.92" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="242.83" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="247" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="259.73" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="263.9" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="268.19" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="272.48" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="276.75" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="280.92" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="297.88" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="297.88" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="176.27" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="297.88" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="297.87" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="302.16" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="306.31" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="310.48" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="314.77" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="236.06" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="236.06" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="231.73" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="231.73" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="231.73" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="227.4" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="227.4" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="227.4" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="227.4" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="223.07" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="223.07" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="223.07" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="223.07" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="297.58" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="301.87" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="306.02" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="310.19" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="314.48" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="302.02" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="306.16" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="310.34" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="314.63" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="240.39" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="240.39" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="240.39" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="236.06" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="236.06" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="236.06" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="231.73" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="231.73" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="231.73" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="227.4" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="227.4" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="227.4" r="1.44"/>
            <circle className="cls-1" cx="285.06" cy="223.49" r="1.44"/>
            <circle className="cls-1" cx="289.35" cy="223.49" r="1.44"/>
            <circle className="cls-1" cx="293.5" cy="223.49" r="1.44"/>
            <circle className="cls-1" cx="285.06" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="289.35" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="293.5" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="285.06" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="289.35" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="293.5" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="285.06" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="289.35" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="293.5" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="285.21" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="289.5" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="293.65" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="297.73" cy="240.39" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="227.4" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="223.07" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="218.74" r="1.44"/>
            <circle className="cls-1" cx="280.85" cy="214.41" r="1.44"/>
            <circle className="cls-1" cx="276.53" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="276.53" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="319.31" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="323.6" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="319.31" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="323.6" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="319.31" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="323.6" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="319.31" cy="175.85" r="1.44"/>
            <circle className="cls-1" cx="323.6" cy="175.85" r="1.44"/>
            <circle className="cls-1" cx="319.17" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="319.16" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="319.17" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="319.16" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="319.31" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="323.6" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="319.02" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="319.02" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="323.31" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="319.16" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="319.16" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="323.45" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="327.92" cy="167.6" r="1.44"/>
            <circle className="cls-1" cx="332.2" cy="167.19" r="1.44"/>
            <circle className="cls-1" cx="327.77" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="332.06" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="336.21" cy="163.27" r="1.44"/>
            <circle className="cls-1" cx="327.77" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="332.06" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="336.2" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="340.38" cy="158.94" r="1.44"/>
            <circle className="cls-1" cx="327.77" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="332.06" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="336.21" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="340.38" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="344.67" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="327.92" cy="171.94" r="1.44"/>
            <circle className="cls-1" cx="230.09" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="225.92" cy="150.28" r="1.44"/>
            <circle className="cls-1" cx="230.09" cy="150.28" r="1.44"/>
            <circle className="cls-1" cx="225.77" cy="146.37" r="1.44"/>
            <circle className="cls-1" cx="229.95" cy="146.37" r="1.44"/>
            <circle className="cls-1" cx="221.63" cy="142.04" r="1.44"/>
            <circle className="cls-1" cx="225.77" cy="142.04" r="1.44"/>
            <circle className="cls-1" cx="229.95" cy="142.04" r="1.44"/>
            <circle className="cls-1" cx="221.63" cy="137.71" r="1.44"/>
            <circle className="cls-1" cx="225.77" cy="137.71" r="1.44"/>
            <circle className="cls-1" cx="229.95" cy="137.71" r="1.44"/>
            <circle className="cls-1" cx="221.63" cy="133.38" r="1.44"/>
            <circle className="cls-1" cx="225.77" cy="133.38" r="1.44"/>
            <circle className="cls-1" cx="229.95" cy="133.38" r="1.44"/>
            <circle className="cls-1" cx="221.77" cy="129.05" r="1.44"/>
            <circle className="cls-1" cx="225.92" cy="129.05" r="1.44"/>
            <circle className="cls-1" cx="230.09" cy="129.05" r="1.44"/>
            <circle className="cls-1" cx="221.77" cy="124.72" r="1.44"/>
            <circle className="cls-1" cx="225.92" cy="124.72" r="1.44"/>
            <circle className="cls-1" cx="230.09" cy="124.72" r="1.44"/>
            <circle className="cls-1" cx="221.77" cy="120.38" r="1.44"/>
            <circle className="cls-1" cx="225.92" cy="120.38" r="1.44"/>
            <circle className="cls-1" cx="230.09" cy="120.38" r="1.44"/>
            <circle className="cls-1" cx="221.77" cy="116.05" r="1.44"/>
            <circle className="cls-1" cx="225.92" cy="116.05" r="1.44"/>
            <circle className="cls-1" cx="230.09" cy="116.05" r="1.44"/>
            <circle className="cls-1" cx="217.11" cy="133.38" r="1.44"/>
            <circle className="cls-1" cx="225.77" cy="112.14" r="1.44"/>
            <circle className="cls-1" cx="229.95" cy="112.14" r="1.44"/>
            <circle className="cls-1" cx="225.77" cy="107.81" r="1.44"/>
            <circle className="cls-1" cx="229.95" cy="107.81" r="1.44"/>
            <circle className="cls-1" cx="441.84" cy="146.39" r="1.44"/>
            <circle className="cls-1" cx="221.82" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="225.97" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="230.14" cy="31.33" r="1.44"/>
            <circle className="cls-1" cx="229.99" cy="9.54" r="1.44"/>
            <circle className="cls-1" cx="229.99" cy="5.21" r="1.44"/>
            <circle className="cls-1" cx="229.99" cy="1.44" r="1.44"/>
            <circle className="cls-1" cx="327.48" cy="188.84" r="1.44"/>
            <circle className="cls-1" cx="327.19" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="327.33" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="327.33" cy="193.17" r="1.44"/>
            <circle className="cls-1" cx="336.06" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="335.76" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="335.91" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="335.91" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="339.94" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="339.64" cy="219.16" r="1.44"/>
            <circle className="cls-1" cx="339.79" cy="214.83" r="1.44"/>
            <circle className="cls-1" cx="339.79" cy="210.49" r="1.44"/>
            <circle className="cls-1" cx="339.92" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="343.95" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="343.8" cy="206.16" r="1.44"/>
            <circle className="cls-1" cx="343.8" cy="201.83" r="1.44"/>
            <circle className="cls-1" cx="450.01" cy="13.8" r="1.44"/>
            <circle className="cls-1" cx="373.59" cy="14.49" r="1.44"/>
            <circle className="cls-1" cx="377.76" cy="14.49" r="1.44"/>
            <circle className="cls-1" cx="382.05" cy="14.49" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="14.49" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="14.49" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="14.49" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="14.49" r="1.44"/>
            <circle className="cls-1" cx="386.34" cy="10.16" r="1.44"/>
            <circle className="cls-1" cx="390.61" cy="10.16" r="1.44"/>
            <circle className="cls-1" cx="394.78" cy="10.16" r="1.44"/>
            <circle className="cls-1" cx="399.07" cy="10.16" r="1.44"/>
            <circle className="cls-1" cx="369.46" cy="6.12" r="1.44"/>
            <circle className="cls-1" cx="373.73" cy="6.12" r="1.44"/>
            <circle className="cls-1" cx="377.91" cy="6.12" r="1.44"/>
            <circle className="cls-1" cx="331.62" cy="14.49" r="1.44"/>
            <circle className="cls-1" cx="335.77" cy="14.49" r="1.44"/>
            <circle className="cls-1" cx="276.42" cy="10.16" r="1.44"/>
            <circle className="cls-1" cx="280.71" cy="10.16" r="1.44"/>
            <circle className="cls-1" cx="285" cy="10.16" r="1.44"/>
            <circle className="cls-1" cx="289.14" cy="10.16" r="1.44"/>
            <circle className="cls-1" cx="285" cy="6.12" r="1.44"/>
            <circle className="cls-1" cx="289.14" cy="6.12" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="108.24" r="1.44"/>
            <circle className="cls-1" cx="43.6" cy="108.24" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="108.24" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="108.24" r="1.44"/>
            <circle className="cls-1" cx="56.45" cy="108.24" r="1.44"/>
            <circle className="cls-1" cx="60.62" cy="108.24" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="108.24" r="1.44"/>
            <circle className="cls-1" cx="94.66" cy="108.24" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="39.43" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="43.6" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="56.45" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="60.62" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="73.35" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="77.52" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="94.66" cy="103.91" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="39.43" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="43.6" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="56.45" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="60.62" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="73.35" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="77.52" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="90.49" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="94.66" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="99.58" r="1.44"/>
            <circle className="cls-1" cx="30.99" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="35.28" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="39.43" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="43.6" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="56.45" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="60.62" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="64.91" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="69.2" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="73.35" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="77.52" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="90.49" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="94.66" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="98.95" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="95.25" r="1.44"/>
            <circle className="cls-1" cx="39.28" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="43.46" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="47.74" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="52.03" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="56.3" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="60.48" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="64.77" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="69.05" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="73.2" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="77.38" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="81.66" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="85.95" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="90.34" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="94.52" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="103.1" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="107.24" cy="91.34" r="1.44"/>
            <circle className="cls-1" cx="39.28" cy="87" r="1.44"/>
            <circle className="cls-1" cx="43.46" cy="87" r="1.44"/>
            <circle className="cls-1" cx="47.74" cy="87" r="1.44"/>
            <circle className="cls-1" cx="52.03" cy="87" r="1.44"/>
            <circle className="cls-1" cx="56.3" cy="87" r="1.44"/>
            <circle className="cls-1" cx="60.48" cy="87" r="1.44"/>
            <circle className="cls-1" cx="64.76" cy="87" r="1.44"/>
            <circle className="cls-1" cx="69.05" cy="87" r="1.44"/>
            <circle className="cls-1" cx="73.2" cy="87" r="1.44"/>
            <circle className="cls-1" cx="77.37" cy="87" r="1.44"/>
            <circle className="cls-1" cx="81.66" cy="87" r="1.44"/>
            <circle className="cls-1" cx="85.95" cy="87" r="1.44"/>
            <circle className="cls-1" cx="90.34" cy="87" r="1.44"/>
            <circle className="cls-1" cx="94.52" cy="87" r="1.44"/>
            <circle className="cls-1" cx="98.81" cy="87" r="1.44"/>
            <circle className="cls-1" cx="103.24" cy="87" r="1.44"/>
            <circle className="cls-1" cx="107.53" cy="87" r="1.44"/>
            <circle className="cls-1" cx="94.57" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="98.86" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="103.15" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="107.54" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="111.71" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="116" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="124.44" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="128.61" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="94.57" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="98.86" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="103.15" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="107.54" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="111.71" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="116" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="124.44" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="128.61" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="94.57" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="98.86" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="103.15" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="107.54" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="111.71" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="116" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="124.44" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="128.61" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="98.86" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="103.15" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="107.54" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="111.71" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="116" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="124.44" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="128.61" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="98.71" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="103" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="107.39" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="111.56" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="115.85" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="120.14" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="124.29" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="128.46" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="132.75" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="137.04" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="90.25" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="94.42" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="98.71" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="103" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="107.39" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="111.56" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="115.85" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="120.14" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="124.29" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="128.46" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="132.75" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="137.04" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="146.06" r="1.44"/>
            <circle className="cls-1" cx="98.71" cy="146.06" r="1.44"/>
            <circle className="cls-1" cx="103" cy="146.06" r="1.44"/>
            <circle className="cls-1" cx="107.39" cy="146.06" r="1.44"/>
            <circle className="cls-1" cx="111.56" cy="146.06" r="1.44"/>
            <circle className="cls-1" cx="115.85" cy="146.06" r="1.44"/>
            <circle className="cls-1" cx="120.14" cy="146.06" r="1.44"/>
            <circle className="cls-1" cx="124.29" cy="146.06" r="1.44"/>
            <circle className="cls-1" cx="128.46" cy="146.06" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="141.73" r="1.44"/>
            <circle className="cls-1" cx="86.1" cy="141.73" r="1.44"/>
            <circle className="cls-1" cx="107.39" cy="141.73" r="1.44"/>
            <circle className="cls-1" cx="111.56" cy="141.73" r="1.44"/>
            <circle className="cls-1" cx="115.85" cy="141.73" r="1.44"/>
            <circle className="cls-1" cx="73.5" cy="137.39" r="1.44"/>
            <circle className="cls-1" cx="77.67" cy="137.39" r="1.44"/>
            <circle className="cls-1" cx="81.96" cy="137.39" r="1.44"/>
            <circle className="cls-1" cx="86.25" cy="137.39" r="1.44"/>
            <circle className="cls-1" cx="69.23" cy="133.06" r="1.44"/>
            <circle className="cls-1" cx="73.5" cy="133.06" r="1.44"/>
            <circle className="cls-1" cx="77.67" cy="133.06" r="1.44"/>
            <circle className="cls-1" cx="81.96" cy="133.06" r="1.44"/>
            <circle className="cls-1" cx="86.25" cy="133.06" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="128.73" r="1.44"/>
            <circle className="cls-1" cx="60.65" cy="128.73" r="1.44"/>
            <circle className="cls-1" cx="64.94" cy="128.73" r="1.44"/>
            <circle className="cls-1" cx="69.23" cy="128.73" r="1.44"/>
            <circle className="cls-1" cx="73.5" cy="128.73" r="1.44"/>
            <circle className="cls-1" cx="77.67" cy="128.73" r="1.44"/>
            <circle className="cls-1" cx="48.04" cy="124.4" r="1.44"/>
            <circle className="cls-1" cx="52.33" cy="124.4" r="1.44"/>
            <circle className="cls-1" cx="56.47" cy="124.4" r="1.44"/>
            <circle className="cls-1" cx="60.65" cy="124.4" r="1.44"/>
            <circle className="cls-1" cx="64.94" cy="124.4" r="1.44"/>
            <circle className="cls-1" cx="77.67" cy="124.4" r="1.44"/>
            <circle className="cls-1" cx="81.96" cy="124.4" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="120.49" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="120.49" r="1.44"/>
            <circle className="cls-1" cx="56.33" cy="120.49" r="1.44"/>
            <circle className="cls-1" cx="60.5" cy="120.49" r="1.44"/>
            <circle className="cls-1" cx="81.81" cy="120.49" r="1.44"/>
            <circle className="cls-1" cx="98.71" cy="120.49" r="1.44"/>
            <circle className="cls-1" cx="103" cy="120.49" r="1.44"/>
            <circle className="cls-1" cx="39.43" cy="116.16" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="116.16" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="116.16" r="1.44"/>
            <circle className="cls-1" cx="56.33" cy="116.16" r="1.44"/>
            <circle className="cls-1" cx="60.5" cy="116.16" r="1.44"/>
            <circle className="cls-1" cx="47.89" cy="111.83" r="1.44"/>
            <circle className="cls-1" cx="52.18" cy="111.83" r="1.44"/>
            <circle className="cls-1" cx="56.33" cy="111.83" r="1.44"/>
            <circle className="cls-1" cx="60.5" cy="111.83" r="1.44"/>
            <circle className="cls-1" cx="141.32" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="145.49" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="171.62" r="1.44"/>
            <circle className="cls-1" cx="141.32" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="145.49" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="167.29" r="1.44"/>
            <circle className="cls-1" cx="141.32" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="145.49" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="162.96" r="1.44"/>
            <circle className="cls-1" cx="141.32" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="145.49" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="158.63" r="1.44"/>
            <circle className="cls-1" cx="141.17" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="145.34" cy="154.72" r="1.44"/>
            <circle className="cls-1" cx="141.17" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="145.34" cy="150.39" r="1.44"/>
            <circle className="cls-1" cx="98.86" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="103.15" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="107.54" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="111.71" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="116" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="124.44" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="128.61" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="98.86" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="103.15" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="107.54" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="111.71" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="116" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="124.44" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="128.61" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="94.57" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="98.86" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="103.15" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="107.54" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="111.71" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="116" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="124.44" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="128.61" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="94.57" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="98.86" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="103.15" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="107.54" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="111.71" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="116" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="124.44" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="128.61" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="141.32" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="145.49" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="188.91" r="1.44"/>
            <circle className="cls-1" cx="141.32" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="145.49" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="184.58" r="1.44"/>
            <circle className="cls-1" cx="141.32" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="145.49" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="180.25" r="1.44"/>
            <circle className="cls-1" cx="141.32" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="145.49" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="175.92" r="1.44"/>
            <circle className="cls-1" cx="43.72" cy="111.83" r="1.44"/>
            <circle className="cls-1" cx="116" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="128.73" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="116" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="128.73" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="116" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="128.73" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="116" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="128.73" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="145.61" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="158.36" cy="206.11" r="1.44"/>
            <circle className="cls-1" cx="145.61" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="158.36" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="145.61" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="145.61" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="116" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="128.73" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="116" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="128.73" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="116" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="128.73" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="116" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="128.73" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="132.9" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="145.61" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="158.36" cy="223.4" r="1.44"/>
            <circle className="cls-1" cx="145.61" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="158.36" cy="219.07" r="1.44"/>
            <circle className="cls-1" cx="145.61" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="158.36" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="145.61" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="149.78" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="158.36" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="235.88" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="235.88" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="235.88" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="235.88" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="235.88" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="235.88" r="1.44"/>
            <circle className="cls-1" cx="145.76" cy="235.88" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="231.55" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="231.55" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="231.55" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="231.55" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="231.55" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="231.55" r="1.44"/>
            <circle className="cls-1" cx="145.76" cy="231.55" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="145.76" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="149.89" cy="235.88" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="235.88" r="1.44"/>
            <circle className="cls-1" cx="149.89" cy="231.55" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="231.55" r="1.44"/>
            <circle className="cls-1" cx="149.89" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="154.07" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="158.36" cy="227.21" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="253.16" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="253.16" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="253.16" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="253.16" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="253.16" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="248.83" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="248.83" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="248.83" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="248.83" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="248.83" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="248.83" r="1.44"/>
            <circle className="cls-1" cx="145.76" cy="248.83" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="244.5" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="244.5" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="244.5" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="244.5" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="244.5" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="244.5" r="1.44"/>
            <circle className="cls-1" cx="145.76" cy="244.5" r="1.44"/>
            <circle className="cls-1" cx="120.29" cy="240.17" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="240.17" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="240.17" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="240.17" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="240.17" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="240.17" r="1.44"/>
            <circle className="cls-1" cx="145.76" cy="240.17" r="1.44"/>
            <circle className="cls-1" cx="149.89" cy="240.17" r="1.44"/>
            <circle className="cls-1" cx="107.17" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="111.56" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="102.88" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="107.17" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="111.56" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="102.88" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="107.17" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="111.56" cy="193.12" r="1.44"/>
            <circle className="cls-1" cx="116.2" cy="231.66" r="1.44"/>
            <circle className="cls-1" cx="116.2" cy="227.33" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="261.51" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="261.51" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="261.51" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="257.18" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="257.18" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="257.18" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="278.79" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="274.46" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="274.46" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="270.13" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="270.13" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="270.13" r="1.44"/>
            <circle className="cls-1" cx="124.58" cy="265.8" r="1.44"/>
            <circle className="cls-1" cx="128.87" cy="265.8" r="1.44"/>
            <circle className="cls-1" cx="133.01" cy="265.8" r="1.44"/>
            <circle className="cls-1" cx="137.19" cy="270.13" r="1.44"/>
            <circle className="cls-1" cx="137.3" cy="282.52" r="1.44"/>
            <circle className="cls-1" cx="141.48" cy="282.52" r="1.44"/>
            <circle className="cls-1" cx="149.89" cy="278.79" r="1.44"/>
            <circle className="cls-1" cx="158.03" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="158.03" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="158.03" cy="175.85" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="175.85" r="1.44"/>
            <circle className="cls-1" cx="158.03" cy="171.52" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="171.52" r="1.44"/>
            <circle className="cls-1" cx="166.45" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="170.62" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="174.91" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="179.2" cy="184.51" r="1.44"/>
            <circle className="cls-1" cx="166.45" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="170.62" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="174.91" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="179.2" cy="180.18" r="1.44"/>
            <circle className="cls-1" cx="166.45" cy="175.85" r="1.44"/>
            <circle className="cls-1" cx="170.62" cy="175.85" r="1.44"/>
            <circle className="cls-1" cx="174.91" cy="175.85" r="1.44"/>
            <circle className="cls-1" cx="158.03" cy="193.05" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="193.05" r="1.44"/>
            <circle className="cls-1" cx="166.61" cy="193.05" r="1.44"/>
            <circle className="cls-1" cx="158.03" cy="188.72" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="188.72" r="1.44"/>
            <circle className="cls-1" cx="166.61" cy="188.72" r="1.44"/>
            <circle className="cls-1" cx="170.74" cy="193.05" r="1.44"/>
            <circle className="cls-1" cx="174.91" cy="193.05" r="1.44"/>
            <circle className="cls-1" cx="170.74" cy="188.72" r="1.44"/>
            <circle className="cls-1" cx="174.91" cy="188.72" r="1.44"/>
            <circle className="cls-1" cx="179.2" cy="188.72" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="166.61" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="166.61" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="170.74" cy="201.78" r="1.44"/>
            <circle className="cls-1" cx="170.74" cy="197.45" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="166.61" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="166.61" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="162.32" cy="206.08" r="1.44"/>
            <circle className="cls-1" cx="166.61" cy="206.08" r="1.44"/>
            <circle className="cls-1" cx="170.74" cy="214.74" r="1.44"/>
            <circle className="cls-1" cx="170.74" cy="210.41" r="1.44"/>
            <circle className="cls-1" cx="170.74" cy="206.08" r="1.44"/>
            <circle className="cls-1" cx="158.03" cy="197.5" r="1.44"/>
            <circle className="cls-1" cx="445.97" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="450.14" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="479.89" cy="154.61" r="1.44"/>
            <circle className="cls-1" cx="492.79" cy="188.8" r="1.44"/>
            <circle className="cls-1" cx="467.31" cy="184.47" r="1.44"/>
            <circle className="cls-1" cx="471.6" cy="184.47" r="1.44"/>
            <circle className="cls-1" cx="475.89" cy="184.47" r="1.44"/>
            <circle className="cls-1" cx="480.04" cy="184.47" r="1.44"/>
            <circle className="cls-1" cx="488.5" cy="184.47" r="1.44"/>
            <circle className="cls-1" cx="492.79" cy="184.47" r="1.44"/>
            <circle className="cls-1" cx="497.18" cy="184.47" r="1.44"/>
            <circle className="cls-1" cx="458.87" cy="180.14" r="1.44"/>
            <circle className="cls-1" cx="450.29" cy="175.81" r="1.44"/>
            <circle className="cls-1" cx="454.58" cy="175.81" r="1.44"/>
            <circle className="cls-1" cx="484.21" cy="175.81" r="1.44"/>
            <circle className="cls-1" cx="488.5" cy="175.81" r="1.44"/>
            <circle className="cls-1" cx="501.35" cy="175.81" r="1.44"/>
            <circle className="cls-1" cx="505.64" cy="175.81" r="1.44"/>
            <circle className="cls-1" cx="450.14" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="454.43" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="467.17" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="471.45" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="475.74" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="484.06" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="488.35" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="509.78" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="445.97" cy="167.57" r="1.44"/>
            <circle className="cls-1" cx="450.14" cy="167.57" r="1.44"/>
            <circle className="cls-1" cx="467.16" cy="167.57" r="1.44"/>
            <circle className="cls-1" cx="471.45" cy="167.57" r="1.44"/>
            <circle className="cls-1" cx="475.74" cy="167.57" r="1.44"/>
            <circle className="cls-1" cx="479.89" cy="167.57" r="1.44"/>
            <circle className="cls-1" cx="488.35" cy="167.57" r="1.44"/>
            <circle className="cls-1" cx="501.21" cy="167.57" r="1.44"/>
            <circle className="cls-1" cx="441.82" cy="163.24" r="1.44"/>
            <circle className="cls-1" cx="445.97" cy="163.24" r="1.44"/>
            <circle className="cls-1" cx="475.74" cy="163.24" r="1.44"/>
            <circle className="cls-1" cx="479.89" cy="163.24" r="1.44"/>
            <circle className="cls-1" cx="501.21" cy="163.24" r="1.44"/>
            <circle className="cls-1" cx="441.82" cy="158.91" r="1.44"/>
            <circle className="cls-1" cx="475.74" cy="158.91" r="1.44"/>
            <circle className="cls-1" cx="479.89" cy="158.91" r="1.44"/>
            <circle className="cls-1" cx="522.44" cy="184.89" r="1.44"/>
            <circle className="cls-1" cx="526.59" cy="184.89" r="1.44"/>
            <circle className="cls-1" cx="530.76" cy="184.89" r="1.44"/>
            <circle className="cls-1" cx="535.05" cy="184.89" r="1.44"/>
            <circle className="cls-1" cx="539.34" cy="184.89" r="1.44"/>
            <circle className="cls-1" cx="522.44" cy="180.56" r="1.44"/>
            <circle className="cls-1" cx="526.59" cy="180.56" r="1.44"/>
            <circle className="cls-1" cx="530.76" cy="180.56" r="1.44"/>
            <circle className="cls-1" cx="535.05" cy="180.56" r="1.44"/>
            <circle className="cls-1" cx="513.86" cy="176.23" r="1.44"/>
            <circle className="cls-1" cx="518.15" cy="176.23" r="1.44"/>
            <circle className="cls-1" cx="522.44" cy="176.23" r="1.44"/>
            <circle className="cls-1" cx="526.59" cy="176.23" r="1.44"/>
            <circle className="cls-1" cx="530.76" cy="176.23" r="1.44"/>
            <circle className="cls-1" cx="518.15" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="522.44" cy="171.9" r="1.44"/>
            <circle className="cls-1" cx="543.47" cy="184.89" r="1.44"/>
            <circle className="cls-1" cx="543.47" cy="180.56" r="1.44"/>
            <circle className="cls-1" cx="551.93" cy="180.56" r="1.44"/>
            <circle className="cls-1" cx="547.64" cy="176.23" r="1.44"/>
            <circle className="cls-1" cx="539.34" cy="189.22" r="1.44"/>
            <circle className="cls-1" cx="543.47" cy="189.22" r="1.44"/>
            <circle className="cls-1" cx="501.64" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="510.11" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="514.4" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="518.54" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="522.72" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="527.01" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="531.29" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="484.5" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="488.68" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="492.96" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="497.25" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="501.64" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="505.82" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="510.11" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="514.4" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="518.54" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="522.72" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="527.01" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="531.29" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="484.5" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="488.67" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="492.96" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="497.25" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="501.64" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="505.82" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="510.11" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="514.4" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="518.54" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="522.72" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="527.01" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="531.29" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="484.65" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="488.82" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="493.11" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="497.4" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="501.79" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="505.96" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="510.25" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="514.54" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="518.69" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="522.86" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="527.15" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="531.44" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="484.65" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="488.82" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="493.11" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="497.4" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="501.79" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="505.96" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="510.25" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="514.54" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="518.69" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="522.86" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="527.15" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="531.44" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="484.65" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="488.82" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="493.11" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="497.4" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="501.79" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="505.96" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="510.25" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="514.54" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="518.69" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="522.86" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="527.15" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="531.44" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="484.65" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="488.82" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="493.11" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="497.4" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="501.79" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="505.96" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="510.25" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="514.54" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="518.69" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="522.86" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="527.15" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="531.44" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="484.5" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="488.68" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="492.96" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="497.25" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="501.64" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="505.82" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="510.11" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="514.4" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="518.54" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="522.72" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="527.01" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="531.29" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="484.5" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="488.67" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="492.96" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="497.25" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="501.64" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="505.82" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="510.11" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="514.4" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="518.54" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="522.72" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="527.01" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="531.29" cy="205.66" r="1.44"/>
            <circle className="cls-1" cx="492.96" cy="201.33" r="1.44"/>
            <circle className="cls-1" cx="497.25" cy="201.33" r="1.44"/>
            <circle className="cls-1" cx="501.64" cy="201.33" r="1.44"/>
            <circle className="cls-1" cx="505.82" cy="201.33" r="1.44"/>
            <circle className="cls-1" cx="510.11" cy="201.33" r="1.44"/>
            <circle className="cls-1" cx="514.4" cy="201.33" r="1.44"/>
            <circle className="cls-1" cx="527.01" cy="201.33" r="1.44"/>
            <circle className="cls-1" cx="531.29" cy="201.33" r="1.44"/>
            <circle className="cls-1" cx="497.25" cy="197" r="1.44"/>
            <circle className="cls-1" cx="501.64" cy="197" r="1.44"/>
            <circle className="cls-1" cx="505.82" cy="197" r="1.44"/>
            <circle className="cls-1" cx="510.11" cy="197" r="1.44"/>
            <circle className="cls-1" cx="527.01" cy="197" r="1.44"/>
            <circle className="cls-1" cx="531.29" cy="197" r="1.44"/>
            <circle className="cls-1" cx="535.42" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="535.42" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="539.6" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="535.57" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="539.74" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="535.57" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="539.74" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="535.57" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="539.74" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="535.57" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="535.42" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="527.01" cy="192.64" r="1.44"/>
            <circle className="cls-1" cx="510.11" cy="192.64" r="1.44"/>
            <circle className="cls-1" cx="514.4" cy="192.64" r="1.44"/>
            <circle className="cls-1" cx="510.27" cy="248.11" r="1.44"/>
            <circle className="cls-1" cx="514.44" cy="248.11" r="1.44"/>
            <circle className="cls-1" cx="518.73" cy="248.11" r="1.44"/>
            <circle className="cls-1" cx="523.02" cy="248.11" r="1.44"/>
            <circle className="cls-1" cx="510.27" cy="243.78" r="1.44"/>
            <circle className="cls-1" cx="514.45" cy="243.78" r="1.44"/>
            <circle className="cls-1" cx="518.73" cy="243.78" r="1.44"/>
            <circle className="cls-1" cx="523.02" cy="243.78" r="1.44"/>
            <circle className="cls-1" cx="527.15" cy="243.78" r="1.44"/>
            <circle className="cls-1" cx="467.4" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="471.58" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="475.87" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="480.15" cy="226.9" r="1.44"/>
            <circle className="cls-1" cx="467.4" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="471.58" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="475.87" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="480.15" cy="222.57" r="1.44"/>
            <circle className="cls-1" cx="467.4" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="471.58" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="475.87" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="480.15" cy="218.24" r="1.44"/>
            <circle className="cls-1" cx="471.58" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="475.87" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="480.15" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="480.15" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="513.1" cy="255.83" r="1.44"/>
            <circle className="cls-1" cx="467.29" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="471.46" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="475.75" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="480.04" cy="235.56" r="1.44"/>
            <circle className="cls-1" cx="467.29" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="471.46" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="475.75" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="480.04" cy="231.23" r="1.44"/>
            <circle className="cls-1" cx="467.43" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="471.6" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="475.89" cy="239.89" r="1.44"/>
            <circle className="cls-1" cx="542.77" cy="264.58" r="1.44"/>
            <circle className="cls-1" cx="547.06" cy="264.58" r="1.44"/>
            <circle className="cls-1" cx="551.23" cy="259.96" r="1.44"/>
            <circle className="cls-1" cx="555.52" cy="259.96" r="1.44"/>
            <circle className="cls-1" cx="559.98" cy="255.83" r="1.44"/>
            <circle className="cls-1" cx="568.87" cy="251.7" r="1.44"/>
            <circle className="cls-1" cx="572.57" cy="246.67" r="1.44"/>
            <circle className="cls-1" cx="567.43" cy="213.91" r="1.44"/>
            <circle className="cls-1" cx="564.3" cy="209.99" r="1.44"/>
            <circle className="cls-1" cx="120.26" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="124.43" cy="78.1" r="1.44"/>
            <circle className="cls-1" cx="120.26" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="124.43" cy="73.77" r="1.44"/>
            <circle className="cls-1" cx="107.4" cy="124.4" r="1.44"/>
            <circle className="cls-1" cx="111.7" cy="124.4" r="1.44"/>
        </g>
    );
};

export default WorldMapPoints;