import React, { SFC } from 'react';
import styled from '../../styled-components';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import formatEventDate from '../../services/formatEventDate';
import LazyImg from '../atoms/LazyImg';

interface Props extends LinkProps {
    hero?: {
        srcSmall: string | null;
        widthSmall: string | null;
        srcMedium: string | null;
        widthMedium: string | null;
        srcLarge: string | null;
        widthLarge: string | null;
        title: string | null;
    } | null;
    title: string;
    startDate: string | null;
    endDate: string | null;
    location: string | null;
}

const EventPreview: SFC<Props> = ({ title, startDate, endDate, location, hero, ...rest }) => (
    <Link {...rest} className={'event-item'}>
        {hero && (
            <ImgWrapper>
                <LazyImg
                    alt={hero && hero.title || undefined}
                    src={hero && hero.srcSmall || undefined}
                    srcSet={`
                        ${hero && hero.srcSmall} ${hero && hero.widthSmall}w,
                        ${hero && hero.srcMedium} ${hero && hero.widthMedium}w,
                        ${hero && hero.srcLarge} ${hero && hero.widthLarge}w`}
                    sizes={'(min-width: 990px) 50vw, 100vw'}
                />
            </ImgWrapper>
        )}
        {startDate && <EventDate>{formatEventDate(startDate, endDate)}</EventDate>}
        <EventTitle>{title}</EventTitle>
        {location && <EventLocation>{location}</EventLocation>}
    </Link>
);

const EventDate = styled.span`
    font: ${props => props.theme.fonts.large.eventDate};
    color: ${props => props.theme.colors.tango};
    margin: 0;
    text-transform: uppercase;
`;

const EventTitle = styled.h2`
    margin: 0;
    font: ${props => props.theme.fonts.small.sectionItemTitle};
    transition: .2s color ease-in-out;

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        font: ${props => props.theme.fonts.medium.sectionItemTitle};
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.large.sectionItemTitle};
    }
`;

const EventLocation = styled.p`
    margin:0;
    font: ${props => props.theme.fonts.small.sectionItemSubtitle};
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        font: ${props => props.theme.fonts.medium.sectionItemSubtitle};
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.large.sectionItemSubtitle};
    }
`;

const Link = styled(RouterLink)`
    display: block;
    text-decoration: none;
    color: inherit;
    width: 100%;
    
    &:hover {
      img {
          transform: scale(1.05); 
      }
      
      h2 {
          color: ${props => props.theme.colors.tango};
      }
    }
`;

const ImgWrapper = styled.section`
    position: relative;
    overflow: hidden;
    
    img {
      width: 100%;
      transition: .5s transform cubic-bezier(0.215, 0.61, 0.355, 1);
    }
`;

export default EventPreview;
