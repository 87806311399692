import React, { CSSProperties } from 'react';
import backgroundDark from '../../assets/stats_background.svg';
import backgroundOrange from '../../assets/stats_background_orange.svg';
import styled from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';

interface Props {
    title: string;
    amount?: number | null;
    linkText: string;
    linkUrl: string;
    hasOrangeBackground?: boolean;
    darkButton?: boolean;
    backgroundStyle?: CSSProperties | undefined;
}

const StatBlock = ({ title, amount, linkText, linkUrl, hasOrangeBackground, backgroundStyle, darkButton }: Props) => {
    return (
        <Block isOrange={hasOrangeBackground}>
            <Content>
                {amount && <Amount>{amount}</Amount>}
                <Title>{title}</Title>
                <div>
                    {darkButton ? 
                        <DefaultButton to={linkUrl} colorScheme="dark">
                            {linkText}
                        </DefaultButton>
                        :
                        <DefaultButton to={linkUrl} colorScheme="light">
                            {linkText}
                        </DefaultButton>
                    }
                </div>
            </Content>
            <Background src={hasOrangeBackground ? backgroundOrange : backgroundDark} alt={title} style={backgroundStyle} />
        </Block>
    );
};

const Block = styled.div<{ isOrange?: boolean }>`
    position: relative;
    overflow: hidden;
    padding: 3rem 2.5rem;

    font-family: chalet-new-york-sixty;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ isOrange, theme }) => (isOrange ? theme.colors.tango : theme.colors.black)};

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        padding: 4rem 3.5rem;
    }
`;

const Amount = styled.div`
    font-size: 5rem;
    line-height: 6rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        font-size: 8rem;
        line-height: 9rem;
    }
`;

const Title = styled.div`
    font-size: 2.2rem;
    line-height: 2.8rem;

    margin-bottom: 2rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        min-height: 6.4rem;
        font-size: 2.6rem;
        line-height: 3.2rem;
    }
`;

const Content = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const Background = styled.img`
    position: absolute;
    bottom: 0;
`;

export default StatBlock;
