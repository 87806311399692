import styled from '../../styled-components';

interface Props {
    color?: string;
    maxWidth?: string;
    textShadow?: boolean;
    alignRight?: boolean;
}

const IntroTitle = styled.h2<Props>`
    color: ${props => (props.color ? props.color : props.theme.colors.black)};
    font: ${props => props.theme.fonts.small.introTitle};
    margin: 0 0 2.4rem;

    &::after {
        content: '';
        display: block;
        width: 6rem;
        height: 0.2rem;
        margin-top: 2.4rem;
        background-color: ${props => props.theme.colors.tango};
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.medium.introTitle};
        margin: 0 0 4rem;

        &::after {
            margin-top: 4rem;
            width: 12rem;
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font: ${props => props.theme.fonts.large.introTitle};
        max-width: 720px;
    }
`;

export default IntroTitle;
