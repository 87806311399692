import { branch, renderComponent } from 'recompose';
import { compose } from 'recompose';
import SpinnerPage from '../components/molecules/SpinnerPage';

export default compose(
    branch(
        ({ data }) => data.loading,
        renderComponent(SpinnerPage)
    )
);
