import React, { Fragment, SFC } from 'react';
import * as Form from '../atoms/Form';
import styled from 'styled-components';

interface Props {
    type: string;
    sameAs: string;
    value: boolean;
}

const RegisterContact: SFC<Props> = ({ type, sameAs, value }: Props) => (
    <Fragment>
        <Form.Field
            name={`${type}.sameAs`}
            label={`Same as ${sameAs }`}
            type="checkbox"
            defaultChecked={value}
        />

        <OptionalForm hide={value}>
            <Form.Field
                name={`${type}.title`}
                label="Title *"
                placeholder="Choose..."
                type="select"
                options={['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.']}
            />

            <Form.Field name={`${type}.firstName`} label="First Name *" placeholder={`${type} first name`}/>
            <Form.Field name={`${type}.lastName`} label="Last Name *" placeholder={`${type} last name`}/>
            <Form.Field name={`${type}.jobTitle`} label="Job Title" placeholder={`${type} job title`}/>
            <Form.Field name={`${type}.email`} label="Email *" placeholder={`${type} email`}/>
            <Form.Field name={`${type}.phoneNumber`} label="Phone/Mobile *" placeholder={`${type} phonenumber`}/>
        </OptionalForm>
    </Fragment>
);

export default RegisterContact;

const OptionalForm = styled.div<{hide: boolean}>`
    display: ${(props) => props.hide ? 'none' : 'block'};
`;