import React, { ReactNode } from 'react';
import styled from '../../styled-components';

interface Props {
    children: ReactNode;
}

const HeaderOffsetter = styled.div`
    position: relative;
    z-index: 0;
    padding-top: 64px;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
      padding-top: 74px;
    } 
`;

export default ({ children }: Props) => (
    <HeaderOffsetter>
        <div>{children}</div>
    </HeaderOffsetter>
);
