import React from 'react';
import styled from '../../styled-components';
import { Img } from 'react-image-loading';
import formatEventDate from '../../services/formatEventDate';

interface Props {
    location?: string | null;
    img?: {
        srcSmall: string | null;
        widthSmall: string | null;
        srcMedium: string | null;
        widthMedium: string | null;
        srcLarge: string | null;
        widthLarge: string | null;
        title: string | null;
    };
    title: string;
    subTitle?: string | null;
    startDate?: string | null;
    endDate?: string | null;
}

const HeroDetail = ({ img, title, subTitle, location, startDate, endDate }: Props) => {
    return (
        <Wrapper>
            <ImgWrapper>
                <Img
                    alt={img && img.title || undefined}
                    src={img && img.srcSmall || undefined}
                    srcSet={`
                        ${img && img.srcSmall} ${img && img.widthSmall}w,
                        ${img && img.srcMedium} ${img && img.widthMedium}w,
                        ${img && img.srcLarge} ${img && img.widthLarge}w`}
                />
            </ImgWrapper>
            <HeroText>
                <HeroTitle>{title} {location && <span><br />{location}</span>}</HeroTitle>
                {subTitle && <HeroSubtext>{subTitle}</HeroSubtext>}
                {startDate && (
                    <HeroSubtext>
                        {formatEventDate(startDate, endDate ? endDate : null)}
                    </HeroSubtext>
                )}
            </HeroText>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: calc(100vh - 19rem);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    text-align: center;
    
    &:before {
        position: absolute;
        width: 100%;
        height: 2.5rem;
        background-color: white;
        z-index: 1;
        margin: 0 auto;
        @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
          content:'';
          max-width: 1152px;            
        }
        @media screen and (min-width: ${(props) => props.theme.mediaQueries.xl}) {
          max-width: 1344px;      
        }
        @media screen and (min-width: ${(props) => props.theme.mediaQueries.xxl}) {
          max-width: 1440px;
        }
    }
    &:after {
        content:'';
        position: absolute;
        display: block;
        background-color: black;
        opacity: 0.125;
        left:0; right: 0;
        width: 100%; height: 100%;
        z-index: 0;
    }
    
`;
const ImgWrapper = styled.div`
    min-height: calc(100vh - 19rem);
    width: 100%;
    position: relative;
    text-align: left;
    overflow: hidden;
    
    img {
      width: 100%;
      object-fit: cover;
      height: calc(100vh - 19rem);
    }
 
    @media screen and (max-width: ${(props) => props.theme.mediaQueries.s}) {
      height: calc(100vh - 12rem);
    }
   
`;

const HeroText = styled.div`
    position: absolute;
    z-index: 1;
    padding-bottom: 9rem;
    max-width: 61.3rem;
    margin: 0 auto;
    padding: 0 3.3rem 9rem 3.3rem;
`;

const HeroTitle = styled.h1`
    font-family: chalet-new-york-sixty, sans-serif;
    font-size: 4.4rem;
    line-height: 4.4rem;
    text-align: center;
    margin: 0 0 1.6rem 0;
    letter-spacing: -0.178rem;
    color: ${(props) => props.theme.colors.white};
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font-size: 6.4rem;
        line-height: 6.4rem;
    }
    span{
      font-family: chalet-london-sixty, sans-serif;
      font-weight: 100;
    }
`;

const HeroSubtext = styled.p`
    font-family: chalet-new-york-sixty, sans-serif;
    font-size: 2.1rem;
    line-height: 2.4rem;
    letter-spacing: -0.067rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font-size: 2.4rem;
        line-height: 3.2rem;
    }
`;

export default HeroDetail;
