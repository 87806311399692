import React, { ReactNode, SFC } from 'react';
import Container from '../../atoms/Container';
import styled, { ThemeProps, withTheme } from '../../../styled-components';
import { compose } from 'recompose';

const enhanced = compose<OutProps, InProps>(
    withTheme
);

type OutProps = ThemeProps;

interface InProps {
    featuredEntries?: ReactNode;
    children: ReactNode;
}
// todo: add statistic block to introwrap
const CollectionIntro: SFC<OutProps & InProps> = ({ children, theme, featuredEntries }) => (
    <Container
        maxWidth
        paddingTop={'4.8rem'}
        paddingTopL={'8.8rem'}
        paddingBottom={'7.2rem'}
        paddingBottomL={'7.2rem'}
        backgroundColor={theme.colors.mirage}
    >
        <IntroSection>
            <IntroWrap>
                {children}
            </IntroWrap>
            {featuredEntries &&
                featuredEntries
            }
        </IntroSection>
    </Container>
);

const IntroSection = styled.section`
    color: white;
    >p {
        font: ${(props) => props.theme.fonts.small.introText};
        @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font: ${(props) => props.theme.fonts.large.introText};
        }
    }
`;

const IntroWrap = styled.div`
    display: flex;
    flex: 1 100%;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    padding: 0.5rem;
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        flex-direction: unset;
        flex: 1 50%;
        >div{
            max-width: 50%;
        }
    }
`;

export default enhanced(CollectionIntro);
