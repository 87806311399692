import React, { Fragment, SFC } from 'react';
import { Values } from './RegisterForm';
import styled, { css } from '../../styled-components';
import theme from '../../constants/theme';
import RegisterConfirmationContact from './RegisterConfirmationContact';

interface Props {
    values: Values;
    resellerForm?: boolean;
}

const RegisterConfirmation: SFC<Props> = ({ values, resellerForm }: Props) => (
    <Fragment>
        <FormTitle>Your Contact Details</FormTitle>
        <DataList>
            <DataName>Title *</DataName>
            <DataValue>{values.title}</DataValue>

            <DataName>First Name *</DataName>
            <DataValue>{values.firstName}</DataValue>

            <DataName>Last Name *</DataName>
            <DataValue>{values.lastName}</DataValue>

            <DataName>Job Title</DataName>
            <DataValue>{values.jobTitle}</DataValue>

            <DataName>Email</DataName>
            <DataValue>{values.email}</DataValue>

            <DataName>Phone/Mobile</DataName>
            <DataValue>{values.phoneNumber}</DataValue>
        </DataList>

        <FormTitle>Company Details</FormTitle>
        <DataList>
            <DataName>Company Name *</DataName>
            <DataValue>{values.companyName}</DataValue>

            <DataName>URL *</DataName>
            <DataValue>{values.url}</DataValue>

            <DataName>Industry *</DataName>
            <DataValue>{values.industry}</DataValue>

            <DataName>Main Headquarter's location</DataName>
            <DataValue>{values.mainLocation}</DataValue>
        </DataList>

        <FormTitle>Address and Billing Information</FormTitle>
        <DataList>
            <DataName>Address line *</DataName>
            <DataValue>{values.address}</DataValue>

            <DataName>City *</DataName>
            <DataValue>{values.city}</DataValue>

            <DataName>Postal (zip) Code *</DataName>
            <DataValue>{values.postalCode}</DataValue>

            <DataName>Country</DataName>
            <DataValue>{values.country}</DataValue>

            { values.sameAsRegisteredOfficeAddress && (
                <Fragment>
                    <DataName>Billing Information *</DataName>
                    <DataValue>Same as registered office address</DataValue>
                </Fragment>
            )}

            <DataName>VAT Number</DataName>
            <DataValue>{values.vatNumber}</DataValue>
        </DataList>

        {!values.sameAsRegisteredOfficeAddress && (
            <Fragment>
                <SmallFormTitle>Billing Information</SmallFormTitle>
                <DataList>
                    <DataName>Billing Name *</DataName>
                    <DataValue>{values.billingName}</DataValue>

                    <DataName>Address *</DataName>
                    <DataValue>{values.billingAddress}</DataValue>

                    <DataName>City *</DataName>
                    <DataValue>{values.billingCity}</DataValue>

                    <DataName>Postal (zip) Code *</DataName>
                    <DataValue>{values.billingPostalCode}</DataValue>
                </DataList>
            </Fragment>
        )}

        <FormTitle>Administrative Contact Person (ACP)</FormTitle>
        <DataList>
            <DataName>Title *</DataName>
            <DataValue>{values.title}</DataValue>

            <DataName>First Name *</DataName>
            <DataValue>{values.firstName}</DataValue>

            <DataName>Last Name *</DataName>
            <DataValue>{values.lastName}</DataValue>

            <DataName>Job Title</DataName>
            <DataValue>{values.jobTitle}</DataValue>

            <DataName>Email</DataName>
            <DataValue>{values.email}</DataValue>

            <DataName>Phone/Mobile</DataName>
            <DataValue>{values.phoneNumber}</DataValue>
        </DataList>

        <FormTitle>Billing Contact Person (BCP)</FormTitle>
        <RegisterConfirmationContact values={values} contactType={'BCP'} sameAs={'ACP'}/>

        <FormTitle>Contractual Contact Person (CCP)</FormTitle>
        <RegisterConfirmationContact values={values} contactType={'CCP'} sameAs={'BCP'}/>

        <FormTitle>Technical Contact Person (TCP)</FormTitle>
        <RegisterConfirmationContact values={values} contactType={'TCP'} sameAs={'CCP'}/>

        <FormTitle>NOC</FormTitle>
        { !values.NOC ?
            (
                <DataList>
                    <DataName>Email</DataName>
                    <DataValue>{values.NOCemail}</DataValue>

                    <DataName>Phone/Mobile</DataName>
                    <DataValue>{values.NOCphoneNumber}</DataValue>
                </DataList>
            )
            : (
                <Paragraph>Same as TCP</Paragraph>
            )
        }

        { resellerForm && (
            <Fragment>
                <FormTitle>Commercial Details</FormTitle>
                <DataList>
                    <DataName>Commercial Email</DataName>
                    <DataValue>{values.commercialEmail}</DataValue>

                    <DataName>Types of Services</DataName>
                    <DataValue>{values.typesOfServices}</DataValue>
                </DataList>
            </Fragment>
        )}

    </Fragment>
);

export default RegisterConfirmation;

const FormTitle = styled.h2`
    font: ${props => props.theme.fonts.small.registerSubtitle};
    margin-top: 6.1rem;
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font: ${props => props.theme.fonts.large.registerSubtitle};
    }
`;

const SmallFormTitle = styled.h3 `
    font: ${(props) => props.theme.fonts.small.introText};
    max-width: 80rem;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        font: ${(props) => props.theme.fonts.large.introText};
    }
`;

const Paragraph = styled.p`
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: bold;
    margin-bottom: 9.6rem;
`;

const DataList = styled.dl `
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: bold;
    margin-bottom: 6rem;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const ListItem = css`
    display: block;
    padding: 1.5rem 0;
    margin: 0;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        width: 50%;
        display: inline-block;
        border-bottom: .1rem solid ${theme.colors.mystic};
    }
`;

const DataName = styled.dt `
   ${ListItem};
`;

const DataValue = styled.dd `
   ${ListItem};
   border-bottom: .1rem solid ${theme.colors.mystic};
   white-space: pre-wrap;
`;
