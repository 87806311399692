import React, { Fragment, SFC } from 'react';
import styled, { css } from '../../styled-components';
import theme from '../../constants/theme';
import { Values } from './RegisterForm';
import get from 'lodash.get';

interface Props {
    contactType: string;
    sameAs: string;
    values: Values;
}

const RegisterConfirmationContact: SFC<Props> = ({ values, contactType, sameAs }: Props) => {

    return (
        <Fragment>
            {!get(values, contactType).sameAs ?
                (
                    <DataList>
                        <DataName>Title *</DataName>
                        <DataValue>{get(values, contactType).title}</DataValue>

                        <DataName>First Name *</DataName>
                        <DataValue>{get(values, contactType).firstName}</DataValue>

                        <DataName>Last Name *</DataName>
                        <DataValue>{get(values, contactType).lastName}</DataValue>

                        <DataName>Job Title</DataName>
                        <DataValue>{get(values, contactType).jobTitle}</DataValue>

                        <DataName>Email</DataName>
                        <DataValue>{get(values, contactType).email}</DataValue>

                        <DataName>Phone/Mobile</DataName>
                        <DataValue>{get(values, contactType).phoneNumber}</DataValue>
                    </DataList>
                )
                : (
                    <Paragraph>Same as {sameAs}</Paragraph>
                )
            }
        </Fragment>
    );
};
// <Form.Field name={`${this.props.type}.firstName`} label="First Name *" placeholder={`${this.props.type} first name`}/>
export default RegisterConfirmationContact;

const Paragraph = styled.p`
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: bold;
    margin-bottom: 9.6rem;
`;

const DataList = styled.dl `
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: bold;
    margin-bottom: 6rem;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const ListItem = css`
    display: block;
    padding: 1.5rem 0;
    margin: 0;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        width: 50%;
        display: inline-block;
        border-bottom: .1rem solid ${theme.colors.mystic};
    }
`;

const DataName = styled.dt `
   ${ListItem};
`;

const DataValue = styled.dd `
   ${ListItem};
   border-bottom: .1rem solid ${theme.colors.mystic};
`;