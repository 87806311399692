import React from 'react';
import styled from '../../../styled-components';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends LinkProps{
    clicked: boolean;
}

const TabButtonLink = styled(Link)<LinkProps & { pressed: number; }>`
    background-color: white;
    border: none;
    border-bottom: 2px solid ${props => (props.pressed === 1 ? props.theme.colors.blazeOrange : props.theme.colors.transparentGrey)};
    color: ${(props) => (props.pressed === 1 ? props.theme.colors.blazeOrange : props.theme.colors.baliHai)};
    cursor: pointer
    display: block;
    float: left;
    font-size: 17px;
    font-family: urw-din;
    font-weight: 600;
    font-size: 1.2rem;
    outline: none;
    padding: 14px 16px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    &:hover {
        border-bottom: 2px solid ${(props) => props.theme.colors.blazeOrange};
        color: ${(props) => props.theme.colors.blazeOrange};
    }
`;

export default ({clicked, ...rest }: Props) => (
    <TabButtonLink pressed={clicked ? 1 : 0} {...rest }/>
);