import styled from '../../../styled-components';

export const ButtonWithIcon = styled.button`
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    font-size: 1.4rem;
    letter-spacing: 0.86px;
    text-decoration: none;
    font-family: chalet-new-york-sixty, sans-serif;
    padding: 0;
    color: inherit;
    border: none;
    background-color: unset;
    cursor: pointer;
    outline: none;
`;

export const buttonHover = `
    content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 2.4rem;
        background-color: inherit;
        z-index: -1;
        transition: transform 0.2s;
`;