import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from '../../../styled-components';
import LinkIconDown from '../icons/LinkIconDown';

interface Props {
    first?: boolean;
    inActive?: boolean;
}

const PaginationLink = styled(NavLink)<NavLinkProps & { 'data-first': boolean }>`
    display: inline-block;
    position: relative;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    margin: 0 1.2rem 0 0;
    transition: background-color 0.15s;
    background-color: black;
    color: white;
    transform: rotate(${props => (props['data-first'] ? '90deg' : '-90deg')});
    opacity: 0.3;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        width: 40px;
        height: 40px;
    }
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:not(.active) {
        pointer-events: none;
    }

    &.active {
        opacity: 1;
    }
`;

export default ({ first, inActive, ...rest }: Props & NavLinkProps) => (
    <PaginationLink data-first={!!first} activeClassName="active" isActive={() => !inActive} {...rest}>
        <LinkIconDown color={'white'} />
    </PaginationLink>
);
