import { Form as FormikForm, FormikBag, FormikProps, withFormik } from 'formik';
import gql from 'graphql-tag';
import React, { Fragment, SFC } from 'react';
import { graphql, MutationFunction } from 'react-apollo';
import ReactGA from 'react-ga';
import ReCAPTCHA from 'react-google-recaptcha';
import {branch, compose, Omit} from 'recompose';
import { object, string } from 'yup';
import { default as sites, Site } from '../../constants/sites';
import {
    sendContactRequestForRegistrationMutation,
    sendContactRequestForRegistrationMutationVariables,
} from '../../entities/operationResults';
import matchRoute from '../../services/matchRoute';
import styled from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import * as Form from '../atoms/Form';
import { ExchangeParams } from '../Exchange';
import * as Intro from '../molecules/Intro';

interface Values {
    recaptcha: string;
    firstName: string;
    lastName: string;
    organization: string;
    jobTitle: string;
    workEmail: string;
}

type newSendContactRequestForRegistrationMutationVariables = Omit<sendContactRequestForRegistrationMutationVariables, 'contactFormId'> & {
    contactFormId?: number | null;
    authorId: string,
};

const CONTACT_REQUEST_REGISTRATION_MUTATION = gql`
    mutation sendContactRequestForRegistration(
        $siteId: Int
        $firstName: String
        $lastName: String
        $organization: String
        $jobTitle: String
        $workEmail: String
        $contactFormId: Number
        $recaptcha: String
        $siteLocation: String
        $authorId: ID!
    ) {
        save_contactRequest_registration_Entry(
            siteId: $siteId
            title: "dummy"
            firstName: $firstName
            lastName: $lastName
            organization: $organization
            jobTitle: $jobTitle
            workEmail: $workEmail
            contactFormId: $contactFormId
            recaptchaValue: $recaptcha
            siteLocation: $siteLocation
            authorId: $authorId
        ) {
            mailSent
        }
    }
`;

const trackSubmitFormEvent = () => {
    ReactGA.event({
        category: 'Contactform',
        action: 'Form submitted',
        label: location.pathname,
    });
};

interface InProps {
    contactBlockId?: number | null;
    isDark?: boolean;
    isRegistrationForm?: boolean
    registrationSubject?: string | null;
}


interface OutProps extends FormikProps<Values> {
   sendContactRequestForRegistration?: MutationFunction<sendContactRequestForRegistrationMutation, sendContactRequestForRegistrationMutationVariables>;
}

type Props = InProps & OutProps;

const ContactFormRegistration: SFC<Props> = ({
    submitForm,
    status,
    isSubmitting,
    isValid,
    isDark,
    registrationSubject,
    setFieldValue,
    errors,
    touched,
}) => {
    return (
        <FormContainer>
            <FormError>{status && status.type === 'error' && <Form.Error>{status.value}</Form.Error>}</FormError>
            <FormikForm className={
                window.location.href.search('pricing') > -1 ? 'Sales' :
                    window.location.href.search('events') > -1 ? 'Events' :
                        window.location.href.search('service') ?'Customer-service' : ''}>
                {status && status.type === 'success' ? (
                    <Intro.Text>
                        <p className={'success-message'}>
                            {'Thank you for your registration! We look forward to seeing you at ' + registrationSubject ? registrationSubject : ''}
                        </p>
                    </Intro.Text>
                ) : (
                    <Fragment>
                        <Form.Field name="firstName" label="First Name*" placeholder="Your first name" />
                        <Form.Field name="lastName" label="Last Name*" placeholder="Your last name" />
                        <Form.Field name="organization" label="Organization*" placeholder="Your organization name" />
                        <Form.Field name="workEmail" label="Work Email*" placeholder="Your work email" />
                        <Form.Field name="jobTitle" label="Job Title*" placeholder="Your job title" />

                        <ReCAPTCHAContainer>
                            <ReCAPTCHA
                                sitekey={process.env.RAZZLE_RECAPTCHA_SITEKEY || ''}
                                onChange={response => setFieldValue('recaptcha', response)}
                                theme={isDark ? 'dark' : 'light'}
                            />
                            {errors.recaptcha && touched.recaptcha && <Form.Error>{errors.recaptcha}</Form.Error>}
                        </ReCAPTCHAContainer>
                        <DefaultButton
                            to="#"
                            colorScheme={isDark ? 'colored' : 'dark'}
                            onClick={e => {
                                e.preventDefault();
                                submitForm();
                            }}
                            disabled={isSubmitting || !isValid}
                        >
                            SEND
                        </DefaultButton>
                    </Fragment>
                )}
            </FormikForm>
        </FormContainer>
    );
};

const FormContainer = styled(Intro.Container)`
    padding-top: 0;
    padding-bottom: 6rem;

    textarea {
        resize: none;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-top: 2.2rem;
    }
`;

const FormError = styled.div`
    margin-bottom: 2rem;
`;

const ReCAPTCHAContainer = styled.div`
    margin-bottom: 3.2rem;
`;

const handleSubmit = async (
    { firstName, lastName, organization, jobTitle, workEmail, recaptcha }: Values,
    { props, setSubmitting, setStatus }: FormikBag<Props, Values>
) => {
    const matchedRoute = matchRoute<ExchangeParams>(location.pathname);
    const site: Site | undefined = sites.find(
        current => !!matchedRoute && current.handle === matchedRoute.match.params.exchange
    );
    const siteId = site ? site.id : sites[0].id;
    const siteLocation = site ? site.name : sites[0].name;

    try {
        if (props.sendContactRequestForRegistration) {
            const { data } = await props.sendContactRequestForRegistration({
                variables: {
                    siteId,
                    firstName,
                    lastName,
                    organization,
                    jobTitle,
                    workEmail,
                    contactFormId: props.contactBlockId !== null ? String(props.contactBlockId) : null,
                    recaptcha,
                    siteLocation,
                    authorId: '1',
                } as newSendContactRequestForRegistrationMutationVariables,
            });

            if (data && data.save_contactRequest_registration_Entry && !data.save_contactRequest_registration_Entry.mailSent) {
                return setStatus({
                    type: 'error',
                    value: 'Failed to send message to recipient, try again later.',
                });
            }
        }
        trackSubmitFormEvent();
        setStatus({
            type: 'success',
        });
    } catch (ex) {
        setStatus({
            type: 'error',
            value: ex.toString(),
        });
    } finally {
        setSubmitting(false);
    }
};

const enhance = compose<OutProps, InProps>(
    branch(
        (props: InProps) => !props.isRegistrationForm,
            graphql(CONTACT_REQUEST_REGISTRATION_MUTATION, {
                name: 'sendContactRequestForRegistration',
            })
    ),
    graphql(CONTACT_REQUEST_REGISTRATION_MUTATION, {
        name: 'sendContactRequestForRegistration',
    }),
    withFormik<Props, Values>({
        validationSchema: object().shape({
            workEmail: string()
                .required('Please enter an email address')
                .email('Please enter a valid email address'),
            firstName: string().required('Please enter your first name'),
            lastName: string().required('Please enter your last name'),
            organization: string().required('Please enter your organization name'),
            jobTitle: string().required('Please enter your job title'),
            recaptcha: string()
                .nullable(true)
                .required('Please complete the reCAPTCHA'),
        }),
        handleSubmit,
        mapPropsToValues: () => ({
            firstName: '',
            lastName: '',
            workEmail: '',
            organization: '',
            jobTitle: '',
            recaptcha: '',
        }),
    })
);

export default enhance(ContactFormRegistration);
