import Highcharts, { IndividualSeriesOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DateTime } from 'luxon';
import React, { SFC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { MenuScheme } from '../../entities/operationResults';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import { ExchangeParams } from '../Exchange';

type OutProps = RouteComponentProps<ExchangeParams>;

export interface Props {
    averageIn: Array<{
        __typename: 'averageInputData_TableRow';
        // Time
        time: number | null;
        // Value (Mbit/s)
        value: number | null;
    } | null> | null;
    averageOut?: Array<{
        __typename: 'averageOutputData_TableRow';
        // Time
        time: number | null;
        // Value (Mbit/s)
        value: number | null;
    } | null> | null;
    peakIn?: Array<{
        __typename: 'peakInputData_TableRow';
        // Time
        time: number | null;
        // Value (Mbit/s)
        value: number | null;
    } | null> | null;
    peakOut?: Array<{
        __typename: 'peakOutputData_TableRow';
        // Time
        time: number | null;
        // Value (Mbit/s)
        value: number | null;
    } | null> | null;
    peakLine?: number;
    showLegend?: boolean;
    scheme: MenuScheme;
    interval?: string | null;
}

const calculateDivision = (num: number) => {
    if (num > 1000000) {
        return {
            divider: 1000000,
            unit: 'Tb',
        };
    } else if (num > 1000) {
        return {
            divider: 1000,
            unit: 'Gb',
        };
    } else {
        return {
            divider: 1,
            unit: 'Mb',
        };
    }
};

interface TimeZoneOffsetInterface {
    ams: number;
    mum: number;
    hk: number;
    chi: number;
    car: number;
    bay: number;
    sin: number;
    ban: number;
    [key: string]: number;
}

// get the zone offsets for this time, in minutes
const timeZoneOffsets: TimeZoneOffsetInterface = {
    ams: DateTime.fromObject({ zone: 'Europe/Amsterdam' }).offset,
    mum: DateTime.fromObject({ zone: 'Asia/Kolkata' }).offset,
    kol: DateTime.fromObject({ zone: 'Asia/Kolkata' }).offset,
    hk: DateTime.fromObject({ zone: 'Asia/Hong_Kong' }).offset,
    chi: DateTime.fromObject({ zone: 'America/Chicago' }).offset,
    car: DateTime.fromObject({ zone: 'America/Curacao' }).offset,
    bay: DateTime.fromObject({ zone: 'America/Los_Angeles' }).offset,
    sin: DateTime.fromObject({ zone: 'Asia/Singapore' }).offset,
    lag: DateTime.fromObject({ zone: 'Africa/Lagos' }).offset,
    ban: DateTime.fromObject({ zone: 'Asia/Bangkok' }).offset,
};

const ChartGraph: SFC<Props & ThemeProps & OutProps> = ({
    averageIn,
    averageOut,
    peakIn,
    peakOut,
    peakLine,
    showLegend,
    scheme,
    theme,
    interval,
    match,
}) => {
    if (!averageIn) {
        return null;
    }

    const firstGraphData = averageIn[0];
    const calculatedUnits = calculateDivision((firstGraphData && firstGraphData.value) || 0);
    const showPeakLine =
        peakLine &&
        peakLine / calculatedUnits.divider - ((firstGraphData && firstGraphData.value) || 0) / calculatedUnits.divider <
            500;

    const rowData = (row: any) => {
        return (row && row.time && !isNaN(row.value)
            ? [(row.time + timeZoneOffsets[match.params.exchange] * 60) * 1000, row.value / calculatedUnits.divider]
            : [0, 0]) as [number, number];
    };

    const series: IndividualSeriesOptions[] = [];
    if (peakIn && peakIn.length) {
        series.push({
            name: 'Peak 5 Minute Input',
            color: theme.colors.crayola,
            data: peakIn.map(rowData),
            legendIndex: 4,
        });
    }
    if (averageIn && averageIn.length) {
        series.push({
            name: 'Input',
            color: theme.colors.tango,
            data: averageIn.map(rowData),
            legendIndex: 1,
        });
    }
    if (averageOut && averageOut.length) {
        series.push({
            name: 'Output',
            color: theme.colors.mustard,
            data: averageOut.map(rowData),
            type: 'line',
            legendIndex: 2,
        });
    }
    if (peakOut && peakOut.length) {
        series.push({
            name: 'Peak 5 Minute Output',
            color: theme.colors.christine,
            data: peakOut.map(rowData),
            type: 'line',
            legendIndex: 3,
        });
    }

    return (
        <StyledGraph scheme={scheme}>
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'areaspline',
                        height: 300,
                        backgroundColor: 'rgba(255, 255, 255, 0.0)',
                    },
                    title: {
                        text: null,
                    },
                    exporting: {
                        enabled: false,
                    },
                    legend: {
                        enabled: showLegend,
                    },
                    xAxis: {
                        type: 'datetime',
                        units: [['hour', [1, 2, 4, 8, 12]], ['day', [1, 2, 4]], ['month', [1]]],
                        dateTimeLabelFormats: {
                            hour: '%H:%M',
                            day: interval && interval === 'daily' ? '%H:%M' : '%d-%m',
                            month: '%b',
                        },
                        minPadding: 0,
                        maxPadding: 0,
                        labels: {
                            style: {
                                color: `${scheme === 'light' ? theme.colors.dustyGray : '#fff'}`,
                            },
                        },
                    },
                    yAxis: {
                        labels: {
                            format: `{value} ${calculatedUnits.unit}/s`,
                            style: {
                                color: `${scheme === 'light' ? theme.colors.dustyGray : '#fff'}`,
                            },
                        },
                        title: {
                            text: null,
                        },
                        plotLines:
                            showPeakLine && peakLine
                                ? [
                                      {
                                          value: peakLine / calculatedUnits.divider,
                                          color: `${scheme === 'light' ? theme.colors.black : theme.colors.white}`,
                                          width: 2,
                                      },
                                  ]
                                : [],
                        startOnTick: true,
                    },
                    tooltip: {
                        shared: false,
                        valueDecimals: 3,
                        backgroundColor: 'rgba(0,0,0,0)',
                        headerFormat: '<span class="tooltip-title">BITS PER SECOND</span><br/>',
                        pointFormat: `<span class="tooltip-amount">{point.y}</span> <span class="tooltip-unit">${calculatedUnits.unit}/s</span>`,
                        borderWidth: 0,
                        useHTML: true,
                        shadow: false,
                        // positioner(labelWidth: any, labelHeight: any, point: any) {
                        //     const tooltipX = point.plotX + 75;
                        //     const tooltipY = point.plotY - 15;
                        //     return {
                        //         x: tooltipX,
                        //         y: tooltipY
                        //     };
                        // }
                    },
                    credits: {
                        enabled: false,
                    },
                    plotOptions: {
                        areaspline: {
                            fillOpacity: 0.9,
                            states: {
                                hover: {
                                    halo: {
                                        size: 0,
                                    },
                                },
                            },
                            softThreshold: true,
                        },
                        series: {
                            marker: {
                                states: {
                                    hover: {
                                        radius: 5,
                                        fillColor: '#ffffff',
                                        lineColor: '#000000',
                                        lineWidth: 3,
                                    },
                                },
                                symbol: 'cirlce',
                            },
                        },
                    },
                    series,
                }}
            />
        </StyledGraph>
    );
};

const StyledGraph = styled.div<{ scheme: MenuScheme }>`
    .highcharts-axis-labels,
    .tooltip-title {
        font-family: urw-din;
        font-weight: 600;
        font-size: 1.1rem;
    }

    .tooltip-amount {
        font-family: chalet-new-york-sixty;
        font-size: 16px;
    }

    .tooltip-unit {
        font-family: chalet-new-york-sixty;
        font-size: 10px;
    }

    .tooltip-title,
    .tooltip-amount,
    .tooltip-unit {
        color: ${props => (props.scheme === 'light' ? props.theme.colors.vulcan : props.theme.colors.white)};
    }
`;

const enhanced = compose<ThemeProps, Props>(
    withTheme,
    withRouter
);

export default enhanced(ChartGraph);
