import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from '../../styled-components';
import LazyImg from '../atoms/LazyImg';

interface Props {
    service?: string | null;
    img?: {
        srcSmall: string | null;
        widthSmall: string | null;
        srcMedium: string | null;
        widthMedium: string | null;
        srcLarge: string | null;
        widthLarge: string | null;
        title: string | null;
    };
    title: string;
    link: string;
    date: string | null;
}

const StoryPreview = ({ title, img, service, link, date }: Props) => (
    <StoryWrap to={link} className={'story-item'}>
        {img && (
            <ImgWrapper>
                <LazyImg
                    alt={(img && img.title) || undefined}
                    src={(img && img.srcSmall) || undefined}
                    srcSet={`
                        ${img && img.srcSmall} ${img && img.widthSmall}w,
                        ${img && img.srcMedium} ${img && img.widthMedium}w,
                        ${img && img.srcLarge} ${img && img.widthLarge}w`}
                    sizes={'(min-width: 990px) 50vw, 100vw'}
                />
            </ImgWrapper>
        )}
        <StoryCat>{service && <h4>{service}</h4>}</StoryCat>
        <StoryContent>
            {date && (
                <StoryDate>
                    <h4>{date}</h4>
                </StoryDate>
            )}
            <h5>{title}</h5>
        </StoryContent>
    </StoryWrap>
);

const StoryWrap = styled(Link)<LinkProps>`
    position: relative;
    text-decoration: none;
    text-align: left;
    background-color: ${props => props.theme.colors.mirage};
    margin: 0.5rem;
    height: calc(100vw - 1.6rem);
    box-sizing: border-box;

    @media screen and (min-width: ${props => props.theme.mediaQueries.xs}) {
        width: 50%;
    }

    &:hover,
    &:focus {
        img {
            transform: scale(1.05);
        }
    }

    h4,
    h5,
    p {
        color: white;
    }

    h5 {
        font: ${props => props.theme.fonts.large.eventTitle};
        letter-spacing: 0.01rem;
        margin-bottom: 0.8rem;
        margin-top: 0;
    }
    p {
        font: ${props => props.theme.fonts.large.contentBody};
        letter-spacing: 0.01rem;
        margin: 0;
        display: none;
        @media screen and (min-width: ${props => props.theme.mediaQueries.xs}) {
            display: unset;
        }
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.xs}) {
        height: 44.6rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.xxl}) {
        height: 56rem;
    }
`;

const ImgWrapper = styled.div`
    min-height: 100%;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0) 60%,
            rgba(0, 0, 0, 0.2) 100%
        );
    }

    img {
        min-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        object-fit: cover;
        object-position: center;
        transition: 0.5s transform cubic-bezier(0.215, 0.61, 0.355, 1);
    }
`;

const StoryDate = styled.div`
    font: ${props => props.theme.fonts.small.storyTitle};
    letter-spacing: 0.04rem;
    text-transform: uppercase;
`;

const StoryContent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 3.3rem;
    max-width: 28.8rem;
    z-index: 3;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        padding: 4rem 26rem 4rem 4.8rem;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 4rem 13rem 4rem 4.8rem;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        padding: 4rem 18rem 4rem 4.8rem;
    }
`;

const StoryCat = styled.div`
    position: relative;
    z-index: 3;
    font: ${props => props.theme.fonts.small.storyTitle};
    letter-spacing: 0.04rem;
    h4 {
        margin: 0;
        text-transform: uppercase;
    }
`;

export default StoryPreview;
