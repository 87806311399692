import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ColorScheme } from '../../../entities/ColorScheme';
import { buttonBackgroundColor, buttonColor } from '../../../services/colorHelpers';
import styled from '../../../styled-components';
import { buttonHover } from './ButtonStyles';

interface Props extends LinkProps {
    colorScheme: ColorScheme;
    disabled?: boolean;
}

const DefaultButton = styled(Link)<LinkProps & { 'data-colorscheme': ColorScheme; 'data-disabled': boolean }>`
    position: relative;
    font-family: urw-din;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.86px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 2.4rem;
    padding: 1.3rem 3rem;
    align-items: center;
    transition: background-color 0.15s, opacity 0.25s, transform 0.2s;
    opacity: ${props => (props['data-disabled'] ? 0.5 : 1)};
    display: inline-flex;
    white-space: nowrap;
    background-color: ${props => buttonBackgroundColor(props['data-colorscheme'], 'neutral', props.theme)};
    color: ${props => buttonColor(props['data-colorscheme'], 'neutral', props.theme)};
    &:hover {
        background-color: ${props => buttonBackgroundColor(props['data-colorscheme'], 'hover', props.theme)};
        color: ${props => buttonColor(props['data-colorscheme'], 'hover', props.theme)};

        &:after {
            transform: scale(1.025);
        }
    }
    &:focus {
        background-color: ${props => buttonBackgroundColor(props['data-colorscheme'], 'focus', props.theme)};
        color: ${props => buttonColor(props['data-colorscheme'], 'focus', props.theme)};
    }

    svg:last-child {
        margin-left: 1rem;
        margin-right: -1rem;
    }

    svg:first-child {
        margin-right: 1rem;
        margin-left: -1rem;
    }

    &:after {
        ${buttonHover};
    }
`;

export default ({ colorScheme, disabled, ...rest }: Props) => (
    <DefaultButton data-disabled={!!disabled} data-colorscheme={colorScheme} {...rest} />
);
