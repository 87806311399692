import React from 'react';
import styled from '../../../../styled-components';
import { formatBitUnits } from '../../../../services/numberFormatting';
import DefaultButton from '../../../atoms/buttons/DefaultButton'
import LinkIconRight from '../../../atoms/icons/LinkIconRight';

interface InProps {
    locationName: string;
    peak?: any;
    asns?: number | null;
    internationalExchange?: string;
    poweredBy?: boolean;
    alternative?: boolean;
    url?: string;
    comingSoon?: boolean;
}

/**
 * @param locationName name of the location.
 * @param peak Optional. Peak traffic stats.
 * @param asns Optional. total amount of asns for this location.
 * @param internationalExchange Optional. Powered by location only. Pass name of powered by location.
 * @param poweredBy Optionial. Add if location is a powered by location.
 * @param alternative Optional. Add if Pinpointcard should be placed below the location.
 * @param url Optional. Add the (external) url of the location.
 * @param comingSoon Optional. Add if the location is a coming soon location. If this is used, peak and asns are not visible.
 * 
 * @returns PinpointCard component
 */
const PinpointCard = ({locationName, peak, asns, internationalExchange, poweredBy, alternative, url, comingSoon}: InProps) => {
    const peakTraffic = peak ? peak : 0;
    const peakTrafficFormatted = formatBitUnits(peakTraffic);

	return (
        <Card alternative={alternative} poweredBy={poweredBy}>
            <Container>
                {poweredBy && 
                    <StatContainer>
                      <StatLabel>Int. Exchange</StatLabel>
                      <StatValue>{internationalExchange}</StatValue>
                  </StatContainer>
                }
                <StatContainer>
                    <StatLabel>Location</StatLabel>
                    <StatValue>{locationName}</StatValue>
                </StatContainer>
            </Container>
            {comingSoon ? (
                <Container>
                    <StatContainer>
                        <StatLabel>___</StatLabel>
                        <StatValue>Coming soon</StatValue>
                    </StatContainer>
                </Container>
            )   :   !poweredBy ? (
                <Container>
                    <StatContainer>
                        <StatLabel>Peak</StatLabel>
                        <StatValue>{peakTrafficFormatted.amount} {peakTrafficFormatted.unit}/s</StatValue>
                    </StatContainer>
                    <StatContainer>
                        <StatLabel>ASNS</StatLabel>
                        <StatValue>{asns}</StatValue>
                    </StatContainer>
                </Container>
            )   :   (
                <Container>
                    <StatContainer>
                        <DefaultButton to={{ pathname: url}} target="_blank" colorScheme={'colored'}>
                            <span>{`Visit website`}</span>
                            <LinkIconRight color={'white'} />
                        </DefaultButton>
                    </StatContainer>
                </Container>
            )}
        </Card>
    )
};

const StatContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 100px;
    margin-bottom: 8px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const StatLabel = styled.div`
    display: flex;
    opacity: 0.5;
    text-transform: uppercase;
    font: ${props => props.theme.fonts.large.smallCaps};
    color: inherit;
    margin-right: 10px;

    > span {
        display: none;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        font: ${props => props.theme.fonts.large.smallCaps};
        justify-content: center;

        > span {
            display: inline;
        }
    }
`;

const StatValue = styled.div`
    display: flex;
    font-size: 1.6rem;
    font-family: chalet-new-york-sixty;

    @media screen and (min-width: ${props => props.theme.mediaQueries.xl}) {
        font-size: 1.4rem;
        justify-content: center;
    }
`;

const Card = styled.div<{ alternative?: boolean, poweredBy?: boolean }>`
	position: relative;
    background-color: white;
	height: ${props => (props.poweredBy ? '86px !important' : '80px !important')};
	width: auto !important;
	border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin-top: ${props => (props.alternative ? '10px' : '0px')};

	&:after {
		content: '';
		position: absolute;
		top: ${props => (props.alternative ? '-5%' : props.poweredBy ? '99%' : '97%')};
		left: 50%;
		margin-left: -10px;
		width: 0;
		height: 0;
        border-top: ${props => (props.alternative ? 'none' : 'solid 10px white')};
        border-bottom: ${props => (props.alternative ? 'solid 10px white' : 'none')};
		border-left: solid 10px transparent;
		border-right: solid 10px transparent;
    }
`;

export default PinpointCard;