import React, { SFC } from 'react';
import { ShowcaseStoryFragment } from '../../entities/operationResults';
import Container from '../atoms/Container';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import { compose } from 'recompose';
import DefaultButton from '../atoms/buttons/DefaultButton';
import gql from 'graphql-tag';
import { SERVICE_NAV_WIDTH } from '../Service';
import LazyImg from '../atoms/LazyImg';

export const storyShowcaseFragment = gql`
    fragment ShowcaseStory on story_story_Entry {
        title
        uri
        description
        heroImage {
            srcSmall: url(transform: "showcaseStorySmall")
            widthSmall: width(transform: "showcaseStorySmall")
            srcMedium: url(transform: "showcaseStoryMedium")
            widthMedium: width(transform: "showcaseStoryMedium")
            srcLarge: url(transform: "showcaseStoryLarge")
            widthLarge: width(transform: "showcaseStoryLarge")
            title
        }
    }
`;

interface InProps {
    story: ShowcaseStoryFragment;
}

const StoryShowcase: SFC<InProps & ThemeProps> = ({ story, theme }) => {
    const image = story.heroImage && story.heroImage[0];
    return (
        <StoryShowcaseContainer
            maxWidth
            paddingTopL={'12rem'}
            paddingTop={'6rem'}
            paddingBottomL={'12rem'}
            paddingBottom={'6rem'}
            backgroundColor={theme.colors.aquaHaze}
        >
            <Wrapper>
                <Content>
                    <SubTitle>AMS-IX STORY</SubTitle>
                    <Title>{story.title}</Title>
                    <Description>{story.description}</Description>
                        <div>
                            <DefaultButton
                                to={`/${story.uri}`}
                                colorScheme={'dark'}
                            >
                                <span style={{textTransform: 'uppercase'}}>Read story</span>
                            </DefaultButton>
                        </div>

                </Content>
                {image && image.srcSmall && (
                    <Image>
                        <LazyImg
                            alt={image.title || undefined}
                            src={image.srcSmall}
                            srcSet={`
                                ${image.srcSmall} ${image.widthSmall}w,
                                ${image.srcMedium} ${image.widthMedium}w,
                                ${image.srcLarge} ${image.widthLarge}w`}
                            sizes={'(min-width: 990px) 50vw, 100vw'}
                        />
                    </Image>
                )}
            </Wrapper>
        </StoryShowcaseContainer>
    );
};

const StoryShowcaseContainer = styled(Container)`
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) and (max-width: ${(props) => props.theme.mediaQueriesValues.xxl + SERVICE_NAV_WIDTH}px) {
        > div {
            padding-right: 12rem;
        }
    }
`;

const SubTitle = styled.h3`
    margin: 0 0 1rem 0;
    font-family: urw-din;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${props => props.theme.colors.baliHai};
`;

const Title = styled.h2`
    margin: 0 0 2rem 0;
    font: ${props => props.theme.fonts.large.sectionItemTitle};
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        margin-bottom: 3.4rem;
    }
`;

const Description = styled.div`
    color: ${props => props.theme.colors.baliHai};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    margin-bottom: 4rem;
`;

const Content = styled.div`
    padding-right: 8%;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const Image = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0 16px 124px;
    margin-bottom: 2rem;
    position: relative;
    
    img {
        width: 100%;
        display: block;
    }
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        width: 66%;
        margin-bottom: 0;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        flex-direction: row;
    }
`;

const enhanced = compose<ThemeProps, InProps>(
    withTheme
);

export default enhanced(StoryShowcase);