import { match } from "react-router-dom";

const getCanonicalUrl = (matchParams: match<any>) => {
    if (typeof window === 'undefined') {
        return undefined;
    }
    const path = matchParams.path.replace(':exchange', 'ams').replace(':slug', matchParams.params.slug)
    return window.location.origin + path;
}

export default getCanonicalUrl;