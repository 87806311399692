import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ColorScheme } from '../../../entities/ColorScheme';
import styled from '../../../styled-components';
import LinkIconRight from '../icons/LinkIconRight';

interface Props extends LinkProps {
    colorScheme: ColorScheme;
}

const colorChooser = (type: ColorScheme) => {
    if (type === 'light') {
        return 'white';
    }
    return 'black';
};

const LinkButton = styled(Link)<LinkProps & { 'data-colorscheme': ColorScheme }>`
    display: inline-flex;
    align-items: flex-end;
    font: ${props => props.theme.fonts.small.textLink};
    letter-spacing: 0.86px;
    text-decoration: none;
    color: ${props => colorChooser(props['data-colorscheme'])};
    align-items: center;
    transition: 0.2s transform;

    svg:last-child {
        padding-left: 1rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        font: ${props => props.theme.fonts.medium.textLink};
    }

    &:hover {
        transform: translateX(0.3rem);
    }
`;

export default ({ colorScheme, children, ...rest }: Props) => (
    <LinkButton data-colorscheme={colorScheme} {...rest}>
        {children} <LinkIconRight color={colorChooser(colorScheme)} />
    </LinkButton>
);
