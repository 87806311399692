import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const BackIcon = ({ color = 'currentColor', ...rest }: Props) => (
    <svg width="9" height="12" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g transform="matrix(0 -1 -1 0 8.75 12.5)" fill="none" fillRule="evenodd">
            <rect fill={color} x=".5" y=".5" width="3" height="3" rx="1.5" />
            <rect fill={color} x="5" y="5.75" width="3" height="3" rx="1.5" />
            <rect fill={color} x="9.5" y=".5" width="3" height="3" rx="1.5" />
            <path stroke={color} strokeWidth="1.05" d="M2.5 2.5l4 4.75 4-4.75" />
        </g>
    </svg>

);

export default BackIcon;
