import React from 'react';
import styled from '../../styled-components';

const ComingSoon = () => {
    return (
        <Description>
            <p>
                Coming Soon
            </p>
        </Description>
    );
};


const Description = styled.div`
    display: flex;
    justify-content: center;
    padding: 4rem 3.2rem 2.8rem 4rem; 
    p{
        margin: 0;
        text-align: left;
        font: ${(props) => props.theme.fonts.small.exchangePicker};
        letter-spacing: -0.049rem;
        padding-bottom: 2rem;
    }
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        padding: 5.6rem 8rem 3.2rem 5.6rem; 
        p{
            font: ${(props) => props.theme.fonts.medium.exchangePicker};
            letter-spacing: -0.087rem;
        }
    }

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
        padding: 12.6rem 6.4rem 3.2rem 6.4rem; 
        p{
            font: ${(props) => props.theme.fonts.large.exchangePicker};
        }
    }
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.xl}) {
        padding: 12.6rem 12rem 3.2rem 10.2rem; 
        p{
            font: ${(props) => props.theme.fonts.large.exchangePicker};
        }
    }
    
`;

export default ComingSoon;
