import React, { SFC } from 'react';
import ObserveSize from 'react-observe-size';
import { List, WindowScroller } from 'react-virtualized';
import { ListRowProps } from 'react-virtualized/dist/es/List';
import { compose } from 'recompose';
import { ThemeInterface } from '../../constants/theme';
import styled, { ThemeProps, withTheme } from '../../styled-components';
import LinkIconRight from '../atoms/icons/LinkIconRight';
import PlusIcon from '../atoms/icons/PlusIcon';

interface ColumnWidth {
    l: string;
    m: string;
    s: string;
}

interface ColumnType {
    text: string;
    width: ColumnWidth;
    cta?: boolean;
    iconType?: string;
    onClick?: () => void;
    minWidth?: string;
    color?: string;
    url?: string;
}

interface InProps {
    getRowData: (index: number) => ColumnType[];
    headerItems: ColumnType[];
    numOfRows: number;
}

type OutProps = ThemeProps;

const TableColumn = ({
    text,
    cta,
    iconType,
    onClick,
    url,
    theme,
    width,
    ...rest
}: ColumnType & { theme: ThemeInterface }) => {
    let button;
    switch (iconType) {
        case 'link':
            button = <LinkIconRight color={theme.colors.tango} />;
            break;
        case 'popup':
            button = <PlusIcon color={theme.colors.tango} />;
            break;
        default:
            button = '';
            break;
    }
    return cta ? (
        <Column {...rest} widthL={width.l} widthM={width.m} widthS={width.s}>
            <ContactButton onClick={onClick}>
                <span>{text}</span> {button}
            </ContactButton>
        </Column>
    ) : (
        <Column {...rest} widthL={width.l} widthM={width.m} widthS={width.s}>
            {url ? (
                <a href={url} target="_blank">
                    {text}
                </a>
            ) : (
                text
            )}
        </Column>
    );
};

const CollectionTable: SFC<OutProps & InProps> = ({ getRowData, numOfRows, headerItems, theme }) => {
    const renderTableRow = ({ key, index, style }: ListRowProps) => {
        const row = getRowData(index);
        return (
            <Row key={key} style={style}>
                {row.map((column, i) => (
                    <TableColumn {...column} theme={theme} key={i} />
                ))}
            </Row>
        );
    };
    return (
        <ResponsiveWrap>
            <Container>
                <ObserveSize>
                    {({ width }) => (
                        <React.Fragment>
                            <TableHeader>
                                {headerItems.map((column, i) => (
                                    <TableColumn {...column} theme={theme} color={theme.colors.baliHai} key={i} />
                                ))}
                            </TableHeader>
                            <WindowScroller>
                                {({ height, scrollTop }) => (
                                    <StyledList>
                                        <List
                                            autoHeight
                                            scrollTop={scrollTop}
                                            width={width}
                                            height={height}
                                            headerHeight={20}
                                            rowHeight={65}
                                            rowCount={numOfRows}
                                            rowRenderer={renderTableRow}
                                        />
                                    </StyledList>
                                )}
                            </WindowScroller>
                        </React.Fragment>
                    )}
                </ObserveSize>
            </Container>
        </ResponsiveWrap>
    );
};

const Container = styled.section`
    max-width: ${props => props.theme.maxWidths.xxl}px;
    margin: 0 auto;
    padding: 0 3.3rem;

    @media screen and (max-width: ${props => props.theme.mediaQueriesValues.s - 1}px) {
        padding: 0 2rem;
    }
`;

const ResponsiveWrap = styled.div`
    overflow-x: auto;
    padding-bottom: 5.6rem;
`;

const StyledList = styled.div`
    .ReactVirtualized__List {
        outline: none;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    font-family: chalet-new-york-sixty;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    overflow: hidden;

    border-bottom: 1px solid ${props => props.theme.colors.mystic};

    > div {
        box-sizing: border-box;
    }

    @media screen and (max-width: ${props => props.theme.mediaQueriesValues.s - 1}px) {
        font-size: 1.4rem;
    }
`;

const TableHeader = styled(Row)`
    height: 6.5rem;
    margin-top: 6.5rem;
`;

export const Column = styled.div<{ widthL: string; widthM: string; widthS: string; color?: string; minWidth?: string }>`
    position: relative;
    width: ${props => props.widthL};
    min-width: ${props => props.minWidth || 'auto'};
    margin-right: 2.4rem;
    overflow: hidden;

    ${props =>
        props.color
            ? `color: ${props.color}`
            : `
        color: ${props.theme.colors.shuttleGray};
        &:first-child {color: ${props.theme.colors.black}}
    `};

    a:hover {
        text-decoration: underline;
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1.5rem;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 100%);
    }

    &:last-child {
        text-align: right;
        margin-right: 0;
    }

    &:last-child:after {
        display: none;
    }

    @media screen and (max-width: 1060px) {
        ${props =>
            props.widthM === '0'
                ? `
            display: none;        
        `
                : `
            width: ${props.widthM};
        `}
    }

    @media screen and (max-width: ${props => props.theme.mediaQueriesValues.s - 1}px) {
        ${props =>
            props.widthS === '0'
                ? `
            display: none;        
        `
                : `
            width: ${props.widthS};
        `};
        min-width: auto;
    }
`;

const ContactButton = styled.button`
    border: none;
    background: none;
    font: ${props => props.theme.fonts.large.textLink};
    text-transform: uppercase;
    font-size: 1.3rem;
    color: ${props => props.theme.colors.black};
    cursor: pointer;
    padding: 0;

    > svg {
        margin-left: 1.2rem;
        vertical-align: text-bottom;
    }

    span {
        @media screen and (max-width: ${props => props.theme.mediaQueriesValues.s - 1}px) {
            display: none;
        }
    }
`;

const enhance = compose<OutProps, InProps>(withTheme);

export default enhance(CollectionTable);
