import styled from '../../styled-components';

interface Props {
    color?: string;
    maxWidth?: string;
    textShadow?: boolean;
    alignRight?: boolean;
}

const ContentTitle = styled.h2<Props>`
    font: ${props => (props.theme.fonts ? props.theme.fonts.small.contentTitle : '')};
    color: ${props => (props.color ? props.color : 'inherit')};
    ${props => (props.textShadow ? 'text-shadow: 0 0 20px rgba(0, 0 , 0, 0.15);' : '')};

    margin-bottom: 2.4rem;
    margin-top: 0;

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.medium.contentTitle};
        max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font: ${props => props.theme.fonts.large.contentTitle};
        margin-bottom: 3.2rem;
    }
`;

export default ContentTitle;
