import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
}

const Facebook = ({ type, color = 'currentColor', ...rest }: Props) => (
    <svg width="9" height="16">
        <path
            d="M.372 8.522h1.754v7.22c0 .142.115.258.258.258h2.973a.258.258 0 0 0 .259-.258V8.556h2.016a.258.258 0 0 0 .256-.229l.306-2.658a.258.258 0 0 0-.256-.288H5.616V3.715c0-.502.27-.757.804-.757h1.518a.258.258 0 0 0 .258-.258V.26a.258.258 0 0 0-.258-.258H5.845C5.831.002 5.798 0 5.75 0c-.363 0-1.625.071-2.622.988-1.104 1.017-.95 2.234-.914 2.445V5.38H.372a.258.258 0 0 0-.258.258v2.624c0 .143.115.259.258.259z"
            fill={color}
            fillRule="nonzero"
        />
    </svg>
);

export default Facebook;
