import { Field as FormikField, FieldProps } from 'formik';
import React, { Fragment } from 'react';
import styled, { css } from '../../styled-components';
import theme from '../../constants/theme';
import selectIcon from '../../assets/select_icon.svg';
import check from '../../assets/check.svg';
import get from 'lodash.get';

interface Props {
    name: string;
    type?: string;
    label?: string;
    placeholder?: string;
    value?: string;
    smallText?: string;
    options?: string[];
    onClick?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    disabled?: boolean;
    defaultChecked?: boolean;
}

const FormField: React.SFC<Props & FieldProps> = ({ field, form: { touched, errors }, label, type = 'text', smallText, placeholder, options, defaultChecked, ...props }) => {
    return (
        <FieldContainer>
            {label && type !== 'checkbox' &&
                <Label htmlFor={field.name} disabledInput={props.disabled}>{label}</Label>
            }

            {type !== 'textarea' && type !== 'select' && type !== 'checkbox' && type !== 'multipleRadio' &&
                <Fragment>
                    <Input smallText={!!smallText} type={type ? type : 'text'} id={field.name} placeholder={placeholder} value={field.value ? field.value : ''} {...field} {...props} />
                    {smallText && <SmallText>{smallText}</SmallText> }
                </Fragment>
            }
            {type === 'textarea' &&
                <Fragment>
                    <Textarea smallText={!!smallText} rows={3} id={field.name} placeholder={placeholder} {...field} {...props} />
                    {smallText && <SmallText>{smallText}</SmallText> }
                </Fragment>
            }
            {type === 'select' &&
                <Fragment>
                    <SelectWrapper smallText={!!smallText}>
                        <Select id={field.name} required {...props} {...field}>
                            {placeholder && <option value="" hidden>{placeholder}</option> }
                            {options && options.map((option, key) => (
                                <option value={option} key={key}>{option}</option>
                            ))}
                        </Select>
                    </SelectWrapper>
                    {smallText && <SmallText>{smallText}</SmallText> }
                </Fragment>
            }

            {type === 'multipleRadio' &&
                <Fragment>
                    {options && options.map((option, key) => (
                        <FlexRow key={key}>
                            <RadioWrapper>
                                <Field type="radio" name={field.name} value={option}/>
                            </RadioWrapper>
                            <Label>{option}</Label>
                        </FlexRow>
                    ))}
                </Fragment>
            }

            {type === 'checkbox' &&
                <CheckboxWrapper>
                    <Checkbox type="checkbox" defaultChecked={defaultChecked} id={field.name} name={field.name} {...field} {...props}/>
                    {label && <Label htmlFor={field.name} dangerouslySetInnerHTML={{ __html: label }} />}
                    {smallText && <SmallText>{smallText}</SmallText> }
                </CheckboxWrapper>
            }

            {get(touched, field.name) && get(errors, field.name) && <Error>{get(errors, field.name)}</Error>}
        </FieldContainer>
    );
};

export const Field = (props: Props) => (
    <FormikField component={FormField} {...props} />
);

export const FieldContainer = styled.div`
    padding-bottom: 3.2rem;
    height: auto;
`;

const FlexRow = styled.div `
    display: flex;
    flex-direction: row;
`

const SmallText = styled.small`
  font-size: 1.2rem;
  line-height: 2.4rem;
`;
const base = css<{smallText: boolean}>`
    appeareance: none;
    border: none;
    margin: 0;
    font-family: chalet-new-york-sixty;
    font-size: 1.8rem;
    line-height: 4rem;
    outline: none;
    display: block;
    color: inherit;
    padding: ${(props) => props.smallText ? '0' : '0 0 .8rem'};

    width: 100%;
    background-color: transparent;
    
    ::placeholder {
        color: ${props => props.theme.colors.mystic};
    }
    
    &:hover ::placeholder {
        color: ${props => props.theme.colors.baliHai};
    }
    
    &:disabled {
        color: ${props => props.theme.colors.baliHai};
        border-bottom: .1rem solid ${props => props.theme.colors.mystic};
        
        @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
            width: 50%;
        }

    }
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font-size: 2.4rem;
    }
    
    
`;

// todo: use withComponent instead when https://github.com/styled-components/styled-components/issues/1315 gets fixed
export const Input = styled.input<{smallText: boolean}>`
    ${base};
`;

const Textarea = styled.textarea<{smallText: boolean}>`
    ${base};
`;

export const Label = styled.label<{disabledInput?: boolean}>`
    font-family: chalet-new-york-sixty;
    font-size: 1.4rem;
    letter-spacing: 0;
    line-height: 2.6rem;
    padding-bottom: 1rem;
    display: block;
    color: ${props => props.disabledInput ? props.theme.colors.baliHai : 'inherit'};

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font-size: 1.6rem;
    }
    
    a {
      color: ${theme.colors.tango};
      border-bottom: .1rem solid ${theme.colors.tango}
    }
`;

export const Error = styled.div`
    font-family: chalet-new-york-sixty;
    font-size: 1.2rem;
    color: #CB0202;
    letter-spacing: 0;
    line-height: 2.4rem;
`;

const RadioWrapper = styled.div`
    transform: scale(2);
    margin: 2rem 0 0 0;
    width: 50px;
`;

const Select = styled.select`
    border-radius: 35px;
    padding: .4rem 2.5rem;
    background-color: white;
    border-color: ${theme.colors.baliHai};
    width: 100%; 
    
    font-family: chalet-new-york-sixty;
    font-size: 2.4rem;
    line-height: 4rem;
    appearance: none;
    
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        width: 45rem;
    }
    
    &:focus {
       outline: none;
    }
    
    &:not(:focus):invalid {
      color: ${props => props.theme.colors.mystic};
    }
    
    &[disabled], &:disabled{
      opacity: 0.4;
      cursor: not-allowed;
    }
`;

const SelectWrapper = styled.div<{smallText: boolean}>`
    width: 100%;
    padding: ${(props) => props.smallText ? '0' : '0 0 .8rem'};
    height: 5rem;
    position: relative;

    @media screen and (min-width: ${(props) => props.theme.mediaQueries.s}) {
        width: 45rem;
    }
    
    &:after {
        content: '';
        background-image: url(${selectIcon});
        width: 1.7rem;
        height: 1.2rem;
        display: inline-block;
        background-size: cover;
        position: absolute;
        right: 2rem;
        top: 2rem;
    }
`;

const Checkbox = styled.input`
    display: none;
    
    + ${Label} {
        display: inline;
        margin-left: 2.9rem;
    }
    
    // Box.
    & + label:before {
        content: '';
        display: inline-block;
        box-sizing: border-box;
        width: 1.8rem;
        height: 1.8rem;
        background: transparent;
        border: 1px solid ${theme.colors.baliHai};
        position: absolute;
        top: 0;
        left: 0;
    }
    
    // Box hover
    &:hover + label:before {
        background: ${theme.colors.aquaHaze};
    }
    
    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
    
    // Box checked
    &:checked + label:before {
        background: ${theme.colors.aquaHaze};
    }
    
    // Icon
    &:checked + label:after {
        content: '';
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        box-sizing: border-box;
        left: .3rem;
        top: .4rem;
        background-size: 1.3rem;
        background-repeat: no-repeat;
        background-image: url(${check});
    }
`;

const CheckboxWrapper = styled.div`
    position: relative;
`;