// todo at some point we would like to receive these from the CMS,
// but craftql does not support querying for a list of sites as of now...
import siteList from '../../countries.json'

export interface Site {
    id: number;
    handle: string;
    name: string;
    primary?: boolean;
    url?: string;
}

const sites: Site[] = siteList

export const poweredBySites: Site[] = [{
    id: 0,
    handle: 'cai',
    name: 'Cairo',
    url: 'https://eg-ix.com.eg/',
}, {
    id: 0,
    handle: 'kuc',
    name: 'Kuching',
    url: 'https://www.irix.my/ix/',
}, {
    id: 0,
    handle: 'mnix',
    name: 'Manama',
    url: 'https://www.mn-ix.com/',
}, {
    id: 0,
    handle: 'omix',
    name: 'Muscat',
    url: 'https://om-ix.com/',
}];


export default sites;

