import React from 'react';
import styled from '../../styled-components';
import { Img } from 'react-image-loading';

interface Props {
    img?: {
        src: string | null;
        width: string | null;
        src2x: string | null;
        width2x: string | null;
        title: string | null;
    };
    title: string;
    subTitle?: string | null;
}

const HeroNews = ({ img, title, subTitle }: Props) => {
    return (
        <HeroWrap>
            <HeroContent>
                <HeroImageWrapper>
                    <Img
                        alt={img && img.title || ''}
                        src={img && img.src || undefined}
                        srcSet={`
                            ${img && img.src} ${(img && img.width)}w,
                            ${img && img.src2x} ${(img && img.width2x)}w`}
                        sizes={'(min-width: 990px) 10vw, 140px'}
                    />
                </HeroImageWrapper>
                <HeroTitle>{title}</HeroTitle>
                <HeroSubtext>{subTitle}</HeroSubtext>
            </HeroContent>
        </HeroWrap>
    );
};

const HeroWrap = styled.section`
    background-color: ${(props) => props.theme.colors.mirage};
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
    &:before{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2.5rem;
        background-color: white;
        z-index: 1;
        margin: 0 auto;
        @media screen and (min-width: ${(props) => props.theme.mediaQueries.l}) {
          content:'';
          max-width: 1152px;            
        }
        @media screen and (min-width: ${(props) => props.theme.mediaQueries.xl}) {
          max-width: 1344px;      
        }
        @media screen and (min-width: ${(props) => props.theme.mediaQueries.xxl}) {
          max-width: 1440px;
        }
    }
`;

const HeroImageWrapper = styled.div`
    position: relative;
    border-radius: 50%;
    width: 14rem;
    height: 14rem;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 4.7rem;
    > img {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: transform 0.5s ease-in-out 0s;
    }
`;

const HeroContent = styled.div`
    position: relative;
    z-index: 1;
    padding-bottom: 9rem;
    max-width: 61.3rem;
    margin: 0 auto;
    padding: 10.4rem 0 10.4rem 0;
    text-align: center;
`;

const HeroTitle = styled.h1`
    font-family: chalet-new-york-sixty, sans-serif;
    font-size: 4.4rem;
    line-height: 4.4rem;
    margin: 0 0 1.6rem 0;
    letter-spacing: -0.178rem;
    color: ${(props) => props.theme.colors.white};
    @media screen and (min-width: ${(props) => props.theme.mediaQueries.m}) {
        font-size: 6.4rem;
        line-height: 6.4rem;
    }
`;

const HeroSubtext = styled.p`
    font-family: urw-din, sans-serif;
    font-size: 1.4rem;
    line-height: 5.6rem;
    letter-spacing: 0.14rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.tango};
`;

export default HeroNews;