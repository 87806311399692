import { Form as FormikForm, FormikBag, FormikProps, withFormik } from 'formik';
import gql from 'graphql-tag';
import React, { Fragment, SFC, useState, useEffect } from 'react';
import { graphql, MutationFunction } from 'react-apollo';
import ReactGA from 'react-ga';
import ReCAPTCHA from 'react-google-recaptcha';
import {branch, compose, Omit} from 'recompose';
import { object, string } from 'yup';
import { default as sites, Site } from '../../constants/sites';
import {
    sendContactRequestForWishlistMutation,
    sendContactRequestForWishlistMutationVariables,
} from '../../entities/operationResults';
import matchRoute from '../../services/matchRoute';
import styled from '../../styled-components';
import DefaultButton from '../atoms/buttons/DefaultButton';
import * as Form from '../atoms/Form';
import { ExchangeParams } from '../Exchange';
import * as Intro from '../molecules/Intro';
import Separator from '../atoms/Separator';
import IconButton from '../atoms/buttons/IconButton';
import PlusIcon from '../atoms/icons/PlusIcon';
import CloseIcon from '../atoms/icons/CloseIcon';

interface Values {
    fullName: string;
    emailAddress: string;
    recaptcha: string;
    company: string;
    requestedAs: string;
    yourAsNumber: string;
}

const CONTACT_REQUEST_ASN_MUTATION = gql`
    mutation sendContactRequestForWishlist(
        $siteId: Int
        $subject: String
        $emailAddress: String
        $fullName: String
        $company: String
        $contactFormId: Number
        $recaptcha: String
        $siteLocation: String
        $requestedAs: String
        $yourAsNumber: String
        $authorId: ID!
    ) {
        save_contactRequest_wishlist_Entry(
            siteId: $siteId
            subject: $subject
            title: "dummy"
            emailAddress: $emailAddress
            fullName: $fullName
            company: $company
            contactFormId: $contactFormId
            recaptchaValue: $recaptcha
            siteLocation: $siteLocation
            requestedAs: $requestedAs
            yourAsNumber: $yourAsNumber
            authorId: $authorId
        ) {
            mailSent
        }
    }
`;

const trackSubmitFormEvent = () => {
    ReactGA.event({
        category: 'Contactform',
        action: 'Form submitted',
        label: location.pathname,
    });
};

interface InProps {
    contactBlockId?: number | null;
    skipPhoneNumber?: boolean;
    isDark?: boolean;
    toNetwork?: number;
    wishlist?: boolean;
}

interface OutProps extends FormikProps<Values> {
    sendContactRequestForWishlist?: MutationFunction<sendContactRequestForWishlistMutation, sendContactRequestForWishlistMutationVariables>;
}

type newSendContactRequestForWishlistMutationVariables = Omit<sendContactRequestForWishlistMutationVariables, 'contactFormId'> & {
    contactFormId?: number | null;
    authorId: string,
};

type Props = InProps & OutProps;

const ContactFormASN: SFC<Props> = ({
    submitForm,
    status,
    isSubmitting,
    isValid,
    isDark,
    setFieldValue,
    errors,
    touched,
    wishlist,
}) => {

    const [values, setValues] = useState<any>([]);
    const [requestedASN, setRequestedASN] = useState<any>([]);
    const [estimatedTrafficVolume, setEstimatedTrafficVolume] = useState<any>([]);

    useEffect(() => {
        setValues([...values, {requestedAS: "", estimatedTrafficVolume: ""}]);
        setRequestedASN([...requestedASN, ""]);
        setEstimatedTrafficVolume([...estimatedTrafficVolume, ""]);
    }, []);

    useEffect(() => { putToProps()}, [values])
    useEffect(() => { putToProps()}, [requestedASN])
    useEffect(() => { putToProps();}, [estimatedTrafficVolume])

    const addValue = (event: any) => {
        event.preventDefault();
        setValues([...values, {requestedAS: "", estimatedTrafficVolume: ""}]);
        setRequestedASN([...requestedASN, ""]);
        setEstimatedTrafficVolume([...estimatedTrafficVolume, ""]);
    };

    // Custom value changes are needed because Formik is not used in dynamic forms
    const handleValueChange = (index: any, e: any) => {
        const updatedValues = values.map((value: any, i: any) => {
            if(i === index) {
                if(estimatedTrafficVolume.length > 0) {
                    return {requestedAS: e.target.value, estimatedTrafficVolume: estimatedTrafficVolume[i]}
                } else {
                    return {requestedAS: e.target.value, estimatedTrafficVolume: ""};
                }
            }   
            else {
                return value;
            }
        })

        setValues(updatedValues);

        const updatedValuesSingleKey = requestedASN.map((value: any, i: any) => {
            if (i === index) {
                return e.target.value;
            } else {
                return value;
            }
        });

        setRequestedASN(updatedValuesSingleKey);
      };

      const handleEstimatedValueChange = (index: any, e: any) => {
        const updatedValues = values.map((value: any, i: string | number) => {

        if(i === index) {
            if(requestedASN.length > 0) {
                return  {requestedAS: requestedASN[i], estimatedTrafficVolume: e.target.value};
            } else {
                return {requestedAS: "", estimatedTrafficVolume: e.target.value};
            }
        }   else {
                return value;
            }
        })
        setValues(updatedValues);

        const updatedValuesSingleKey = estimatedTrafficVolume.map((value: any, i: any) => {
            if (i === index) {
                return e.target.value;
            } else {
                return value;
            }
        });

        setEstimatedTrafficVolume(updatedValuesSingleKey);
    };

    const deleteValue = (event: any, jump: any) => {
        event.preventDefault();
        setValues(values.filter((j: any) => j !== jump));
    };

    const putToProps = () => {
        const str = values.map((a: any) => `(Requested AS: ${ Object.values(a).join(", Estimated Volume: ") })`)
        .join(", ")

        // Send value to props
        setFieldValue("requestedAs", str);
    }

    return (
        <FormContainer>
            <FormError>{status && status.type === 'error' && <Form.Error>{status.value}</Form.Error>}</FormError>
            <FormikForm className={
                window.location.href.search('pricing') > -1 ? 'Sales' :
                    window.location.href.search('events') > -1 ? 'Events' :
                        window.location.href.search('service') ?'Customer-service' : ''}>
                {status && status.type === 'success' ? (
                    <Intro.Text>
                        <p className={'success-message'}>
                            {'Thank you! Your message has been received. We will get back to you soon.'}
                        </p>
                    </Intro.Text>
                ) : (
                    <Fragment>
                        {wishlist && 
                            <Fragment>
                                {values.map((jump: { requestedAS: any; estimatedTrafficVolume: any; }, index: React.Key | undefined) => (
                                    <Flex key={index}>
                                        <InputContainer>
                                            <Form.Label>Requested AS*</Form.Label>
                                            <Form.Input    
                                                key={index}
                                                value={jump.requestedAS || ""}
                                                placeholder="0000000"
                                                smallText={false}
                                                onChange={(e) => handleValueChange(index, e)}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <Form.Label>Estimate traffic volume (daily, in GE)</Form.Label>
                                            <Form.Input    
                                                key={index}
                                                value={jump.estimatedTrafficVolume || ""}
                                                placeholder="0000"
                                                smallText={false}
                                                onChange={(e) => handleEstimatedValueChange(index, e)}
                                            />
                                        </InputContainer>
                                        {values.length > 1 &&
                                            <AddButton 
                                                onClick={(e) => deleteValue(e, jump)}
                                                icon={<CloseIcon />} 
                                                colorScheme="dark">
                                                Delete
                                            </AddButton>
                                        }
                                    </Flex>
                                ))}
                                <AddButton 
                                    onClick={e => addValue(e)}
                                    icon={<PlusIcon />} 
                                    colorScheme="dark">
                                    Add other ASN
                                </AddButton>
                                <FormSep/>
                            </Fragment>
                        }
                        {wishlist && 
                            <Form.Field name="yourAsNumber" label="Your AS number*" placeholder="0000000" />
                        }
                        <Form.Field name="fullName" label="Name*" placeholder="Your full name" />
                        <Form.Field name="company" label="Company*" placeholder="Your company name" />
                        <Form.Field name="emailAddress" label="Email address*" placeholder="Your email address" />                   
                        <ReCAPTCHAContainer>
                            <ReCAPTCHA
                                sitekey={process.env.RAZZLE_RECAPTCHA_SITEKEY || ''}
                                onChange={response => setFieldValue('recaptcha', response)}
                                theme={isDark ? 'dark' : 'light'}
                            />
                            {errors.recaptcha && touched.recaptcha && <Form.Error>{errors.recaptcha}</Form.Error>}
                        </ReCAPTCHAContainer>
                        <DefaultButton
                            to="#"
                            colorScheme={isDark ? 'colored' : 'dark'}
                            onClick={e => {
                                e.preventDefault();
                                submitForm();
                            }}
                            disabled={isSubmitting || !isValid}
                        >
                            SEND
                        </DefaultButton>
                    </Fragment>
                )}
            </FormikForm>
        </FormContainer>
    );
};

const FormContainer = styled(Intro.Container)`
    padding-top: 0;
    padding-bottom: 6rem;

    textarea {
        resize: none;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding-top: 2.2rem;
    }
`;

const InputContainer = styled.div`
    flex-direction: column;
    padding-bottom: 3.2rem;
`;

const FormError = styled.div`
    margin-bottom: 2rem;
`;

const ReCAPTCHAContainer = styled.div`
    margin-bottom: 3.2rem;
`;

const FormSep = styled(Separator)`
    margin-bottom: 4rem;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: row;
`;

const AddButton = styled(IconButton)`
    font: ${props => props.theme.fonts.large.textLink};
    text-transform: uppercase;
    margin-bottom: 4rem;
`;

const handleSubmit = async (
    { fullName, company, emailAddress, recaptcha, yourAsNumber, requestedAs }: Values,
    { props, setSubmitting, setStatus }: FormikBag<Props, Values>
) => {
    const matchedRoute = matchRoute<ExchangeParams>(location.pathname);
    const site: Site | undefined = sites.find(
        current => !!matchedRoute && current.handle === matchedRoute.match.params.exchange
    );
    const siteId = site ? site.id : sites[0].id;
    const siteLocation = site ? site.name : sites[0].name;
    const subject = "ASN wishlist";

    try {
        if (!props.toNetwork && props.sendContactRequestForWishlist) {
            const { data } = await props.sendContactRequestForWishlist({
                variables: {
                    siteId,
                    emailAddress,
                    fullName,
                    company,
                    contactFormId: props.contactBlockId !== null ? String(props.contactBlockId) : null,
                    recaptcha,
                    siteLocation,
                    requestedAs,
                    yourAsNumber,
                    subject,
                    authorId: '1',
                } as newSendContactRequestForWishlistMutationVariables,
            });

            if (data && data.save_contactRequest_wishlist_Entry && !data.save_contactRequest_wishlist_Entry.mailSent) {
                return setStatus({
                    type: 'error',
                    value: 'Failed to send message to recipient, try again later.',
                });
            }
        }
        trackSubmitFormEvent();
        setStatus({
            type: 'success',
        });
    } catch (ex) {
        setStatus({
            type: 'error',
            value: ex.toString(),
        });
    } finally {
        setSubmitting(false);
    }
};

const enhance = compose<OutProps, InProps>(
    branch(
        (props: InProps) => !props.toNetwork,
            graphql(CONTACT_REQUEST_ASN_MUTATION, {
                name: 'sendContactRequestForWishlist',
            })
    ),
    graphql(CONTACT_REQUEST_ASN_MUTATION, {
        name: 'sendContactRequestForWishlist',
    }),
    withFormik<Props, Values>({
        validationSchema: object().shape({
            emailAddress: string()
                .required('Please enter an email address')
                .email('Please enter a valid email address'),
            fullName: string().required('Please enter your name'),
            company: string().required('Please enter your company name'),
            yourAsNumber: string().required('Please enter your AS number'),
            recaptcha: string()
                .nullable(true)
                .required('Please complete the reCAPTCHA'),
        }),
        handleSubmit,
        mapPropsToValues: () => ({
            emailAddress: '',
            fullName: '',
            company: '',
            recaptcha: '',
            requestedAs: '',
            yourAsNumber: '',
        }),
    })
);

export default enhance(ContactFormASN);
