import React, { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from '../../styled-components';
import LazyImg from '../atoms/LazyImg';

interface Props {
    service?: string | null;
    img?: {
        srcSmall: string | null;
        widthSmall: string | null;
        srcMedium: string | null;
        widthMedium: string | null;
        srcLarge: string | null;
        widthLarge: string | null;
        title: string | null;
    };
    date: string | null;
    link: string;
    children: ReactNode;
}

const FeaturedPreview = ({ children, img, service, link, date }: Props) => (
    <FeaturedPreviewWrap to={link}>
        <ImgWrapper>
            <LazyImg
                alt={(img && img.title) || undefined}
                src={(img && img.srcSmall) || undefined}
                srcSet={`
                        ${img && img.srcSmall} ${img && img.widthSmall}w,
                        ${img && img.srcMedium} ${img && img.widthMedium}w,
                        ${img && img.srcLarge} ${img && img.widthLarge}w`}
                sizes={'(min-width: 990px) 35vw, 100vw'}
            />
        </ImgWrapper>
        {service && (
            <FeaturedPreviewCat>
                <h4>{service}</h4>
            </FeaturedPreviewCat>
        )}
        <FeaturedPreviewContent>
            <Date>{date}</Date>
            <FeaturedDescription>{children}</FeaturedDescription>
        </FeaturedPreviewContent>
    </FeaturedPreviewWrap>
);

const FeaturedPreviewWrap = styled(Link)<LinkProps>`
    flex: 1 calc(50% - 3.9rem);
    position: relative;
    text-decoration: none;
    text-align: left;
    background-color: ${props => props.theme.colors.mirage};
    margin: 0.5rem;
    height: calc(100vw - 1.6rem);
    box-sizing: border-box;

    &:hover {
        img {
            transform: scale(1.05);
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        object-fit: cover;
        object-position: center;
    }
    h4,
    h5,
    p {
        color: white;
    }
    h5 {
        font: ${props => props.theme.fonts.large.storySubtitle};
        letter-spacing: 0.01rem;
        margin-bottom: 0.8rem;
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &:before {
        z-index: 2;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0) 80%,
            rgba(0, 0, 0, 0.1) 100%
        );
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xs}) {
        height: 44.6rem;
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        p {
            max-width: 28rem;
        }
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.xxl}) {
        height: 51.1rem;
    }
`;

const ImgWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        transition: 0.5s transform ease-in-out;
    }
`;

const FeaturedPreviewContent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 3.3rem;
    max-width: 56rem;

    @media screen and (min-width: ${props => props.theme.mediaQueries.s}) {
        padding: 4rem 26rem 4rem 4.8rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        padding: 4rem 13rem 4rem 4.8rem;
    }

    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        padding: 4rem 18rem 4rem 4.8rem;
    }
`;

const Date = styled.p`
    margin: 0;
    font: ${props => props.theme.fonts.large.eventDate};
`;

const FeaturedDescription = styled.p`
    margin: 0;
    font: ${props => props.theme.fonts.small.sectionItemTitle};
    @media screen and (min-width: ${props => props.theme.mediaQueries.m}) {
        font: ${props => props.theme.fonts.medium.sectionItemTitle};
    }
    @media screen and (min-width: ${props => props.theme.mediaQueries.l}) {
        font: ${props => props.theme.fonts.large.sectionItemTitle};
    }
`;

const FeaturedPreviewCat = styled.div`
    position: relative;
    z-index: 3;
    font: ${props => props.theme.fonts.small.storyTitle};
    letter-spacing: 0.04rem;
    h4 {
        margin: 0;
        text-transform: uppercase;
    }
`;

export default FeaturedPreview;
