import React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    }

const IconList: React.FC<Props> = ({ color = 'currentColor', ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path d="M1.2002 4.79999H3.6002V7.19999H1.2002V4.79999ZM6.0002 4.79999H22.8002V7.19999H6.0002V4.79999ZM1.2002 10.8H3.6002V13.2H1.2002V10.8ZM6.0002 10.8H22.8002V13.2H6.0002V10.8ZM1.2002 16.8H3.6002V19.2H1.2002V16.8ZM6.0002 16.8H22.8002V19.2H6.0002V16.8Z"/>
    </svg>
);

export default IconList;